/**
 * Staff
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    StaffModel = require('./../models/staffModel'),
    Backbone = require('backbone');
var config = require('./../models/configModel');

Backbone.$ = $;

module.exports = Backbone.Collection.extend({
    model: StaffModel,
    servicesByStaff: false,
    businessToken: false,

    url:
        function() {
            var bid = this.businessToken;
            /* istanbul ignore if*/
            if (!bid) {
                bid = config.getBusinessToken();
            }
            return config.getAPIUrl() + 'staff/' + bid;
    },

    sortStaff: function(availabilityReponse) {

        _.each(availabilityReponse, function(service){
            service = service.sort(function(staffA, staffB){
                var priceOrder = 0;

                //price can be null, place them at start
                if(staffB.price == null) {
                    staffB.price = -10;
                }
                if(staffA.price == null) {
                    staffA.price = -10;
                }
                priceOrder = staffA.price - staffB.price;

                var staffOrder = staffA.firstName.localeCompare(staffB.firstName);

                if(priceOrder === 0 ) {
                    return staffOrder;
                } else {
                    return priceOrder;
                }

            });

        });

        return availabilityReponse;
    },

    getCapability: function(services) {
        var that = this;

        return $.get(this.url() + "/services/" + services.join("-"), function(result) {
            _.each(result, function(serviceDetails) {
                _.each(serviceDetails, function(staff) {
                    staff.firstName = that.get(staff.staffId).get('firstName');
                    staff.lastName = that.get(staff.staffId).get('lastName');
                    staff.formattedName = that.get(staff.staffId).get('formattedName');
                });
            });
            that.servicesByStaff = that.sortStaff(result);

        });
    },

    findByKid: function (kid) {
        return this.findWhere({'id': kid});
    },

    /**
     * Creates a special staff model to represent a user selection of "anyone".
     * @returns {StaffModel}
     */
    createAnyoneStaff: function () {
        return {
            'firstName': '(anyone)',
            'formattedName': '(anyone)',
            'lastName': '',
            'staffId': '0',
            'staffName': '(anyone)'
        };
    }

});

