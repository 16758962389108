/**
 * Client Histories
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    BaseServiceCollection = require('./baseServiceCollection'),
    config = require('./../../js/models/configModel');

Backbone.$ = $;

module.exports = BaseServiceCollection.extend({
    comparator: 'name',
    userModel:false,
    cachedUserId: false,
    getUrl: function (type, customer_id) {

        if (type === 'package') {
            return config.getAPIUrl() + "package/" + config.getBusinessToken() + "/client_history/" + customer_id;
        } else {
            return config.getAPIUrl() + "service/" + config.getBusinessToken() + "/client_history/" + customer_id;
        }
    },
    initialize: function (models,options) {
        BaseServiceCollection.prototype.initialize(models, options);
        if(typeof options !== 'undefined') {
            if (typeof options.userModel !== 'undefined') {
                this.userModel  = options.userModel;
            }

        }
        _.bindAll(this, 'process');
    },
    fetch:function(){
        var that = this;
        var deferred = $.Deferred();
        this.reset();

        if (this.userModel && this.userModel.get('logged_in')) {

            var clientServiceHistoryPromise = $.ajax({
                url: this.getUrl('service', this.userModel.get('id'))
            });
            var clientPackageHistoryPromise = $.ajax({
                url: this.getUrl('package', this.userModel.get('id'))
            });

            $.when(clientServiceHistoryPromise, clientPackageHistoryPromise).done(
                function (clientServiceHistoryPromise, clientPackageHistoryPromise) {
                    that.cachedUserId = that.userModel.get('id') + '-' + that.userModel.get('business_id');

                    that.process_ready = function() {
                        that.process(clientServiceHistoryPromise, clientPackageHistoryPromise);
                    };

                    deferred.resolve();
                }).fail(function(){
                    that.cachedUserId = false;
                    deferred.reject();
                });
        } else {
            that.cachedUserId = false;
            deferred.resolve();
        }
        return deferred;
    },

    process_ready: false,

    process: function (service_history, package_history) {
        var that = this;

        _.each(service_history[0], function (service_id) {
            that.servicesCollection.each(function (serviceModel, key) {
                if (serviceModel.get('id') === service_id) {
                    that.add(serviceModel);
                }
            });
        });

        _.each(package_history[0], function (package_id) {
            that.packagesCollection.each(function (packageModel, key) {
                if (packageModel.get('id') === package_id) {
                    that.add(packageModel);
                }
            });
        });
    },
    checkCacheInvalid: function() {
        if (this.userModel && this.userModel.get('logged_in')) {
            return this.cachedUserId !== this.userModel.get('id') + '-' + this.userModel.get('business_id');
        } else {
            return this.cachedUserId !== false;
        }
    }
});

