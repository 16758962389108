/**
 * Holds details about a category
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');
var config = require('./../../js/models/configModel');

Backbone.$ = $;

module.exports = Backbone.Model.extend({
    url: function() {
        return config.getAPIUrl() + 'category/' + this.get('id');
    },
    // init of the model
    initialize: function(model) {

    }
});

