/**
 * Analytics, intercom, facebook,  and GA
 *
 * @module
 */
"use strict";
/*global ga, gtag, Intercom, fbq, chainPixelId, businessPixelId */
var config = require('./../models/configModel');
var _ = require('underscore');
var lastPage = false,
    lastTitle = false;

module.exports = {


    /**
     * track a dimension
     *
     * dimension1 = "Online booking price visible"
     * dimension2 = "Online booking step size"
     * dimension6 = "Online booking deposit setting"
     * @param dimId
     * @param description
     */
    trackDimension:function(dimId, description) {
        if (typeof gtag === 'function') {
            gtag('set', 'dimension'+dimId, description);
        }
    },


    /**
     * sets user details for intercom and ga, doesn't track login as event
     * because this can be triggered on an f5
     *
     * @param businessModel
     * @param userModel
     * @param name
     */
    login:function(businessModel, userModel) {

        var userSettings = {
            app_id: window.intercom_app_id,
            name: userModel.get('firstName') + " " + userModel.get('lastName'),
            email: userModel.get('email'),
            // TODO: The current logged in user's sign-up date as a Unix timestamp. //created_at
            //fb
            //gender
            company: {
                id: businessModel.get('id'),
                name: businessModel.get('name'),
                business_type: businessModel.get('branch'),
                prices_enabled: businessModel.get('prices_enabled'),
                phone_enabled: businessModel.get('phone_enabled'),
                cancellations_enabled: businessModel.get('cancellations_enabled'),
                cancel_period: businessModel.get('cancel_period'),
                background_image_enabled: businessModel.get('background_image_enabled'),
                step_size: businessModel.get('step_size'),
                deposit_type: businessModel.get('deposit_type'),
            }
        };

        this.setGA4UserId("x" + userModel.get('id'));

        if (typeof Intercom === 'function') {
            Intercom('boot', userSettings); //jshint ignore:line

        }
    },
    setGA4UserId: function(userId) {
        if (typeof gtag !== 'function') {
            return;
        }

        gtag('set', 'user_id', userId);
    },
    trackError:function(message) {
        if (typeof gtag === 'function') {
            // this 'general' could probably be changed to a more useful event name
            _.forEach([window.ga4_id_internal, window.ga3_id_internal], function(send_to_param) {
                gtag('event', 'general', {
                    'event_category': 'Warning',
                    'event_action': 'Saw error popup',
                    'event_label': message,
                    'value': 1,
                    'send_to': send_to_param,
                });
            });
        }
        if (typeof Intercom === 'function') {
           Intercom('trackEvent', 'user-recieved-error',{message:message}); //jshint ignore:line
        }
    },

    trackIntercomEvent:function(label, metadata){
        if (typeof Intercom === 'function') {
            Intercom('trackEvent', label, metadata); //jshint ignore:line
        }
    },

    /**
     *
     * https://developers.google.com/analytics/devguides/collection/analyticsjs/events
     *
     * @param visible true visible to customers and us, false just us
     * @param category
     * @param action
     * @param label optional
     * @param value optional
     */
    trackGAEvent: function (visible, category, action, label, value) {
        console.log('tracking event:',visible,"|", category,"|", action,"|", label,"|", value);

        if (typeof gtag === 'function') {
            var send_to_list = window.businessTracker && visible ?
                [window.ga4_id_internal, window.ga3_id_internal, window.ga4_id_external_business] :
                [window.ga4_id_internal, window.ga3_id_internal];

            _.forEach(send_to_list, function(send_to_param) {
                gtag('event', 'general', {
                    'send_to': send_to_param,
                    'event_category': category,
                    'event_action': action,
                    'event_label': label,
                    'value': value
                });
            });
        }
    },
    /**
     * Add a new pageview
     *
     * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
     *
     * @param url
     * @param title
     */
    trackPageView: function (url, title) {
        if ((typeof ga === 'function') ||(typeof gtag === 'function') ) {
            if (!title) {
                title = url;
            }

            if (lastPage === url && lastTitle === title) {
                console.log('skipping duplicate url submit');
            } else {
                console.log('tracking page:', config.getBaseUrl() + url, ' title:', title);
                if (typeof gtag === 'function') {
                    gtag('event', 'page_view', {
                        'page_title': title,
                        'page_location': config.getBaseUrl() + url
                    });
                }
            }

            lastPage = url;
            lastTitle = title;
        }

        if (typeof Intercom === 'function') {
            Intercom('update');//jshint ignore:line
        }

    },
    logout: function() {
        if (typeof Intercom === 'function') {
            Intercom('shutdown');//jshint ignore:line
        }

        this.setGA4UserId(null);
    },

    trackFBEvent: function(eventType, businessType, params) {
        if (typeof fbq === 'undefined') {
            return;
        }

        if (module.exports.getPixelId('chain') || module.exports.getPixelId('business')) {

            if (typeof businessType !== 'undefined') {
                var pixelId = module.exports.getPixelId(businessType);
                if (pixelId) {
                    fbq('trackSingle', pixelId, eventType, params);
                }
            } else {
                fbq('track', eventType, params);
            }
        }
    },

    getPixelId: function(businessType) {
        var pixelId = null;
        if (businessType === 'chain'){
            pixelId = chainPixelId;
        } else {
            pixelId = businessPixelId;
        }

        if (pixelId === null || typeof pixelId === 'undefined' || pixelId.trim() === ''){
            return false;
        }
        return pixelId;
    }

};
