/**
 * Holds details about a staff member
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    config = require('./../../js/models/configModel');


module.exports = Backbone.Model.extend({

    urlRoot: config.getAPIUrl() + "staff/" + config.getBusinessToken()
});
