/**
 * Classes containing services/packages should extend this
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    config = require('./../../js/models/configModel');

Backbone.$ = $;

/** @global */
window._ = _;

module.exports = Backbone.Collection.extend({

    servicesCollection: {},
    packagesCollection: {},
    categoryModel: {},
    stateModel: {},
    businessModel: {},
    baseUrl: "",
    initialize: function (models, options) {

        this.baseUrl = config.getAPIUrl() + 'booking/' + config.getBusinessToken();
        if (typeof options === 'undefined') {
            options = {};
        }

        if (typeof options.servicesCollection !== 'undefined') {
            this.servicesCollection = options.servicesCollection;
        }
        if (typeof options.packagesCollection !== 'undefined') {
            this.packagesCollection = options.packagesCollection;
        }
        if (typeof options.categoryModel !== 'undefined') {
            this.categoryModel = options.categoryModel;
        }
        if (typeof options.stateModel !== 'undefined') {
            this.stateModel = options.stateModel;
        }
        if (typeof options.businessModel !== 'undefined') {
            this.businessModel = options.businessModel;
        }
    },

    //implement in sub module
    /* istanbul ignore next */
    process: function (filter) {

    }
});

