"use strict";

var optimize = function(img, format, max, ratio, callback) {
    if(typeof max === 'undefined' || max == null) {
        max = 2000;
    }

    if(typeof format === 'undefined'|| format == null) {
        format = 'image/jpg';
    }

    if(typeof ratio === 'undefined' || ratio == null) {
        ratio = 0.7;
    }

    var width = img.width, height = img.height;

    if (width > height) {
        if (width > max) {
            //height *= max_width / width;
            height = Math.round(height *= max / width);
            width = max;
        }
    } else {
        if (height > max) {
            //width *= max_height / height;
            width = Math.round(width *= max / height);
            height = max;
        }
    }

    var canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");

    var returnObj = {
        'content' : null,
        'height' : height,
        'width' : width
    };

    ctx.drawImage(img, 0, 0, width, height);

    returnObj.content = canvas.toDataURL('image/jpeg', ratio);// get the data from canvas as 70% JPG (can be also PNG, etc.)

    if(typeof callback !== 'undefined') {
        callback(returnObj);
    }
};

module.exports = {
    imageOptimize: optimize
};
