/**
 * List of packages
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    PackageModel = require('./../models/packageModel'),
    BaseServiceCollection = require('./baseServiceCollection'),
    Backbone = require('backbone');
var config = require('./../models/configModel');

Backbone.$ = $;

/**
 * stores all available packages from the server.
 * Everything else holds a pointer to these models
 */
module.exports = Backbone.Collection.extend({
    model: PackageModel,
    businessToken: false,
    url:
        /* istanbul ignore next */
        function() {
            var bid = this.businessToken;
            if (!bid) {
                bid = config.getBusinessToken();
            }
            return config.getAPIUrl() + 'package/' + bid;
    },
    // init of the model
    initialize: function(models, options) {
        BaseServiceCollection.prototype.initialize(models, options);
    },
    /**
     *
     * @param serviceId
     * @returns {string}
     */
    findServiceName: function(serviceId) {
        var name = "";

        _.find(this.models, function(packageModel){
            name = packageModel.getNameFromPackageItem(serviceId);
            return name !== "";
        });

        return name;
    },

    /**
     *
     * @param serviceId
     * @returns {string}
     */
    findPackageName: function(packageItemId) {
        var name = "";

        _.each(this.models, function(packageModel){
            _.each(packageModel.get('services'), function(service, index){
                if (service.id === packageItemId) {
                    name = packageModel.get('name');
                }
            });
        });

        return name;
    },

    /**
     *
     * @param packageName
     * @returns {undefined}
     */
    getPackageByName: function(packageName) {
        var _package;

        _.each(this.models, function(packageModel){
            if (packageModel.attributes.name === packageName) {
                _package = packageModel;
            }
        });

        return _package;
    }
});

