"use strict";

var $ = require('jquery'),
    Backbone = require('backbone'),
    _ = require('underscore'),
    Attachment = require('./../models/attachmentModel'),
    config = require('./../../js/models/configModel');

module.exports = Backbone.Collection.extend({
    model: Attachment,
    url: config.getAPIUrl() + 'attachment/',

    initialize: function(business_id, owner_kid, owner_type) {
        this.url = config.getAPIUrl() + 'attachment/' + business_id + '?owner_id=' + owner_kid + '&owner_type=' + owner_type;
    }
});

