/**
 * Accept new terms view
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');
var $ = require('jquery'),
    _ = require('underscore'),
    template = Handlebars.compile(require('./../../templates/acceptNewTermsView.hbs').default),
    Backbone = require('backbone'),
    BaseView = require('./baseView'),
    config = require('./../models/configModel');

Backbone.$ = $;
window.jQuery = $;
module.exports = BaseView.extend({
    page: 'Terms updated',
    pageTitle: 'Terms updated',
    template: template,
    termsVisible: false,
    events: function () {
        //merge events from the parent class with events set in this local variable
        var events = {
            "click .tcCloseBtn": "clickClose",
            "click .tcAcceptBtn": "clickAccept",
            'click #tcAcceptViewTCBtn': 'clickViewTerms'
        };

        return _.extend(events, BaseView.prototype.events);
    },
    initialize: function (options) {
        if(options.prevPage) {
            this.prevPage = options.prevPage;
        }
        BaseView.prototype.initialize(options);
    },
    render: function () {
        this.$el.html(this.template());
        BaseView.prototype.render(this);
        this.setPageTitle(this.pageTitle);
    },
    clickClose: function(e) {
        var that = this,
            $termsModal = $('#termsModal');
        function closeCallback() {
            window.location.hash = that.prevPage;
            $('body').trigger('route:navigate_reload_from_state');
        }
        this.userModel.removeLoginCookies();
        if(this.termsVisible) {
            $termsModal.one('hidden.bs.modal', closeCallback);
            $termsModal.modal('hide');
        } else {
            closeCallback();
        }
    },
    clickAccept: function(e) {
        var that = this;
        config.fetchFromDom();
        $.post(config.getAPIUrl() + "customer/" + this.userModel.get('business_id') + "/accept_terms/" + this.userModel.get('id'))
            .done(function (data) {
                function closeCallback() {
                    that.userModel.set('termsAcceptRequired', false);
                    window.location.hash = that.prevPage;
                    $('body').trigger('route:navigate_reload_from_state');
                }
                var $termsModal = $('#termsModal');
                if(that.termsVisible) {
                    $termsModal.one('hidden.bs.modal', closeCallback);
                    $termsModal.modal('hide');
                } else {
                    closeCallback();
                }
        });
    },
    clickViewTerms: function(e) {
        e.preventDefault();

        $('#termsModal .modal-footer').html(
            '<button class="btn btn-default btn-lg pull-left mTop tcCloseBtn">Not now</button>'+
            '<button class="btn btn-success btn-lg pull-right mTop tcAcceptBtn">Accept</button>');
        this.termsVisible = true;

        var that = this,
            $termsModal = $('#termsModal');
        $termsModal.one('hidden.bs.modal', function() {
            that.termsVisible = false;
        });
        $('#termsModal').modal('show');

       /* $('#acceptTCContent').html($('#termsContent').html());
        $('#tcAcceptViewTCBtn').hide();*/
    }
});
