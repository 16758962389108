/**
 * Confirm email Modal
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    template = Handlebars.compile(require('./../../templates/modalConfirmEmail.hbs').default),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    BaseView = require('./baseView');

/*var MobileDetect = require('mobile-detect'),
    md = new MobileDetect(window.navigator.userAgent);*/

Backbone.$ = $;
window.jQuery = $;
require('jquery-validation');


var EmailEvents = {
    MODAL_CLOSED: "modalClosed"
};

module.exports = Backbone.View.extend({
    el: '#inner-modal',
    template: template,
    events: {
        'click #closeX': 'closeModal',
        'click #cancelModal': 'closeModal'
    },
    initialize: function () {
        this.EmailEvents = EmailEvents;
    },
    render: function () {
        var that = this;
        var email = this.model.get('pending_email');
        this.$el.html(this.template({'email': email}));
        that.show();
    },
    show: function () {
        $('#verifyEmailModal').modal('toggle');

    },
    closeModal: function () {
        $('#verifyEmailModal').modal('toggle');
        this.trigger(this.EmailEvents.MODAL_CLOSED);
    }
});

module.exports.EmailEvents = EmailEvents;