/**
 * Holds a booking
 *
 * @module
 */
"use strict";

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');

var config = require('./../../js/models/configModel');
var BaseModel = require('./baseModel');

Backbone.$ = $;

var DEFAULT_VALUE = 50;
var NO_CUSTOMER_VOUCHER = -4;

module.exports = BaseModel.extend({

    idAttribute: 'purchaseHash',

    defaults: {
        purchaseHash:  null,
        trackingNbr:  null,
        customerKid: NO_CUSTOMER_VOUCHER,
        customerBusinessId: null, //required
        value: DEFAULT_VALUE,
        sendToSelf: false,
        recipientEmail: null,
        recipientFirstName: '',
        recipientLastName: '',
        paymentReceiptEmail: null,
        buyerName: null,
        messageForRecipient: null,
        expiryDate: null,
        printTemplate: null,
        postParameters: null,
        queryData: null
    },

    getFullRecipientName: function() {
        return (this.get('recipientFirstName') + ' ' + this.get('recipientLastName')).trim();
    },

    initiate: function() {
        return this.postAction(this._actionUrl('initiate'));
    },

    finalize: function() {

        return this.postAction(this._actionUrl('finalize'));
    },

    _actionUrl: function(action) {
        return config.getAPIUrl() + 'voucher_sale/' + config.getBusinessToken() + '/' + action + this.getQueryParameter();
    },

    getQueryParameter: function () {
        var queryData = this.get('queryData');
        var out = '';

        if (queryData) {
            out = '?' + $.param(queryData);
        }

        return out;
    },

    /**
     * @returns jqXHR with an html response. This calls result is NOT cached in this model instance because the
     * response can change as the user modifies the form inputs.
     */
    fetchModalPreviewTemplate: function() {
        return this._fetchVoucherTemplate('render_preview');
    },

    /**
     * @param specificAction String
     * @returns jqXHR with an html response.
     */
    _fetchVoucherTemplate: function(specificAction) {
        //postAction style does not work with html response headers, because it places the response back into the model
        //attributes
        return $.post({
            data: {model: JSON.stringify(this.toJSON())},
            url: this._actionUrl(specificAction),
            dataType: "html"
        });
    }
});

