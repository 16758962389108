/**
 * Popular services
 *
 * Sorted by popular rank
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    BaseServiceCollection = require('./baseServiceCollection'),
    config = require('./../../js/models/configModel');

Backbone.$ = $;

module.exports = BaseServiceCollection.extend({
    comparator: 'popular',
    initialize: function (models, options) {
        BaseServiceCollection.prototype.initialize(models, options);
    },

    process: function () {
        var that = this;

        //caching
        if (this.length > 0) {
            return;
        }

        this.servicesCollection.each(function (serviceModel, key) {
            if (serviceModel.get('name') && serviceModel.get('popular')) {
                that.add(serviceModel);
            }
        });

        this.packagesCollection.each(function (packageModel, key) {
            if (packageModel.get('name') && packageModel.get('popular')) {
                that.add(packageModel);
            }
        });

    }
});

