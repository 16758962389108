/**
 * Holds a booking
 *
 * @module
 */
"use strict";

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');

Backbone.$ = $;

module.exports = Backbone.Model.extend({

    postAction: function(url, options){
        var defaultOptions = {
            emulateJSON: true,
            url: url,
            type: 'POST'
        };

        options = _.extend(defaultOptions, (options || {}));

        return this.save(this.attributes, options);
    },

    sync: function (method, model, options){
        options.emulateJSON = true;
        return Backbone.Model.prototype.sync(method, model, options);
    }

});

