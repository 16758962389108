/**
 * Global view, should be on everypage and handle common events.
 *
 * @module
 */
"use strict";
/* global FB */
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    StateModel = require('./../models/stateModel'),
    Handlebars = require('handlebars'),
    urlHelpers = require('./../helpers/urlHelpers'),
    config = require('./../models/configModel'),
    analytics = require('./../helpers/analytics'),
    ChangePasswordModalView = require('./changePasswordModalView'),
    loading = require('./../helpers/loadingScreen');

var BaseView = require('./baseView');

Backbone.$ = $;
require('bootstrap-growl');
require('jquery-validation');

Handlebars.registerPartial('partialTopBanner', Handlebars.compile(require('./../../templates/partialTopBanner.hbs').default));
Handlebars.registerPartial('partialBottomBanner', Handlebars.compile(require('./../../templates/partialBottomBanner.hbs').default));
Handlebars.registerPartial('partialEmailTakenModal', Handlebars.compile(require('./../../templates/partialEmailTakenModal.hbs').default));
Handlebars.registerPartial('partialFooter', Handlebars.compile(require('./../../templates/partialFooter.hbs').default));
Handlebars.registerPartial('partialHours', Handlebars.compile(require('./../../templates/partialHours.hbs').default));
Handlebars.registerPartial('partialServiceRow', Handlebars.compile(require('./../../templates/partialServiceRow.hbs').default));
Handlebars.registerPartial('tabAllServicesList', Handlebars.compile(require('./../../templates/tabAllServicesList.hbs').default));

Handlebars.registerPartial('modalTerms', Handlebars.compile(require('./../../templates/modalTerms.hbs').default));
Handlebars.registerPartial('modalHours', Handlebars.compile(require('./../../templates/modalHours.hbs').default));
Handlebars.registerPartial('partialCancelPolicy', Handlebars.compile(require('./../../templates/partialCancelPolicy.hbs').default));

/**
 * This view handles the global events that are intended to be on every page
 *
 * @type {void|*}
 */
module.exports = BaseView.extend({

    events: {
        'click #logoutBtn': 'logoutAction',
        'click #loginBtn.btn-success': 'loginAction',
        'submit #loginForm': 'killSubmit',
        'submit #forgotPassForm': 'killSubmit',
        'keyup #loginForm input[type="password"]': 'checkLoginEnterKeyAction',
        'keyup #changePwModal input': 'checkPwEnterKeyAction',
        'show.bs.modal #loginModal': 'loginModalShowEvent',
        'click #sendFeedback': 'sendFeedback',
        'click #feedbackLink': 'autoPopulateFeedbackFields',
        'click #cancelPolicyLink': 'populateCancelPolicy',
        'click #changePasswordLink': 'changePasswordAction',
        //'click #myDetailsLink': 'myDetailsAction',
        'click #loginModal .btn-facebook': 'loginFacebookAction',
        'click .refresh': 'refreshPage',
        'keyup #forgotPassForm input': 'checkForgotEnterKeyAction',
        'click #sendPwReminderModal.btn-success': 'resetPasswordAction',
        'show.bs.modal #forgotModal': 'pwModalShowEvent',
        'show.bs.modal #termsModal' : 'termsModalShowEvent',
        'blur #mobile' : 'doMobileValidate',
        'keyup #mobile.warning, #mobile.checked': 'doMobileValidate',
        'click #signupButton': 'signUpAnywhere',
        'click #topLoyaltyInfo .close': 'stopShowingLoyaltyBlurb',
        'click .goTop': function(){window.scrollTo(0,0);} //TODO: $('html, body').animate({scrollTop: 0}, 300); instead, animates it so it seems less jumpy. breaks for some reason though...
    },

    initialize: function (options) {
        BaseView.prototype.initialize(options);
        $.validator.addMethod('valid_email', function (value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        }, "Please enter a valid email address.");
        $(document).on('show.bs.modal', '#changePwModal', function (e) {
            $('#savePassBtn')
                .removeClass('disabled btn-default')
                .addClass('btn-success')
                .html('Save password');

            $('#changePwModal input').val('');
        });

        $(document).on('click', "#btnCloseUpdateBrowser", function() {
            var element = document.getElementById("outdated");
            element.parentNode.removeChild(element);
        });

        _.bindAll(this, 'loginCallback',
            '_handleLoginResult','loginAction','loginFacebookAction',
            'startFacebookLogin','resumeFacebookLogin');
    },

    logoutAction: function (e) {
        e.preventDefault();
        analytics.trackGAEvent(false, 'Account', 'Logged out');
        $.cookie('kitomba.onlinebookings2.enableChangePassword', 'false');
        this.userModel.removeLoginCookies();
        this.stateModel.clearData();
        window.location.hash = "";
        //if on homepage the hash="" does nothing so need to reload to be sure;
        $('body').trigger('route:navigate_reload_from_state');
    },

    loginCallback:function (data, redirect) {
        var that = this,
            $body = $('body'),
            redirectHash = false,
            redirectFunction = function() {
                switch(redirect) {
                    case 'myProfile/bookings':
                        return '#myProfile/bookings';
                    case 'prevBooked': //jshint ignore:line
                    default:
                        return '#services/previous';
                }
            };
        $('#loginFailure').hide();

        if(redirect !== 'none' && data.customer.termsAcceptRequired) {
            //as long as maxium depth of router / is 2 (i.e. services/popular) this is fine
            redirectHash  = '#acceptTerms/' + encodeURIComponent(redirectFunction());
        } else if (redirect !== 'none') {
            //Make sure to redirect them back to previously booked once they are logged in.
            redirectHash  = redirectFunction();
        } else if (data.customer.termsAcceptRequired) {
            //as long as maxium depth of router / is 2 (i.e. services/popular) this is fine
            redirectHash = urlHelpers.onHomePage() ? '#landing' : urlHelpers.getHashPath();
            redirectHash  = '#acceptTerms/' + encodeURIComponent(redirectHash);
        } else if (urlHelpers.onHomePage()) {
            redirectHash = '#myProfile/bookings';

            if (!that.userModel.hasAnyBooking()) {
                if(that.businessModel.is_chain()) {
                    redirectHash = "#branchList";
                }
            }
        }
        if (redirectHash) {
            window.location.hash = redirectHash;
        }

        analytics.trackGAEvent(false,"Account", "Logged in", urlHelpers.getHashPath());
        $body.trigger('route:navigate_reload_from_state');

        //hack for unit tests
        return redirectHash;
    },

    stopShowingLoyaltyBlurb: function() {
        this.userModel.set('showLoyaltyBlurb', false);
    },

    _handleLoginResult: function(data, email){
        var that = this, futureApptsPromise, pastApptsPromise;

        that.userModel.setLoginCookies(email, data.api_token, data.customer);
        var $loginModal = $('#loginModal'),
            $loginModalMsg = $loginModal.find('.loginModalMessage'),
            redirect = 'none';

        if ($loginModalMsg.data('reason') !== undefined) {
            redirect = $loginModalMsg.data('reason');
            $loginModal.find('.loginModalMessage').hide();
        }

        var loginCallbackPartial = _.partial(that.loginCallback.bind(that), data, redirect);

        if(urlHelpers.onHomePage()) {
            futureApptsPromise = that.userModel.fetchFutureAppointments(10, that.userModel.get('business_id'));
            pastApptsPromise = that.userModel.fetchPastAppointments(10, that.userModel.get('business_id'));
            $.when(futureApptsPromise, pastApptsPromise).always(function(futureAppts, pastAppts){
                if($loginModal.is(':visible')){
                    //fix for modal not closing correctly
                    setTimeout(function () {
                        loginCallbackPartial();
                        $loginModal.modal('hide');
                    }, 1000);

                } else {
                    loginCallbackPartial();
                }
            });
        } else {
            if($loginModal.is(':visible')){
                setTimeout(function () {
                    loginCallbackPartial();
                    $loginModal.modal('hide');
                }, 1000);
            } else {
                loginCallbackPartial();
            }
        }

    },

    loginAction: function (e) {
        e.preventDefault();

        var that = this, futureApptsPromise, pastApptsPromise,
            $password = $('#password'),
            $email = $('#email'),
            $loginBtn = $('#loginBtn'),
            $loginModal = $('#loginModal');
        $email.blur();
        $password.blur();
        $('#loginFailure').hide();
        if (!this.initLoginValidator()) {
            return;
        }
        $loginBtn.removeClass('btn-success').addClass('btn-default disabled');
        config.fetchFromDom();

        var email = $email.val();
        var password = $password.val();

        this.userModel.login(email, password, function(data){
            $.cookie('kitomba.onlinebookings2.enableChangePassword', 'true');
            that.userModel.set('enableChangePassword', true);
           that._handleLoginResult(data, email);
        }).fail(function(){
            $('#loginFailure').show();
        }).done(function () { //hacky fix for selenium tests
            if($loginModal.is(':visible')) {
                
                $('#loginModal').modal('hide');
            }
        }).always(function(){
            $loginBtn.addClass('btn-success').removeClass('btn-default disabled');
        });
    },

    loginFacebookAction: function(e){
        e.preventDefault();

        if(window.allowNormalFacebookFlow){
            this.startFacebookLogin(this);
        } else{
            if (_.contains(window.overrideNormalFacebookFlow, this.businessModel.get('id'))) {
                this.startFacebookLogin(this);
            }else{
                //this is for KIT-20738
                //immediately skip fb stuff to show modal
                $('#fbTempError').modal('show');
            }
        }
    },

    startFacebookLogin: function(){

        var that = this;
        var apiOptions = {scope: 'email'};

        if (this.businessModel.get('facebookReauthenticate')) {
            let nonce = this.businessModel.get('id') + Date.now(); // unique string to validate user login
            apiOptions = {scope: 'email', auth_type: 'reauthenticate', auth_nonce: nonce};
        }
        if (that.userModel.shouldUseFacebookRedirect()) {
            that.userModel.redirectToFacebook();

        } else if(that.stateModel.get('facebookActive')){
            that.userModel.getFacebookLibrary().login(function(response){
                if(response.status === 'connected'){
                    $('body').addClass('loading');
                    that.resumeFacebookLogin();
                    $('#loginModal').modal('hide');

                } else {
                    $('#fbErrorCode').text('ERR_GEV_SDK_LOGIN_ABORT');
                    $('#fbError').modal('show');
                    analytics.trackGAEvent(false,'Error','Facebook error');
                }
            }, apiOptions);
        } else {
            $('#fbErrorCode').text('ERR_GEV_SDK_INACTIVE');
            $('#fbError').modal('show');
            analytics.trackGAEvent(false,'Error','Facebook error');
        }
    },

    resumeFacebookLogin: function() {
        var that = this;
        $.cookie('kitomba.onlinebookings2.showFbSignup', 'true');
        $.cookie('kitomba.onlinebookings2.enableChangePassword', 'false');

        that.userModel.fblogin().done(function(data){
            $.cookie('kitomba.onlinebookings2.showFbSignup', 'false');
            data = JSON.parse(data);
            that._handleLoginResult(data, data.customer.clean_email);
        }).fail(function(error){
            error.errorHandled = true;
            window.location.reload();
        }).always(function(){
            $('body').removeClass('loading');
        });
    },

    refreshPage : function () {
        window.location.reload();
    },

    killSubmit: function(e) {
        e.preventDefault();
        return false;
    },

    checkLoginEnterKeyAction: function (e) {
        if (e.which === 13 && $('#loginBtn').hasClass('btn-success')) { // enter key
            this.loginAction(e);
        }
        return false;
    },

    checkForgotEnterKeyAction: function (e) {
        if (e.which === 13 && $('#sendPwReminderModal').hasClass('btn-success')) { // enter key
            this.resetPasswordAction(e);
        }
        return false;
    },
    checkPwEnterKeyAction: function (e) {
        if (e.which === 13) { // enter key
            if(this.changePasswordModalView !== null){
                this.changePasswordModalView.savePass();
            }
        }
    },
    resetPasswordAction: function (e) {
        e.preventDefault();
        $('#loginFailure').hide();
        var $reset_password_email = $('#reset_password_email'),
            email = $reset_password_email.val(),
            $forgotPWBtn = $('#sendPwReminderModal');
        $forgotPWBtn.removeClass('btn-success').addClass('btn-default disabled');
        $reset_password_email.blur();
        var postData = {
            'email': email
        };

        if (window.location.hash === ''
            || window.location.hash === '#landing'
            || window.location.hash === '#') {
            postData.stage = 'fromHomePage';//Keyword...
        } else {
            postData.stage = encodeURIComponent(decodeURI(window.location.hash));
        }

        config.fetchFromDom();
        $.ajax({
            url: config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/reset_password",
            type: "POST",
            data: postData,
            suppressErrors: true
        }).done(function () {
                $('#forgotPWFailure').hide();
                $('#forgotPassForm').fadeOut(function () {
                    $('#pwResetSentTo').html(postData.email);
                    $('#pwEmailSentMsg').show();
                });
            })
            .fail(function () {
                $('#forgotPWFailure').show();
                $forgotPWBtn.addClass('btn-success').removeClass('btn-default disabled');
            });
    },

    loginModalShowEvent: function (event) {
        $('#loginFailure, .loginModalMessage').hide();
        var button = $(event.relatedTarget),
            modal = $(event.currentTarget),
            from = button.data('from'),
            msgDiv = modal.find('.loginModalMessage');
        msgDiv.data('reason', 'none');
        if (typeof from !== 'undefined') {
            if(from === 'prevBooked') {
                msgDiv.html('<i class="fa fa-lock"></i> You need to be logged in to view your previously booked services.');
            } else if(from === 'myProfile/bookings') {
                msgDiv.html('<i class="fa fa-lock"></i> You need to be logged in to view my bookings.');
            } else if(from === 'myProfile/details') {
                msgDiv.html('<i class="fa fa-lock"></i> You need to be logged in to view my account.');
            } else if(from === 'myProfile/photos') {
                msgDiv.html('<i class="fa fa-lock"></i> You need to be logged in to view my photos.');
            }

            msgDiv.data('reason', from);
            msgDiv.show();
        } else {
            msgDiv.hide();
        }
    },

    pwModalShowEvent: function () {
        $('#sendPwReminderModal').addClass('btn-success').removeClass('btn-default disabled');
        $('#pwEmailSentMsg').hide();
        $('#forgotPassForm').show();
    },

    termsModalShowEvent: function() {
        $('span.businessName').text(window.brandBusinessName);
        $('span.name').text(window.brandName);
        $('span.websiteUrl').text(window.websiteUrl);
        $('span.privacyLink').text(window.privacyLink);
        $('span.supportEmail').text(window.supportEmail);
        $('span.businessAddress').text(window.businessAddress);
        $('span.registrationCode').text(window.registrationCode);
    },

    initLoginValidator: function () {
        var $loginForm = $('#loginForm');
        $loginForm.validate({
            //  onfocusout: false,
            //  onkeyup: false,
            //  onclick: false,
            rules: {
                email: {
                    required: true,
                    email: true,
                    valid_email:  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                },
                password: {
                    required: true,
                    minlength: 6
                }
            },
            messages: {
                email: {
                    required: "Please enter a valid email address",
                    email: "Please enter a valid email address",
                    valid_email: "Please enter a valid email address"
                },
                password: {
                    required: "Password is required",
                    minlength: "Password must be at least 6 characters"
                }
            },
            errorLabelContainer: "#loginValidationAnchor",
            wrapper: 'li',
            errorPlacement: function (error, element) {
                $('#loginValidationAnchor').append(error);
            }
        });

        return $loginForm.valid();
    },
    sendFeedback: function () {
        analytics.trackGAEvent(false,"Non-booking behaviour","Submitted feedback popup");
        this.feedbackValidate();
        if(!$('#feedback_form').valid()){
            return;
        }
        var email = $('#feedback_email').val(),
            name = $('#feedback_name').val(),
            feedback = $('#feedback_body').val(),
            postData = {
                'email': email,
                'name': name,
                'feedback': feedback
            };

        $('#sendFeedback')
            .addClass('disabled btn-default')
            .removeClass('btn-success')
            .html('Send feedback <i class="fa fa-circle-o-notch fa-spin"></i>');

        config.fetchFromDom();
        $.post(config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/feedback", postData)
            .done(function (data) {
                $('#feedbackModal').modal('hide');
                $('#feedbackSentModal').modal('show');
            })
            .fail(function (xhr, textStatus, errorThrown) {
                //TODO Need proper error handling here
                console.log('Feedback cannot be sent');
            });

    },

    autoPopulateFeedbackFields: function () {
        var first_name = this.userModel.get('firstName') ? this.userModel.get('firstName').trim() : "";
        var last_name = this.userModel.get('lastName') ? this.userModel.get('lastName').trim() : "";
        var email = this.userModel.get('clean_email') ? this.userModel.get('clean_email').trim() : "";

        $('#feedback_name').val((first_name + ' ' + last_name).trim());
        $('#feedback_email').val(email);
        $('#feedback_body').val('');

        this.removeFeedbackError();
        $('#sendFeedback')
            .removeClass('disabled btn-default')
            .addClass('btn-success')
            .html('Send feedback');

    },

    populateCancelPolicy: function () {
        var cancelPolicy = this.businessModel.get('cancellation_policy').trim() === '' ? false : this.businessModel.get('cancellation_policy');
        if(cancelPolicy !== false){
            $('#cancelBody').text(cancelPolicy);
        }
    },

    removeFeedbackError: function (){
        this.feedbackValidate();
        $('#feedback_form').validate().resetForm();
        $('#feedback_body').removeClass('error');
    },

    changePasswordAction: function(e){
        if(e != null){
            e.preventDefault();
        }
        this.changePasswordModalView = new ChangePasswordModalView({model: this.userModel});

        this.changePasswordModalView.render();

    },

    feedbackValidate: function(){
        $('#feedback_form').validate({
            rules: {
                feedback_body: {
                    required: true
                }
            },
            messages: {
                feedback_body: {
                    required: "Please provide some feedback"
                }
            },
            errorLabelContainer: "#feedbackValidationAnchor",
            wrapper: 'li',
            errorPlacement: function (error, element) {
                $('#feedbackValidationAnchor').append(error);
            }
        });
    },

    myDetailsAction: function(e){

        if (e != null) {
            e.preventDefault();
        }

        //this.showEditDetailsModal();

        var hash = "#myProfile/details";
        window.location.hash = hash;
    },

    doMobileValidate: function(evnt) {
        require(
            ['js/helpers/google_phone_number_handler'],
            (phoneNumberHandler) => {
                var value, isValid, $element = $(evnt.currentTarget);
                value = $element.val();

                if (value.trim() !== '') {
                    isValid = phoneNumberHandler.isValidMobile(value, this.businessModel.get('business_phoneNumberCountryCode'));
                    if (!isValid) {
                        $element.removeClass("checked");
                        $element.addClass("warning");
                        $element.siblings('i.notValid').show();

                        $element.siblings('i.valid').hide();
                    } else {
                        if ($element.hasClass('warning')) {
                            $element.addClass("checked");
                            $element.removeClass("warning");
                            $element.siblings('i.notValid').hide();
                            $element.siblings('i.valid').show();
                        }
                    }
                } else {
                    $element.removeClass("checked");
                    $element.removeClass("warning");
                    $element.siblings('i.notValid').hide();
                }
            });
    },
    signUpAnywhere: function(e){
        if(e!=null){
            e.preventDefault();
        }
        this.userModel.set('signUpNoBooking',true);
        if (this.businessModel.is_chain()) {
            $('body').trigger('route:navigate', '#branchListSignupAnywhere');
        } else {
            $('body').trigger('route:navigate', '#login/signup');
        }
    }
});
