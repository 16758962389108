/**
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    Handlebars = require('handlebars'),
    loading = require('./../helpers/loadingScreen'),
    BaseModal = require('./../components/baseModalView');

Backbone.$ = $;

Handlebars.registerPartial('baseModal', Handlebars.compile(require('./../../templates/partialBaseModal.hbs').default));

var template = Handlebars.compile(require('./../../templates/voucherPreviewModal.hbs').default);

module.exports = BaseModal.extend({
    template: template,

    show: function() {
        //fetched from k1 as data on the business object as this template is needed in four places across ob and k1
        var fetch = loading.until(this.model.fetchModalPreviewTemplate());
        fetch.then(this.insertResponse.bind(this));
    },

    insertResponse: function(renderedPreviewHtml) {
        //with out overriding render it will only work with this.model
        this.model.renderedPreviewHtml = renderedPreviewHtml;
        BaseModal.prototype.show.call(this);
    }

});
