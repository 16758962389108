/**
 * Photo events view, super class that contains all shared code for photo interactions.
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    StateModel = require('./../models/stateModel'),
    config = require('./../models/configModel'),
    analytics = require('./../helpers/analytics'),
    moment = require('moment'),
    photoSwipeInit = require('./../helpers/photo_swipe_init'),
    AttachmentModel = require('./../models/attachmentModel'),
    photoItem = Handlebars.compile(require('./../../templates/photoswipe/clientPhotoItem.hbs').default),
    imageOptimize = require('./../helpers/image_optimize');

Backbone.$ = $;
var BaseView = require('./baseView');
require('browsernizr/test/forms/fileinput');
var Modernizr = require("browsernizr");
Handlebars.registerPartial('photoItem', photoItem);

// variable used  to help detect whether we've 'left' the parent element when triggering dragleave etc.
// see: http://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
var counter = 0;

/**
 * Photo events view, super class that contains all shared code for photo interactions.
 *
 * @type {void|*}
 */
module.exports = BaseView.extend({

    fileUploadLimitInBytes: 8 * 1024 * 1024,
    imageAllowedTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/bmp'],
    photoUploadLimit: 10,
    photoUploadCounter: 0,
    fileImageError: "You file must be an image.",
    fileReaderError: "Your browser does not support file upload.",
    uploadSizeError: "Your file is greater than the supported maximum file size of 8Mb.",
    uploadTypeError: "Your file is not of an allowed photo type.",
    uploadLimitErrorPhotosTab: "You may only upload a maximum of 10 photos at a time.",

    /**
     *
     * @param options
     */
    initialize: function (options) {
        BaseView.prototype.initialize(options);

        if (options.userModel) {
            this.userModel = options.userModel;
        }
        if (options.stateModel) {
            this.stateModel = options.stateModel;
            this.photoUploadCounter = this.stateModel.get('booking_photos').length;
        }
    },

/**
 * ---------------------------
 * Event functions for photos.
 * ---------------------------
 */
    /**
     *
     * @param ev
     * @param callback
     * @param isConfirmScreen : adds this newly uploaded photo to the URL (only used on the confirm page view)
     */
    handlePhotoUploadEvent: function(ev, callback) {
        var validResults,
            filesService = $(ev.currentTarget)[0],
            files = !!filesService.files ? filesService.files : [],
            isImage = true;

        if( !files.length ) {
            return;
        }
        
        analytics.trackGAEvent(true, 'Photos', 'start upload');

        files = [].slice.call(files); //convert from FileList object into an array
        validResults = this.validateFiles(files, isImage);

        if(!validResults.valid) {
            this.showErrorMessage(validResults.errorMessage);
            return;
        } else {
            this.readFilesForUpload(files, isImage, callback);
        }
    },

    /**
     *
     * @param ev
     * @returns {boolean}
     */
    handlePhotoDeleteEvent: function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        var bid = $(ev.target).closest('a').data('bid'),
            id = $(ev.target).closest('a').data('id'),
            filename = $(ev.target).closest('a').data('filename');

        if (!$(".pswp__button--delete").hasClass('btn-danger')) {
            this.showErrorMessage(
                "<p>You can not delete this photo.</p>" +
                "<p>Please get in touch with us if you would like it removed.</p>");
            return;
        }
        var attachmentModel;
        this.userModel.get('attachments_photo').each(function(attModal){
            if(parseInt(attModal.id) === parseInt(id)) {
                attachmentModel = attModal;
            }
        });
        if(attachmentModel !== undefined) {
            this.showDeleteModal(attachmentModel);
        }
        return false;
    },

    /**
     *
     * @param ev
     */
    handleDragEnterEvent: function(ev) {
        counter++;
        $(ev.target).addClass('fileDrag');
        ev.preventDefault();
    },

    /**
     *
     * @param ev
     */
    handleDragOverEvent: function(ev) {
        ev.stopPropagation();
        ev.preventDefault();
    },

    /**
     *
     * @param ev
     */
    handleDragLeaveEvent: function(ev) {
        counter--;
        if (counter === 0) {
            $(ev.target).removeClass('fileDrag');
        }
    },

    /**
     *
     * @param ev
     * @param callback
     * @param isConfirmScreen : adds this newly uploaded photo to the URL (only used on the confirm page view)
     */
    handlePhotoDropEvent: function(ev, callback) {
        ev.stopPropagation();
        ev.preventDefault();

        //drag feedback reset. seems like the easiest way to do this...
        $('.fileDragZone').removeClass('fileDrag');
        counter = 0;

        var dt = ev.originalEvent.dataTransfer,
            files = dt.files,
            validResults,
            isImage = true;

        validResults = this.validateFiles(files, isImage);
        if(!validResults.valid) {
            this.showErrorMessage(validResults.errorMessage);
            return;
        } else {
            this.readFilesForUpload(files, isImage, callback);
        }
    },

    /**
     *
     */
    handleFileUploadEvent: function() {
        //not needed, no files to upload
    },

    /**
     *
     */
    handleFileDeleteEvent: function() {
        //not needed, no files to delete
    },

/**
 * ------------------------------
 * Internal functions for photos.
 * ------------------------------
 */
    /**
     *
     * @param files
     * @param isImage
     * @returns {{valid: boolean, errorMessage: string}}
     */
    validateFiles: function(files, isImage) {
        var that = this, valid = true, errorMessage = '';

        if (!window.FileReader) {
            valid = false;
            errorMessage = this.fileReaderError;
        }

        $.each(files, function(index, file){
            if(file.size > that.fileUploadLimitInBytes) {
                errorMessage = that.uploadSizeError;
                valid = false;
            }
            if(isImage) {
                if(that.imageAllowedTypes.indexOf(file.type) === -1){
                    errorMessage = that.uploadTypeError;
                    valid = false;
                }
            } else {
                valid = false;
                errorMessage = that.fileImageError;
            }
        });
        return {valid: valid, errorMessage: errorMessage};
    },

    /**
     * Creates the loop
     * @param files
     * @param isImage
     * @param callback
     */
    readFilesForUpload: function(files, isImage, callback) {
        var that = this;

        $.each(files, function(index, file){

            if (!that.doIncrementPhotoUploadCounter()) {
                if (that.isConfirmPageWithPhotoGallery()) {
                    that.showPhotoUploadErrorMessage(); //non-modal error for confirm page (due to weirdness)
                } else {
                    that.showErrorMessage(that.uploadLimitErrorPhotosTab);
                }
                return;
            }

            // image files
            var reader = new FileReader(); // instance of the FileReader

            reader.onloadend = function () {
                file.content = this.result;
                that.doFileUpload(file, isImage, callback);
            };

            if(isImage){
                reader.readAsArrayBuffer(file);
            }
        });

        if (that.isPhotosTabWithPhotoGallery()) {
            that.photoUploadCounter = 0;
        } //reset for next upload session (not for confirm page)
    },

    /**
     * Loop function
     * @param file
     * @param isImage
     * @param callback
     * @returns {{owner_type: string, owner_id: boolean, business_id: *, file_name: *, content: (Object|*), original_file_name: *, upload_date: *}}
     */
    doFileUpload: function(file, isImage, callback) {
        var that = this,
            attachmentType = 'client',
            attachmentModel;

        var filename = file.name;
        var owner_id = (that.userModel.get('id') ? that.userModel.get('id'): false);

        var fileObject = {
            owner_type : attachmentType,
            owner_id : owner_id,
            business_id: that.userModel.get('business_id') || config.getBusinessToken(),
            file_name: filename,
            content: file.content,
            original_file_name: filename,
            upload_date: moment().format('D MMM YYYY')
        };
        if(!owner_id) {
            //New customer, need temp ids on photos...
            fileObject.id = "unsaved" + that.userModel.get('attachments_photo').size();
        }
        attachmentModel = new AttachmentModel(fileObject);
        fileObject.format= attachmentModel.get('format');

        if(isImage){
            fileObject.type='photo';
            var blob = new Blob([file.content]); // create blob...
            window.URL = window.URL || window.webkitURL;
            var blobURL = window.URL.createObjectURL(blob); // and get it's URL

            var image = new Image();
            image.src = blobURL;
            image.onload = function() {
                imageOptimize.imageOptimize(image, file.type, null, null, function(optimizedImage) {

                    fileObject.content = optimizedImage.content;
                    fileObject.url = fileObject.thumb_url = fileObject.content;
                    fileObject.width = optimizedImage.width;
                    fileObject.height = optimizedImage.height;

                    //Everything gets converted to jpg.
                    if(fileObject.file_name.indexOf(".")) {
                        fileObject.file_name = fileObject.file_name.substring(0, fileObject.file_name.indexOf("."));
                    }
                    fileObject.file_name = fileObject.file_name + ".jpg";

                    that.addImageToGalleries(fileObject, function(elementList) {
                        that.doSaveFile(fileObject, isImage, elementList, callback);
                    });

                });
            };
        }
        return fileObject; //return this to aid testing
    },

    /**
     * Loop function
     * @param fileObject
     * @param isImage
     * @param elementList
     * @param callback
     */
    doSaveFile: function(fileObject, isImage, elementList, callback){
        var that = this, attachmentModel;

        attachmentModel = new AttachmentModel(fileObject);

        if(fileObject.owner_id) {
            //existing customer do a upload directly
            attachmentModel.save().done(function(result){
                //attachmentModel.set(result);

                if(isImage) { //image
                    if (that.isConfirmPageWithPhotoGallery()) { //only set by confirm page view
                        that.stateModel.addBookingPhotoIdToUrl(attachmentModel.get('id'));
                    }
                    that.userModel.get('attachments_photo').add(attachmentModel, {at: 0});

                    that.afterImageUpload(attachmentModel, elementList, false);

                    if (callback !== undefined) {
                        callback(result);
                    }

                    
                    analytics.trackGAEvent(true, 'Photos', 'successful upload');
                }
            }).fail(function(result) {
                _.each(elementList, function(ele) {
                    ele.remove();
                });
                that.showErrorMessage("File upload has failed. <br>Please check the file size - must be less than 8mb");
                return;
            });
        }
    },

    /**
     * Loop function
     * @param attachmentModel
     */
    doPhotoDelete: function(attachmentModel) {
        var fileId = attachmentModel.get('id'),
            that = this;

        if (fileId && $.isNumeric(fileId)) {
            attachmentModel.destroy().done(function(){
                if (that.isConfirmPageWithPhotoGallery()) { //only if we are using a stateModel for this photo save
                    that.stateModel.removeBookingPhotoIdFromUrl(attachmentModel.get('id'));
                }
                that.doDecrementPhotoUploadCounter();
                that.hidePhotoUploadErrorMessage();
                $('#fileDeleteConfirmation').modal('hide');
                $('figure a[data-id="' + fileId + '"]').closest('figure').remove();
                that.removeItemFromPhotoSwipe();
            }).fail(function() {
                $('#fileDeleteConfirmation').modal('hide');
                that.showErrorMessage("File could not be deleted. <br>Try refreshing the page.");
            });
        }
    },

    removeItemFromPhotoSwipe: function() {
        if(photoSwipeInit.getCurrentInstance().items.length === 1) {
            photoSwipeInit.getCurrentInstance().close();
        } else {
            var oldCurIndex = photoSwipeInit.getCurrentInstance().getCurrentIndex();
            if(oldCurIndex === photoSwipeInit.getCurrentInstance().items.length - 1) {
                photoSwipeInit.getCurrentInstance().goTo(oldCurIndex - 1);
            }
            photoSwipeInit.getCurrentInstance().items.splice(oldCurIndex, 1);

            photoSwipeInit.getCurrentInstance().invalidateCurrItems();
            photoSwipeInit.getCurrentInstance().updateSize(true);
        }
    },
/**
 * -------------------------
 * User interface functions.
 * -------------------------
 */
    /**
     *
     * @param fileObject
     * @param callback
     * @returns {*[]}
     */
    addImageToGalleries: function(fileObject, callback) {
        var newPhotoTabElem = $(photoItem(fileObject)),
            body = $('body'),
            elementList;

        newPhotoTabElem.addClass('stillLoading newUpload');
        newPhotoTabElem.append('<div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="100" aria-valuemax="100"></div>');

        elementList = [newPhotoTabElem];
        body.find('.galleryArea').prepend(newPhotoTabElem);

        if (callback) {
            callback(elementList);
        }

        return elementList; //return this for testing purposes
    },

    /**
     *
     * @param attachmentModel
     * @param elementList
     * @param isNewClient
     * @private
     */
    afterImageUpload: function(attachmentModel, elementList, isNewClient) {
        $('#noPhotosBlurb').hide();
        //Since element got added to multiple locations need to update multiple locations!
        _.each(elementList, function(element) {
            element.find('.photoThumbWrap').removeClass('stillLoading');

            if(!isNewClient){
                var replacement = $('<a />', {
                        'href': "#",
                        'class': 'photoThumbWrap',
                        'itemprop': 'thumbnail',
                        'data-filename': attachmentModel.get('file_name'),
                        'data-size': attachmentModel.get('width') + 'x' + attachmentModel.get('height'),
                        'data-id': attachmentModel.get('id'),
                        'data-bid': attachmentModel.get('business_id'),
                        'data-create_date': attachmentModel.get('create_date'),
                        'data-uploaded_by': 'client'
                    }
                );

                $(element).find('.progress-bar').remove();
                $(element).removeClass('stillLoading newUpload');
                if (element.find('a').attr("href") !== "#") {
                    replacement.attr("href", (attachmentModel.get('url') ? attachmentModel.get('url'): '#'));
                }
                element.find('a').children().each(function(index, elem) {
                    replacement.append(elem);
                });
                element.find('a').replaceWith(replacement);
                if (attachmentModel.get('thumb_url')) {
                    element.find("img").attr("src", attachmentModel.get('thumb_url'));
                    element.find(".photoThumb").css("background-image", "url(" + attachmentModel.get('thumb_url') + ")");
                }
            }
        });
    },

    /**
     *
     * @param attachmentModel
     * @private
     */
    showDeleteModal: function(attachmentModel) {
        var that = this;

        $('#fileDeleteConfirmation .modalBtns').show();
        $('#fileDeleteLoading').hide();

        $('#fileDeleteConfirmation').modal('show');

        $('#fileDeleteYes').off('click').on('click', function(e) {
            e.preventDefault();
            $('#fileDeleteConfirmation .modalBtns').hide();
            $('#fileDeleteLoading').show();
            that.doPhotoDelete(attachmentModel);
            return false;
        });

        $('#fileDeleteNo').off('click').on('click', function(e){
            e.preventDefault();
            $('#fileDeleteConfirmation').modal('hide');
            return false;
        });
    },

    /**
     *
     * @param message
     */
    showErrorMessage: function(message) {
        analytics.trackGAEvent(true, 'Photos', 'failed upload', message);

        var container = $("#clientErrorModal");

        if (typeof container !== "undefined") {
            container.find(".modal-body").html(message);
            container.modal('show');
        } else {
            alert(message);
        }
    },

    /**
     * Doing this way because the modal was disappearing during the asynchronous photo upload ajax
     * possibly to do with the code updating the DOM while the modal was showing, decided to simplify
     * this by using an in-line validation error instead.
     */
    showPhotoUploadErrorMessage: function() {
        $("#photoUploadLimitError").show();
    },

    /**
     *
     */
    hidePhotoUploadErrorMessage: function() {
        $("#photoUploadLimitError").hide();
    },

    /**
     *
     * @param hideEverything
     */
    hideFileInputIfNotSupported: function(hideEverything) {
        if (!Modernizr.fileinput) {
            $('.fileDragZone').remove();
            if (hideEverything) {
                $('.photoArea').remove();
            }
        }
    },

/**
 * ---------------------------------
 * Helper functions for ease of use.
 * ---------------------------------
 */
    /**
     *
     * @param gallery
     */
    initPhotoSwipeFromDOM: function(gallery) {
        photoSwipeInit.initPhotoSwipeFromDOM(gallery);
    },

    /**
     * Detects a dedicated id for the confirm page gallery.
     * @returns {boolean}
     */
    isConfirmPageWithPhotoGallery: function() {
        return ($('#confirmPagePhotoGallery').length === 1);
    },

    /**
     * Detects a dedicated id for the confirm page gallery.
     * @returns {boolean}
     */
    isPhotosTabWithPhotoGallery: function() {
        return ($('#myPhotosTabPhotoGallery').length === 1);
    },

    /**
     *
     * @returns {boolean}
     */
    doIncrementPhotoUploadCounter: function() {
        if (this.photoUploadCounter < this.photoUploadLimit) {
            this.photoUploadCounter++;
            return true;
        }
        return false;
    },

    /**
     *
     */
    doDecrementPhotoUploadCounter: function() {
        var count = 0;
        if (this.photoUploadCounter > 0) {
            count = this.photoUploadCounter - 1;
        }
        this.photoUploadCounter = count;
    }
});
