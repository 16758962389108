"use strict";

var Backbone = require('backbone');
var $ = require('jquery');

/**
 * Base "class" for creating modals in a consistent way
 *
 * Use partialBaseModal.hbs for the modal structure (see voucherPreviewModal.hbs for how to use it)
 *
 */
module.exports = Backbone.View.extend({

    show: function () {
        this.render();
        var modal = this.getModalElement();
        modal.modal('show');
        modal.on('hidden.bs.modal', this.tearDown.bind(this));
    },

    close: function() {
        this.getModalElement().modal('hide');
    },

    tearDown: function(){
        //not sure if we need to do this even removing as we are deleting the entire DOM element
        //but do it just in case
        this.undelegateEvents();
        this.$el.unbind();
        this.$el.remove();
    },

    render: function () {
        this.$el.html(this.template(this.model));
        $('body').append(this.$el);
        return this;
    },

    getModalElement: function(){
        return this.$el.find('.modal');
    }
});
