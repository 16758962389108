/**
 * Confirm a users email or phone when they signup
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    template = Handlebars.compile(require('./../../templates/confirmMobile.hbs').default),
    Backbone = require('backbone'),
    BaseView = require('./baseView'),
    analytics = require('./../helpers/analytics'),
    urlHelpers = require('./../helpers/urlHelpers'),
    UserModel = require('./../models/userModel.js');

var MobileDetect = require('mobile-detect'),
    md = new MobileDetect(window.navigator.userAgent);

require('jquery.cookie');
Backbone.$ = $;
/** @global */
window.jQuery = $;
require('jquery-validation');


module.exports = BaseView.extend({
    page: 'confirm',
    pageTitle: 'Confirm',
    template: template,
    events: {
        "keyup #pinEntryField": 'pinEntered',
        "click .verifyEmail": 'verifyByEmailAction',
        "click #editNumber": 'editNumberAction',
        "click #tryAgainLink": 'resendTxtButtonAction',
        "click #resendButton": 'resendTxtButtonAction'
    },

    initialize: function (options) {
        //call parent constructor
        this.options = options;
        //this.userModel = options.userModel;
        BaseView.prototype.initialize(options);
    },

    render: function () {
        var resentSMSCookie = $.cookie('resentSMS');

        this.options.resentSMS = resentSMSCookie === 'true';
        this.options.phone = this.model.get('phone');
        this.options.country = this.businessModel.get('country');

        this.$el.html(this.template({'options': this.options}));
        BaseView.prototype.render(this);
        this.setPageTitle(this.pageTitle);

        //lazy phone formatting, assume number is international (should be!)
        //and dont prepend the + if they have resent the sms
        if (this.$el.find('#revisedPhone').val().indexOf('+') < 0) {
            this.$el.find('#codeSentTo').prepend('+');
            this.$el.find('#revisedPhone').val('+' + $('#revisedPhone').val());
        }

        if (!md.mobile()) {
            $('#pinEntryField').focus();
        }

        $('#mobileIcon .fa-comment-o').css('opacity', 0);

        setTimeout(function () {
            $('#mobileIcon .fa-comment-o').css('opacity', 1);
            $('#mobileIcon').addClass('shake');

            setTimeout(function () {
                $('#mobileIcon').removeClass('shake');
            },700);

        }, 1000);

        setTimeout(function () {
        $('#mobileIcon').addClass('shake');

            setTimeout(function () {
                $('#mobileIcon').removeClass('shake');
            },700);

        }, 2500);

    },

    pinEntered: function () {
        this.model.set('email', $.cookie('userEmail'));
        var that = this;
        var currentCodeCount = this.model.incorrectCodeCount;
        var pinField = $('#pinEntryField');
        var codeEntered = pinField.val();
        $('.pretendEnterBox').removeClass('currentBox');
        $('#pretendEnterBox' + (codeEntered.length + 1)).addClass('currentBox');
        this.hideIncorrectPinError();
        if (codeEntered.length >= 4) {
            this.undelegateEvents();
            var blockCodeCheck = $.cookie('codeFailed');
            if (blockCodeCheck === 'true') {
                this.verifyByEmailDontResend();
                return;
            }
            $('#pinError').hide();
            $('#pinLoader').css('display', 'inline-block');
            //we want to do a put to check if the code is correct
            var hashPath = window.location.hash,
                query_index = hashPath.indexOf('?');
            if (query_index !== -1) {
                if(that.userModel.get('signUpNoBooking') == true){
                    hashPath = '#services' + hashPath.substr(query_index) + '&redirect=services/selected';
                }else{
                    hashPath = '#confirm' + hashPath.substr(query_index);
                }

            } else {
                hashPath = '#?redirect=myBookings';
            }

            var confirmPromise = this.model.confirmUser(codeEntered, this.model.get('email'), hashPath, false, 'sms');

            $.when(confirmPromise).then(function (data, textStatus, jqXHR) {
                that.clearPinValue();
                if (currentCodeCount < that.model.incorrectCodeCount) {
                    //got the code wrong.
                    pinField.prop('disabled', false);
                    $('#pinEntryForm .pins').finish().effect('shake', {distance: 4}, function () {
                        //'input' trigger is for resetting pretend box focus position.
                        //note mobile browsers probably won't let you do this.
                        //also note settimeout hack to fix ie11 bug
                        setTimeout(function () {
                            if (!md.mobile()) {
                                pinField.focus();
                            }
                            pinField.trigger('input');
                            $('#pretendEnterBox1').addClass('currentBox');
                        }, 200);
                    });
                    if (currentCodeCount >= 2) {
                        that.verifyByEmailAction();
                    }
                    else {
                        analytics.trackGAEvent(false, 'Account', 'Wrong PIN entered');
                        that.showIncorrectPinError();
                        $('#pinLoader').fadeOut('fast');
                        pinField.select();
                        that.delegateEvents();
                    }
                }
            });
        }
    },

    showIncorrectPinError: function () {
        $('<p>That code is not correct. Please double-check it and try again</p>').appendTo('#pinError');
        $('#pinError').show();
    },

    hideIncorrectPinError: function () {
        $('#pinError').hide();
        $('#pinError').html('');
    },

    clearPinValue: function () {
        $('#pinEntryField').val('');
    },

    verifyByEmailAction: function (e) {
        if (e != null) {
            e.preventDefault();
            var data = $(e.currentTarget).data();
            analytics.trackGAEvent(false,data.gaCategory, data.gaLabel, data.gaValue);
            analytics.trackIntercomEvent(data.gaCategory + "-" + data.gaLabel);
        }
        $('body').trigger('route:navigate', 'emailFallback/');
    },

    verifyByEmailDontResend: function (e) {
        if (e != null) {
            e.preventDefault();
        }
        $('body').trigger('route:navigate', 'emailFallback/noResend');
    },

    editNumberAction: function (e) {
        e.preventDefault();
        $('#smsSentTo').hide();
        $('#revisedPhoneWrap').slideDown('fast');
    },

    resendTxtButtonAction: function (e) {
        require(
            ['js/helpers/google_phone_number_handler'],
            (phoneNumberHandler) => {
                var that = this;
                if (e != null) {
                    e.preventDefault();
                }
                var phone = $('#revisedPhone').val(),
                    $btn = $('#resendButton');

                $btn.removeClass('btn-success')
                    .addClass('btn-default disabled')
                    .prop('disabled', true)
                    .append(' <i class="fa fa-circle-o-notch fa-spin"></i>');

                var phoneStrip = (phone.replace(/\D/g, ''));
                phoneStrip = phoneNumberHandler.handleMobileParse(phoneStrip, this.businessModel.get('business_phoneNumberCountryCode'), 'db');

                var resendPromise = this.model.resendConfirmationTxt(phoneStrip);
                $.when(resendPromise).then(function (data, textStatus, jqXHR) {
                    that.model.set('phone', data.customer.phone);
                    $.cookie('userPhone', data.customer.phone);
                    that.render();
                });
                $.cookie('resentSMS', true);
            });
    }
});