"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    UrlHelper = require('./../helpers/urlHelpers'),
    loading = require('./../helpers/loadingScreen'),
    analytics = require('./../helpers/analytics'),
    KitombaPayFormView = require('../views/kitombaPayFormView'),
    VoucherSale = require('./voucherSale'),
    Backbone = require('backbone');
var config = require('./../../js/models/configModel');

Backbone.$ = $;

module.exports = function(businessModel){
    return {
        driverName: "kitombapay",
        payButton: null,

        /**
         * Generate a script tag for the till payment.js sdk
         *
         * Returns a promise that will resolve when the script tag onload event triggers. The sdk itself makes the
         * global constructor 'PaymentJs' available
         *
         * @returns {never}
         */
        loadPaymentsSdk: function (sdkUrl) {
            var deferred = $.Deferred();

            // attach handler to the window so that the onload= attribute in script tag can find it
            // i tried various approaches with .on('load') and had a lot of difficulty getting them to behave correctly
            window.resolvePaymentsPromise = function () {
                deferred.resolve();
            };

            var paymentJs = $("<script>").on('load', window.resolvePaymentsPromise)
                .attr('data-main', 'payment-js')
                .prop('src', sdkUrl);

            document.body.appendChild(paymentJs[0]);

            return deferred.promise();
        },

        getPaymentOptionIcons: function() {
            return '/bookings/img/windcave-pay-options.png';
        },

        setupVoucherButton: function (voucherPageView, clickHandler, button){
            this.showKitombaPayLogo();
        },

        preRenderFormView: function (driverParameters) {
            var that = this;

            // pre-render the form, this will insert it hidden into the current page
            // this is so the iframe contents can load when we boot the payment sdk (initPaymentJs)
            this.formView.render();

            return this.loadPaymentsSdk(driverParameters.sdk_url).then(function () {
                // boot the payment.js with business' public integration key
                // this will initialize the CC/CVV fields and load the iframe
                return that.formView.initPaymentJs(driverParameters.public_integration_key);
            });
        },

        setupDepositButton: function (res, confirmPageView, clickHandler, button){
            this.showKitombaPayLogo();

            // replace the button with a loading state while we prepare the sdk/js form
            button.attr('data-button-text', button.text());
            button.addClass('disabled');
            button.html('<i class="fa fa-spinner fa-pulse"></i>');

            this.formView = new KitombaPayFormView({
                destinationUrlTemplate: res.parameters.destination_url_template,
                purchaseHash: res.apptHash,
                amount: res.price
            });

            this.preRenderFormView(res.parameters);
        },

        showKitombaPayLogo: function () {
            $('body').addClass('withKitombaPay');
        },

        hideKitombaPayLogo: function () {
            $('body').removeClass('withKitombaPay');
        },

        /**
         * Clicked 'Pay with Credit Card' button
         *
         * Show the credit card form ready for user input
         *
         * @param bookingModel
         */
        payDeposit: function (bookingModel) {
            var that = this;
            var apptHash = bookingModel.get('apptHash');

            if (bookingModel.hasNotes()) {
                loading.until(bookingModel.saveNotesByAppointmentHash(apptHash)).done(function () {
                    that.formView.injectView();
                });
            } else {
                this.formView.injectView();
            }

        },

        /**
         *
         * @param voucherSaleModel
         */
        payVoucher: function(voucherSaleModel) {
            var that = this;

            this.formView = new KitombaPayFormView({
                isVoucherSale: true,
                destinationUrlTemplate: voucherSaleModel.postParameters.destination_url_template,
                purchaseHash: voucherSaleModel.purchaseHash,
                amount: voucherSaleModel.value,
            });

            // there's no "initialize" hook to pre-render for voucher sale, so we have to just load and display it on demand
            return this.preRenderFormView(voucherSaleModel.postParameters).then(function () {
                that.formView.injectView();
            });
        },

        createVoucherSale: function(purchaseHash){
            return new VoucherSale({
                purchaseHash: purchaseHash,
            });
        },

        finalise: function (url, method, customer_kid, appt_hash) {
            return $.ajax({
                url: url,
                method: method,
                dataType: 'json',
                data: {
                    driver: this.driverName,
                    customer_kid: customer_kid,
                    appt_hash: appt_hash
                }
            });
        }
    };
};
