/**
 * Services
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    ServiceModel = require('./../models/serviceModel'),
    Backbone = require('backbone');
var config = require('./../models/configModel');

Backbone.$ = $;

/**
 * stores all available services from the server.
 * Everything else holds a pointer to these models
 */
module.exports = Backbone.Collection.extend({
    model: ServiceModel,
    businessToken: false,
    url:
    /* istanbul ignore next */
        function() {
            var bid = this.businessToken;
            if (!bid) {
                bid = config.getBusinessToken();
            }
            return config.getAPIUrl() + 'service/' + bid;
    }

});

