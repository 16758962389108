/**
 * Selected packages/services
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    BaseServiceCollection = require('./baseServiceCollection'),
    config = require('./../../js/models/configModel');

Backbone.$ = $;

/**
 * Used for the "selected" tab on the services page.
 *
 */
module.exports = BaseServiceCollection.extend({
    initialize: function (models, options) {
        BaseServiceCollection.prototype.initialize(models, options);
        //_.bindAll(this, 'process');
    },

    process: function () {
        var that = this;

        //needs to reset because items can change
        this.reset();

        this.servicesCollection.each(function (serviceModel, key) {
            if (serviceModel.get('name') && serviceModel.get('selected')) {
                that.add(serviceModel);
            }
        });

        this.packagesCollection.each(function (packageModel, key) {
            if (packageModel.get('name') && packageModel.get('selected')) {
                that.add(packageModel);
            }
        });
    },
    /**
     * add all services plus all services in packages
     */
    getNumberOfSelectedServices: function () {
        return _.reduce(this.models, function (memo, item) {
            if (item.get('type') === 'service') {
                return memo + 1;
            } else {
                return memo + item.get('services').length;
            }
        }, 0);
    }
});

