/**
 * Voucher page.
 *
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    templatePage = Handlebars.compile(require('../../templates/voucherPageView.hbs').default),
    VoucherPreviewModal = require('./../views/voucherPreviewModal'),
    VoucherSale = require('./../models/voucherSale'),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    BaseView = require('./baseView'),
    loading = require('./../helpers/loadingScreen');
var PaymentFactory = require('../models/paymentFactory');

Backbone.$ = $;

require('handlebars');
require('jquery-ui');
require('jquery-validation');
require('../custom_jquery_plugins/jquery.forcenumeric');

var mailCheckObj = require('./../helpers/kitomba_mailcheck');

module.exports = BaseView.extend({

    SEND_TO_SELF: 'myself',

    page: 'voucher',
    pageTitle: 'Buy a voucher',
    lastSelectedTab: '',
    servicesPopular: {},
    servicesAll: {},
    servicesSelected: {},
    template: templatePage,
    paymentDriver: null,

    events: function () {
        return {
            'change input[name=voucherOption]': 'voucherOptionChanged',
            'change input[name=voucherSendOption]': 'voucherSendOptionChanged',
            'blur #voucherEmailTo': 'doMailcheck',
             'blur #voucherEmailFrom': 'doMailcheck',
            'click .email-suggestion': 'chooseDomainSuggestion',
            'click #openVoucherPreviewModal': 'showVoucherPreview',
            'click .payButton': 'handlePayButtonClick'
        };
    },
    initialize: function (options) {
        this.businessModel = options.businessModel;
        this.userModel = options.userModel;

        this.model.set('buyerName', this.userModel.getFullName());
        this.model.set('paymentReceiptEmail', this.userModel.get('email'));
    },

    render: function () {
        this.setPageTitle(this.pageTitle);

        var templateData = this.model.toJSON();
        templateData.business = this.businessModel.toJSON();
        templateData.showVoucherEmailFrom =
            this.businessModel.get('payment_gateway') === "kitombapay"
            && !this.userModel.isLoggedIn();
        templateData.isLoggedIn = this.userModel.isLoggedIn();
        this.$el.html(this.template(templateData));

        BaseView.prototype.render(this);

        this.renderButton();
        this.initValidator();

        this.$el.find('#voucherValue').forcePositiveNumericDecimalsDecimalPlaces(2);

    },
    handlePayButtonClick: function(e) {
        var that = this;

        e.preventDefault();

        if(!this.$form().valid()) {
            return;
        }

        // prevent double clicks
        $(e.target).addClass('disabled');

        analytics.trackGAEvent(true, 'Voucher', 'voucher purchased started');

        this.model.set(this.getVoucherData());
        this.model.set('customerKid', this.userModel.get('id'));
        this.model.set('customerBusinessId', this.userModel.get('business_id'));

        var paymentReady = $.when(this.model.initiate()).then(function (model) {
            return that.paymentDriver.payVoucher(model);
        });

        loading.until(paymentReady);
    },

    renderButton: function () {

        this.paymentDriver = PaymentFactory.getPaymentModel(this.businessModel);
        var button = $("<a class='payButton mBtm btn-block btn btn-success btn-lg mTop'>Pay with Credit Card</a>");
        $('#voucherCreateForm').append(button);
        $('.payment_options').attr('src', this.paymentDriver.getPaymentOptionIcons());
        this.paymentDriver.setupVoucherButton(this, this.handlePayButtonClick.bind(this), button);

    },

    voucherOptionChanged: function (e) {
        if (e.target.value === 'other') {
            var that = this;
            this.$el.find('.valueCustom').slideDown('fast', function() {
                that.$el.find('#voucherValue').select();
            });
        } else {
            this.$el.find('#voucherValue').val(e.target.value); //set to selected radio's value, as this field's value we will submit
            this.$el.find('.valueCustom').hide();
        }
    },

    voucherSendOptionChanged: function (e) {
        this.$el.find('#voucherEmailFrom').val('');
        if (e.target.value === 'other') { // 'How to send: Email to recipient now' option checked
            mailCheckObj.hideHint(this.$el.find('#hint_voucherEmailTo'));
            if(this.userModel.get('logged_in') !== true) {
                this.$el.find('.js-voucher-email-from').show();
                if (this.businessModel.get('payment_gateway') === "kitombapay") {
                    this.$el.find('#voucherEmailFrom').show(); // KIT-23403 input field popped up without label
                }
            }

            this.$el.find('.js-email-to-self').hide();
            this.$el.find('.js-email-to-recipient, .message-wrap, .preview-link-wrap').show();
        } else {  // 'How to send: Email to yourself' option checked
            this.$el.find('.js-voucher-email-from').hide();

            this.$el.find('#voucherEmailTo').val(this.userModel.get('email') || '');
            this.$el.find('.js-email-to-self').show();
            this.$el.find('.js-email-to-recipient, .message-wrap, .preview-link-wrap').hide();

            this.$el.find('#voucherEmailFrom').hide(); // KIT-23403 input field popped up without label
        }
    },

    doMailcheck: function (event) {
        var $emailInput = $(event.currentTarget);
        $emailInput.mailcheck(mailCheckObj.getDefaultOptions());
    },

    chooseDomainSuggestion: function (event) {
        analytics.trackGAEvent(false, "Account", "Clicked a 'mailcheck' suggestion");
        mailCheckObj.updateInputWithHint($(event.currentTarget).parent());

        return false;
    },

    showVoucherPreview: function(e){
        e.preventDefault();

        var voucherData = this.getVoucherData();

        var modal = new VoucherPreviewModal({
            model: new VoucherSale(voucherData)
        });

        modal.show();

        return false;
    },

    $form: function(){
      return this.$el.find('#voucherCreateForm');
    },

    initValidator: function () {
        var voucherMaxSaleValue = parseInt(this.businessModel.get('voucher_max_sale'));
        var currencySymbol = this.businessModel.attributes.currency_symbol;
        $.validator.addMethod("cRange", $.validator.methods.range, 'Please enter a positive number<br />');

        var recipientFieldsAreRequired = !this.userModel.isLoggedIn();

        this.$form().validate({
            onfocusout: false,
            onkeyup: false,
            onclick: false,
            rules: {
                voucherValue: {
                    required: true,
                    cRange: [1, voucherMaxSaleValue]
                },
                voucherEmailTo: {
                    required: true,
                    email: true,
                    valid_email:  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                },
                senderName: {
                    required: true
                },
                voucherEmailFrom: {
                    email: true,
                    valid_email:  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                },
                voucherNotes: {
                    maxlength: 250
                },
                'recipient-first-name': {
                    maxlength: 40,
                    required: recipientFieldsAreRequired
                },
                'recipient-last-name': {
                    maxlength: 40,
                    required: recipientFieldsAreRequired
                }
            },
            messages: {
                voucherValue:{
                    required: 'Please enter a voucher value.',
                    cRange: 'Voucher value must be between ' + currencySymbol + '1 and ' + currencySymbol + voucherMaxSaleValue + '.'
                },
                voucherEmailTo: {
                    required: 'Please enter an email address.',
                    email: 'Please enter a valid email.'
                },
                senderName: {
                    required: 'Please enter your name.'
                },
                voucherEmailFrom: {
                    required: 'Please enter an email address for yourself.',
                    email: 'Please enter your valid email.'
                },
                voucherNotes: {
                    maxlength: 'Message for recipient must be less than 250 characters.'
                },
                'recipient-first-name': {
                    maxlength: 'Recipient first name must be less than 40 characters.',
                    required: 'Please enter a recipient first name.'
                },
                'recipient-last-name': {
                    maxlength: 'Recipient last name must be less than 40 characters.',
                    required: 'Please enter a recipient last name.'
                }
            },
            errorLabelContainer: "#voucherFormError",
            wrapper: 'li',
            errorPlacement: function (error) {
                this.$el.find('#voucherFormError').append(error);
            }
        });
    },


    getVoucherData: function(){
        var formData = this.getFormData(this.$form());

        var sendToSelf = formData.voucherSendOption === this.SEND_TO_SELF;
        var paymentReceiptEmail = this.userModel.get('logged_in') === true ?
            this.userModel.get('email') :
            (
                sendToSelf ? formData.voucherEmailTo : formData.voucherEmailFrom
            );

        return {
            value: (formData.voucherOption === 'other') ? formData.voucherValue : formData.voucherOption,
            recipientEmail: formData.voucherEmailTo,
            buyerName: formData.senderName,
            paymentReceiptEmail: paymentReceiptEmail,
            recipientFirstName: formData['recipient-first-name'].trim(),
            recipientLastName: formData['recipient-last-name'].trim(),
            messageForRecipient: sendToSelf ? null : formData.voucherNotes.trim(),
            sendToSelf: sendToSelf
        };
    }
});
