/**
 * Base View, all views should extend this
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    StateModel = require('./../models/stateModel'),
    config = require('./../models/configModel'),
    ChangePasswordModalView = require('./changePasswordModalView'),
    MyDetailsModalView = require('./myDetailsModalView'),
    MyDetailsModalCodeView = require('./myDetailsModalCodeView'),
    ConfirmEmailModal = require('./confirmEmailModal');

Backbone.$ = $;
/** @global */
window.jQuery = $;
require('jquery.cookie');
require('jquery-validation');

var MobileDetect = require('mobile-detect'),
    md = new MobileDetect(window.navigator.userAgent);

//loading all partials up front will slow down the user first render
Handlebars.registerPartial('partialTopBanner', Handlebars.compile(require('./../../templates/partialTopBanner.hbs').default));
Handlebars.registerPartial('partialBottomBanner', Handlebars.compile(require('./../../templates/partialBottomBanner.hbs').default));
Handlebars.registerPartial('partialEmailTakenModal', Handlebars.compile(require('./../../templates/partialEmailTakenModal.hbs').default));
Handlebars.registerPartial('partialPhotoSwipe', Handlebars.compile(require('./../../templates/photoswipe/partialPhotoSwipe.hbs').default));
Handlebars.registerPartial('partialFooter', Handlebars.compile(require('./../../templates/partialFooter.hbs').default));
Handlebars.registerPartial('partialHours', Handlebars.compile(require('./../../templates/partialHours.hbs').default));
Handlebars.registerPartial('partialTimeSlots', Handlebars.compile(require('./../../templates/partialTimeSlots.hbs').default));
Handlebars.registerPartial('partialServiceInformation', Handlebars.compile(require('./../../templates/partialServiceInformation.hbs').default));
Handlebars.registerPartial('partialServiceRow', Handlebars.compile(require('./../../templates/partialServiceRow.hbs').default));
Handlebars.registerPartial('partialTimeStaffRow', Handlebars.compile(require('./../../templates/partialTimeStaffRow.hbs').default));
Handlebars.registerPartial('partialStaffList', Handlebars.compile(require('./../../templates/partialStaffList.hbs').default));
Handlebars.registerPartial('tabAllServicesList', Handlebars.compile(require('./../../templates/tabAllServicesList.hbs').default));
Handlebars.registerPartial('partialTimeStaff', Handlebars.compile(require('./../../templates/partialTimeStaff.hbs').default));
Handlebars.registerPartial('partialCodeView', Handlebars.compile(require('./../../templates/partialCodeView.hbs').default));
Handlebars.registerPartial('partialPreviousLocations', Handlebars.compile(require('./../../templates/partialPreviousLocations.hbs').default));
Handlebars.registerPartial('partialLoyaltyBalance', Handlebars.compile(require('./../../templates/partialLoyaltyBalance.hbs').default));
Handlebars.registerPartial('partialLoyaltyBalanceInner', Handlebars.compile(require('./../../templates/partialLoyaltyBalanceInner.hbs').default));
Handlebars.registerPartial('partialConcessionBalances', Handlebars.compile(require('./../../templates/partialConcessionBalances.hbs').default));
Handlebars.registerPartial('partialCentralVoucherBalances', Handlebars.compile(require('./../../templates/partialCentralVoucherBalances.hbs').default));
Handlebars.registerPartial('modalTerms', Handlebars.compile(require('./../../templates/modalTerms.hbs').default));
Handlebars.registerPartial('modalHours', Handlebars.compile(require('./../../templates/modalHours.hbs').default));
Handlebars.registerPartial('modalChooseStaff', Handlebars.compile(require('./../../templates/modalChooseStaff.hbs').default));
Handlebars.registerPartial('termsContent', Handlebars.compile(require('./../../templates/termsContent.hbs').default));
Handlebars.registerPartial('partialEmailCheckInput', Handlebars.compile(require('./../../templates/partialEmailCheckInput.hbs').default));


module.exports = Backbone.View.extend({
    parent: '#content',
    tagName: 'div',

    initialize: function(options) {
        if (options.businessModel) {
            this.businessModel = options.businessModel;
        }
        if (options.branchesModel) {
            this.branchesModel = options.branchesModel;
        }
        if (options.stateModel) {
            this.stateModel = options.stateModel;
        }
        if(options.userModel) {
            this.userModel = options.userModel;
        }

        $('#feedbackSentModal').on('show.bs.modal', function (e) {
            $('#sendFeedback')
                .removeClass('disabled btn-default')
                .addClass('btn-success')
                .html('Send feedback');

            $('#feedback_body').val('');
        });

        //when opening a modal form, focus the first field
        if (!md.mobile()) {
            $(document).on('shown.bs.modal', function () {
                $(this).find('input:visible:first').focus();
            });
        }
    },

    /**
     *
     * @param title
     */
    setPageTitle: function(title) {
        //don't use .html, or else users can inject js on the main page with their customer business name
        $('title').text(title);
    },
    /**
     *
     * @returns {*}
     */
    mergeTemplateData: function() {
        var returnData;
        var otherObjects = {
            business: this.businessModel.attributes,
            branch_details: _.map(this.branchesModel.models,
                function(item) {
                    return item.attributes;
                }),
            page: this.page,
            userModel: this.userModel.attributes
        };

        var banner = $("body").data('banner');
        if (banner) {
            otherObjects.banner = banner;
        }

        if((this.businessModel.get('phone_enabled') && this.businessModel.get('phone')) || this.businessModel.get('address') || (this.businessModel.get('hours') && this.page !== 'landing' )){
            otherObjects.hasContactDetails = true;
        } else {
            otherObjects.hasContactDetails = false;
        }

        otherObjects.showLoginButton = true; //over ride this if you want to hide the login button on the banner template


        if (typeof this.model !== 'undefined') {
            returnData = _.extend({}, otherObjects, {'model':this.model.attributes});
        } else {
            returnData = _.extend({}, otherObjects);
        }
        returnData.baseUrl = config.getBaseUrl();

        return  returnData;
    },
    /**
     *
     * @param that
     */
    render: function(that) {
        $(this.parent).html(that.$el);
        that.delegateEvents();
    },

    getBranchListData: function(templateData,userModel,callback){
        var validDetails = (userModel.get('chain_customer_id') !== undefined && userModel.get('business_id') !== undefined && userModel.get('chain_customer_id') !== null && userModel.get('business_id')!== null);
        if(templateData.branch_details.length > 5 && validDetails){
            userModel.getPreviousBookedBranchDetails().done(function (data) {
                if (data.length > 0) {
                    templateData.prev_booked_branch_details = _.sortBy(data,'name');
                    templateData.showPreviousLocations = true;
                }
                callback(templateData);
            });
        }else{
            if(templateData.branch_details.length > 5){
                var branchFound = [];
                templateData.branch_details.forEach(function(branch){
                    if(branch.id === userModel.get('business_id')){
                        branchFound[0] = branch;
                        templateData.prev_booked_branch_details = branchFound;
                        templateData.showPreviousLocations = true;
                    }
                });
            }
            callback(templateData);
        }
    },


    loadCodeViewModal: function () {
        //will open code view
        this.myDetailsModalCodeView = new MyDetailsModalCodeView({
            model: this.userModel,
            businessModel: this.businessModel
        });
        this.myDetailsModalCodeView.render();

        var that = this;
        this.listenTo(this.myDetailsModalCodeView, MyDetailsModalCodeView.CodeEvents.CODE_CONFIRMED, function() {
            that.reloadViewFromState();
        });
    },
    loadSentEmailViewModal: function () {

        this.confirmEmailModal = new ConfirmEmailModal({
            model: this.userModel
        });
        this.confirmEmailModal.render();

        var that = this;
        this.listenTo(this.confirmEmailModal, ConfirmEmailModal.EmailEvents.MODAL_CLOSED, function() {
            that.reloadViewFromState();
        });

    },
    formatPhoneNumber: function(phoneNumber, callBack) {
        require(
            ['js/helpers/google_phone_number_handler'],
            (phoneNumberHandler) => {
                var formattedPhone = phoneNumberHandler.handleMobileParse(phoneNumber, this.businessModel.get('business_phoneNumberCountryCode'), 'show' );
                callBack(formattedPhone);
            }
        );
    },
    showEditDetailsModal: function() {
        var formattedPhone;
        var that = this;

        this.myDetailsModalView = new MyDetailsModalView({model: this.userModel, businessModel: this.businessModel});
        this.listenTo(this.myDetailsModalView, MyDetailsModalView.TriggerEvents.LOAD_CODE_VIEW, function () {
            that.userModel.sendPending('txt');
            that.loadCodeViewModal();
        });
        this.listenTo(this.myDetailsModalView, MyDetailsModalView.TriggerEvents.LOAD_EMAIL_VIEW, function () {
            that.userModel.sendPending('email');
            that.loadSentEmailViewModal();
        });
        this.listenTo(this.myDetailsModalView, MyDetailsModalView.TriggerEvents.RELOAD_PROFILE_VIEW, function () {
            var hash = window.location.hash;
            if (hash.indexOf("myProfile") > 0) {
                that.reloadViewFromState();
            }
        });

        const render = () => {
            this.myDetailsModalView.render();
            this.myDetailsModalView.show();
            $('[data-toggle="tooltip"]').tooltip();
        };

        if(this.userModel.get('phone')) {
            this.formatPhoneNumber(this.userModel.get('phone'), formattedPhoneNumber => {
                formattedPhone = formattedPhoneNumber;
                this.userModel.set('phone', formattedPhone);
                render();
            });
        } else {
            render();
        }
    },
    reloadViewFromState: function() {
        $('body').trigger('route:navigate_reload_from_state');
    },

    /**
     * Gets all the form fields as a object
     * Where the object key is the form name and the object key value is the form value
     * @param $form
     * @return {{}}
     */
    getFormData: function($form){
        var values = {};
        _.each($form.serializeArray(), function(field, i) {
            values[field.name] = field.value;
        });

        return values;
    }


});
