/**
 * Entry point to website
 *
 *
 */
"use strict";

function fakeConsole() {
    /**
     * Protect window.console method calls, e.g. console is not defined on IE
     * unless dev tools are open, and IE doesn't define console.debug
     *
     * Chrome 41.0.2272.118: debug,error,info,log,warn,dir,dirxml,table,trace,assert,count,markTimeline,profile,profileEnd,time,timeEnd,timeStamp,timeline,timelineEnd,group,groupCollapsed,groupEnd,clear
     * Firefox 37.0.1: log,info,warn,error,exception,debug,table,trace,dir,group,groupCollapsed,groupEnd,time,timeEnd,profile,profileEnd,assert,count
     * Internet Explorer 11: select,log,info,warn,error,debug,assert,time,timeEnd,timeStamp,group,groupCollapsed,groupEnd,trace,clear,dir,dirxml,count,countReset,cd
     * Safari 6.2.4: debug,error,log,info,warn,clear,dir,dirxml,table,trace,assert,count,profile,profileEnd,time,timeEnd,timeStamp,group,groupCollapsed,groupEnd
     * Opera 28.0.1750.48: debug,error,info,log,warn,dir,dirxml,table,trace,assert,count,markTimeline,profile,profileEnd,time,timeEnd,timeStamp,timeline,timelineEnd,group,groupCollapsed,groupEnd,clear
     */

// Union of Chrome, Firefox, IE, Opera, and Safari console methods
    var methods = ['assert', 'assert', 'cd', 'clear', 'count', 'countReset',
        'debug', 'dir', 'dirxml', 'dirxml', 'dirxml', 'error', 'error', 'exception',
        'group', 'group', 'groupCollapsed', 'groupCollapsed', 'groupEnd', 'info',
        'info', 'log', 'log', 'markTimeline', 'profile', 'profileEnd', 'profileEnd',
        'select', 'table', 'table', 'time', 'time', 'timeEnd', 'timeEnd', 'timeEnd',
        'timeEnd', 'timeEnd', 'timeStamp', 'timeline', 'timelineEnd', 'trace',
        'trace', 'trace', 'trace', 'trace', 'warn'];
    var length = methods.length;
    var console = (window.console = window.console || {});
    var method;
    var noop = function () {
    };
    while (length--) {
        method = methods[length];
        // define undefined methods as noops to prevent errors
        if (!console[method]) {
            console[method] = noop;
        }
    }
    return console;
}
window.console = fakeConsole();

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    config = require('./models/configModel'),
    StateModel = require('./models/stateModel'),
    Router = require('./router'),
    analytics = require('./helpers/analytics'),
    attachFastClick = require('fastclick');

require('./rollbar.js');

Backbone.$ = $;
window.jQuery = $;
require('jquery-ui');
//require('jquery-ui-touch-punch');
require('bootstrap');
require('jquery.cookie');
$.cookie.defaults = {path: '/bookings'};

function iosFix() {
    //This mess adds some padding if using iOS 8 on iphone/ipod.
    //Why? Because tapping near the bottom of the page
    // when in 'full screen' mode (which is entered into automatically when scrolling),
    // makes the browser footer appear instead of
    // triggering whatever you tapped on. Lame, huh? So we'll add some padding.
    //TODO: think about re-writing this with mobile-detect plugin... not strictly necessary though.
    var isIphoneOrIpad = function () {
        return (!!navigator.userAgent.match(/iPad/i) || !!navigator.userAgent.match(/iPhone/i));
    };
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

    if (isIphoneOrIpad() && isSafari) {
        $('body').addClass('iosPad');
    }
}

//this needs to be outside the document ready as it sets up the facebook init callback
var stateModel = new StateModel();

$(document).ready(function () {

    config.fetchFromDom();

    //$.ajaxSetup({ cache: false }); //fix ajax cache issue in IE

    $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
        if(typeof jqxhr.errorHandled !== 'undefined' && Boolean(jqxhr.errorHandled)){
            return;
        }

        var status = parseInt(jqxhr.status, 10);
        if (settings.suppressErrors) {
            //console.log("ajax error[suppressed]:",status);
            return;
        }
        if(window.location.hash.indexOf('#verify') !== -1){
            return;
        }
        // an XHR error on any #confirm page will automatically go to services behind the modal, set preventConfirmRedirect to prevent this behavior
        if(!jqxhr.preventConfirmRedirect && window.location.hash.indexOf('#confirm') !== -1){
            window.location.hash = 'services';
            $('body').trigger('route:navigate_reload');
            return;
        }
        //if we get a server error on an ajax call then show a modal notifying the user
        //other errors (ie 400's) need to be handled by application code
        var window_hash = window.location.hash;
        var error = (typeof (jqxhr.responseJSON) !== 'undefined') ? jqxhr.responseJSON.error : 'no error response';

        status = parseInt(status);
        if ((status >= 500 && status < 600) || status === 400 || status === 403) {
            $('#ajaxError').modal();
            analytics.trackError(thrownError + ' Error:'+ error +'. Hash:' + window_hash);
        }
    });

    //this handles non-ajax errors, ie runtime or compile time errors
    window.onerror = function (error, url, line) {
        //remove this when fast click is updated
        if( error.indexOf("Uncaught InvalidStateError: Failed to execute 'setSelectionRange' on 'HTMLInputElement': The input element's type") === 0) {
            console.log('fastclick warning');
            return false;
        }

        //$('#ajaxError').modal();
        analytics.trackError('Error: '+ error+' Line: '+line+' URL: '+url);
    };

    //need to override this function because it does some stupid subsituting of %25 which makes it impossible
    // to use router.naviage with encoded query params
    Backbone.History.prototype.decodeFragment = function (fragment) {
        return decodeURI(fragment);
    };


    var router = new Router({stateModel: stateModel});

    $('body').on('route:replace_only', function(e, parameter) {
        router.navigate(encodeURI(stateModel.buildStateHashUrl(parameter)), {trigger: false, replace: true});
    });

    $('body').on('route:navigate', function (e, parameter) {
        router.navigate(encodeURI(stateModel.buildStateHashUrl(parameter)), {trigger: true});
    });

    $('body').on('route:navigate_replace', function (e, parameter) {
        router.navigate(encodeURI(stateModel.buildStateHashUrl(parameter)), {trigger: true, replace: true});
    });

    $('body').on('route:navigate_reload', function (e) {
        Backbone.history.loadUrl(encodeURI(window.location.hash));
    });

    $('body').on('route:navigate_reload_with_error', function (e, parameter) {
        Backbone.history.loadUrl(encodeURI(stateModel.buildErrorHashHashUrl(parameter)));
    });

    $('body').on('route:navigate_reload_from_state', function (e) {
        Backbone.history.loadUrl(stateModel.updateURLHash());
    });

    $('body').on('click', 'a, button', function (e) {


      var o = $(this);

      if(o.hasClass("externalLink")) {
          return;
      }

      var reclick = 1000;
      if (o.data('disabled')){
        e.preventDefault();  // don't follow anchor
      }
      if (reclick) {
          o.data('disabled', true);
          setTimeout(function () {
              o.data('disabled',false);
          }, reclick);
      }
      //don't do this on bootstrap accordians (service page)
      if (
            $(e.currentTarget).data('parent') !== "#allServsPanels" &&
            !$(e.currentTarget).hasClass('discardHistory') &&
            ($(e.currentTarget).prop('href') && $(e.currentTarget).prop('href').substring(0,4) !== 'tel:')
      ) {

          if (typeof $(e.currentTarget).attr('href') !== 'undefined') {
              $(e.currentTarget).attr('href', stateModel.buildStateHashUrl($(e.currentTarget).attr('href')));
          }
      }
    });


    $('body').on('click', '.analytics', function (e) {
        console.log('analytics click');
        var data = $(e.currentTarget).data();
        analytics.trackGAEvent(false,data.gaCategory, data.gaLabel, data.gaValue);
        analytics.trackIntercomEvent(data.gaCategory + "-" + data.gaLabel);
    });


    if (window !== window.top) {
        analytics.trackGAEvent(false, 'Warning', 'Site viewed in an iframe', window.location.href);
    }

    attachFastClick.attach(document.body);
    iosFix();

    Backbone.history.start();
});


