"use strict";

/* global require, window, module */
var $ = require('jquery');

module.exports = (function () {

    var DELAY = 200; // ms
    var SPINNER_LOCATOR = '#content .spinner'; // application boot spinner, look for it before we decide whether to show the in-app loading overlay

    // track promises, don't remove the overlay until the counter hits zero
    var counter = 0;

    function showLoadingScreen() {
        var $body = $('body');
        // show loading screen
        $body.addClass('loading');
    }

    return {
        showLoadingScreen: function() {
            var $body = $('body');
            // show loading screen
            $body.addClass('loading');
        },
        hideLoadingScreen: function(){
            var $body = $('body');
            // hide loading screen
            $body.removeClass('loading');
        },
        until: function () {
            var $body = $('body');
            var promise = $.when.apply($, arguments);

            // hack: don't do anything if it looks like the application start-up loading content is showing
            // unless we extend the backbone router, looks like it is straight from route -> render. doesn't seem to be a general "finished"/post-routing place to hook into
            if ($body.find(SPINNER_LOCATOR).size() > 0) {
                return promise;
            }

            // delay the display of the loading screen so that instantly resolved promises won't trigger a flash of 'loading'
            var timeoutId = window.setTimeout(showLoadingScreen, DELAY);

            counter += 1;

            promise.always(function () {
                counter = Math.max(0, counter - 1);

                // cancel timeout, in case we have resolved fast enough not to bother
                window.clearTimeout(timeoutId);

                if (counter < 1) {
                    // hide loading screen
                    $body.removeClass('loading');
                }
            });

            return promise;
        },

        counter: counter
    };

})();