/**
 * holds details about the current business.
 *
 * @module
 */
'use strict';

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    config = require('./../../js/models/configModel'),
    moment = require('moment');

Backbone.$ = $;

var BusinessModel = Backbone.Model.extend({
    defaults: {
        address: '',
        paymentRequired: false,
        details_line: null,
        paypal: {
            environment: 'sandbox'
        },
        currency_symbol: '$',
        shouldDisplayPaymentReceiptEmailNotification: false
    },

    url: function () {
        return config.getAPIUrl() + 'business/' + this.get('id');
    },
    // init of the model
    initialize: function (model) {

    },

    preload: function (data) {
        if (_.isObject(data)) {
            // preload data is available, does it match the business we are requesting ?
            var match = data.id === this.get('id') || data.online_booking_address === this.get('id');

            if (match) {
                this.set(data);
                this.set('preload', true);
                this.set('voucher_expiry_date', this.getVoucherExpiryDate())
            }
        }
    },

    fetch: function (options) {
        // kinda hacky
        if (this.get('preload')) {
            this.set('preload', false);
            // already loaded this business
            var promise = $.Deferred().resolve(this.parse(this.attributes), "success", options);

            return promise;
        }

        return Backbone.Model.prototype.fetch.apply(this, arguments);
    },

    parse: function(response) {
        response.stepSize = response['step_size'];//jshint ignore:line
        return response;
    },
    //probably should be in the template?
    getDisplayAddress: function () {
        return this.get('address');
    },

    /**
     *
     * @returns {boolean}
     */
    is_chain: function() {
        return this.get('type') === 'chain';
    },

    /**
     *
     * @returns {boolean}
     */
    is_branch: function() {
        return this.get('type') === 'branch';
    },

    /**
     *
     * @returns {boolean}
     */
    is_boutique: function() {
        return this.get('type') === 'boutique';
    },

    getVoucherExpiryDate: function (){
        return moment().add(this.get('voucher_expiry'), 'months').endOf("day");
    },

    getDefaultStaffPerson: function () {
        var defaultToAnyone = this.get('default_to_anyone') === "1";
        return defaultToAnyone ? BusinessModel.ANY_STAFF_PERSON : BusinessModel.NO_STAFF_SELECTED;
    }

});

BusinessModel.NO_STAFF_SELECTED = "-1";
BusinessModel.ANY_STAFF_PERSON = "0";

module.exports = BusinessModel;


