/**
 * Holds details about a package
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Service = require('./serviceModel'),
    Backbone = require('backbone');
var config = require('./../../js/models/configModel');

Backbone.$ = $;

//holder for services/packages passed from backend
module.exports = Backbone.Model.extend({
    /**
     attributes: Object
     duration: 45
     id: "100000000001207944"
     name: "all"
     popular: false
     price: "$179.00"
     products: Array[1]
     0: Objectid: "100000000001207947"
     itemId: "100000000001207946"
     name: "aaaaaaaaaa"
     position: "1"
     price: "$99.00"
     services: Array[1]
     0: Object
     duration: 45
     id: "100000000001207945"
     itemId: "100000000001108463"
     name: "Cut & Blow Dry Womens"
     position: "0"
     price: "$80.00"
     status: "active"
     type:"package"
     visible: "1"
     */
    defaults: {
        type: "package"
    },
    // init of the model
    initialize: function (model) {

    },

    isAPackage: function() {
        return this.get('type') === Service.PACKAGE;
    },

    /**
     *
     * @param serviceId
     * @returns {string}
     */
    getNameFromPackageItem: function(serviceId) {
        var name = "";
        var target = _.find(this.get('services'), function(serviceModel){
            return serviceModel.itemId === serviceId;
        });

        if(target) {
            name = target.name;
        }
        return name;
    }
});

