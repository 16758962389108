
var $ = require('jquery');
var mailCheck = require('mailcheck');

//ideally these should be in a config file somewhere
mailCheck.defaultDomains.push('gmail.com', 'googlemail.com', 'outlook.com', 'optusnet.com.au', 'icloud.com', 'yahoo.com', 'yahoo.com.au', 'yahoo.co.nz', 'hotmail.com', 'hotmail.co.nz', 'hotmail.co.uk', 'xtra.co.nz', 'windowslive.com', 'live.com.au', 'live.com', 'bigpond.com');
mailCheck.defaultSecondLevelDomains.push('gmail', 'googlemail', 'yahoo', 'outlook', 'optusnet', 'hotmail', 'icloud', 'xtra', 'windowslive', 'live', 'bigpond', 'clear', 'vodafone');
mailCheck.defaultTopLevelDomains.push('com', 'com.au', 'co.nz', 'co.uk', 'net', 'govt.nz', 'gov.au', 'gov.uk', 'gov', 'org', 'edu', 'uk', 'au', 'nz', 'net.nz');

var fullThreshold = 3;
mailCheck.domainThreshold = 3;
mailCheck.secondLevelThreshold = 3;
mailCheck.topLevelThreshold = 3;

//don't blame me for this function, I just moved it
var stringDistance = function (s, t) {
    // Determine the Damerau-Levenshtein distance between s and t
    if (!s || !t) {
        return 99;
    }
    var i, j;
    var m = s.length;
    var n = t.length;
    var charDictionary = {};

    /* For all i and j, d[i][j] holds the Damerau-Levenshtein distance
     * between the first i characters of s and the first j characters of t.
     * Note that the array has (m+1)x(n+1) values.
     */
    var d = [];
    for (i = 0; i <= m; i++) {
        d[i] = [];
        d[i][0] = i;
    }
    for (j = 0; j <= n; j++) {
        d[0][j] = j;
    }

    // Populate a dictionary with the alphabet of the two strings
    for (i = 0; i < m; i++) {
        charDictionary[s.charAt(i)] = 0;
    }
    for (j = 0; j < n; j++) {
        charDictionary[t.charAt(j)] = 0;
    }

    // Determine substring distances
    for (i = 1; i <= m; i++) {
        var db = 0;
        for (j = 1; j <= n; j++) {
            var i1 = charDictionary[t.charAt(j - 1)];
            var j1 = db;
            var cost = 0;

            // Subtract one to start at strings' index zero instead of index one
            if (s.charAt(i - 1) == t.charAt(j - 1)) {  //jshint ignore:line
                db = j;
            } else {
                cost = 1;
            }
            d[i][j] = Math.min(d[i][j - 1] + 1,                 // insertion
                Math.min(d[i - 1][j] + 1,        // deletion
                    d[i - 1][j - 1] + cost)); // substitution
            if (i1 > 0 && j1 > 0) {
                d[i][j] = Math.min(d[i][j], d[i1 - 1][j1 - 1] + (i - i1 - 1) + (j - j1 - 1) + 1); //transposition
            }
        }
        charDictionary[s.charAt(i - 1)] = i;
    }

    // Return the strings' distance
    return d[m][n];
};

var mailCheckRunOriginal = mailCheck.run;
var mailCheckSuggestOriginal = mailCheck.suggest;

//this is a hack workaround to change the distance function without having to pass it in every time you run the check
mailCheck.run = function(opts){
    opts.distanceFunction = stringDistance;
   // _.extend(opts, mailCheck.getDefaultOptions());
    return mailCheckRunOriginal(opts);
};

//the original function does a distance check on each part of the email individually
//but we only want to create a suggestion of the entire email is within a difference of 3
mailCheck.suggest = function(email, domains, secondLevelDomains, topLevelDomains, distanceFunction){

    var emailParts = mailCheckSuggestOriginal.call(mailCheck, email, domains, secondLevelDomains, topLevelDomains, distanceFunction);
    if(emailParts !== false){
        //we found a difference now we want to check if the entire email is within the threshold
        if(distanceFunction(email, emailParts.full) <= fullThreshold){
            return emailParts;
        }
    }

    return false
};

mailCheck.getHintContainer = function(element){
    return $("#hint_" + $(element).attr('id'));
};

mailCheck.getDefaultOptions = function(){
    return {
        suggested: function (element, suggestion) {
            var $hintContainer = mailCheck.getHintContainer(element);

            $hintContainer.find('.address').text(suggestion.address);
            $hintContainer.find('.domain').text(suggestion.domain);
            if (!$hintContainer.is(':visible')) {
                // First error - animate entire hint element
                $hintContainer.fadeIn(150);
            }
        },
        empty: function (element) {
            var $hintContainer = mailCheck.getHintContainer(element);
            $hintContainer.fadeOut(150);
        }
    }
};

mailCheck.updateInputWithHint = function($hint){
    var id = $hint.attr('id');
    var $input = $('#' + id.substring(id.indexOf('_') + 1));


    $input.val($hint.find('.email-suggestion').text());

    mailCheck.hideHint($hint);

};

mailCheck.hideHint = function($hint){
    $hint.fadeOut(150, function () {
        //bit of a hack. can't get the form to re-validate after choosing the suggestion,
        // so blurring, then re-focussing. this also fixes an issue with tabbing between fields after clicking the suggestion :)
        // this.$el.find('#voucherEmailTo').blur();
    });
};

module.exports = mailCheck;