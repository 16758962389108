"use strict";

function pad(n) {
    return (n < 10 ? '0' : '') + n;
}

module.exports = {
    addMinutes: function (time, minsToAdd) {
        var bits = [];
        //handle midnight, single digit times and double digit times 8am, 10pm etc
        bits[0] = time.toString().substr(0, time.toString().length - 2);
        bits[1] = time.toString().substr(-2);
        var mins = bits[0] * 60 + parseInt(bits[1]) + parseInt(minsToAdd);
        return (mins % (24 * 60) / 60 | 0) + pad(mins % 60);//jshint ignore:line
    },
    convertToStandardTime: function (time) {
        var returnTime = time;
        if (time >= 1300) {
            returnTime = time - 1200;
        }
        return ("0000" + returnTime).slice(-4).toString();
    },
    convertToMilitary: function (time) {
        var additionalTime = 0;
        if (time.indexOf('pm') !== -1 && time.indexOf('12') !== 0) {
            additionalTime = 1200;
        }
        var returnTime = parseInt(time.replace(/[^\d+]/g, ''), 10) + additionalTime;

        //handle midnight
        if (time.indexOf('am') !== -1 && returnTime >= 1200) {
            returnTime = returnTime - 1200;
        }
        return returnTime;
    }
};

