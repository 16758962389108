/**
 * Collection of availabiltiy by month/date/time
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    moment = require('moment'),
    AvailabilityModel = require('./../models/availabilityModel'),
    BaseServiceCollection = require('./baseServiceCollection'),
    Backbone = require('backbone');

Backbone.$ = $;

/**
 * stores all available packages from the server.
 * Everything else holds a pointer to these models
 */
module.exports = BaseServiceCollection.extend({
    model: AvailabilityModel,
    timeslotsCollection: {},
    startDate: false,
    endDate: false,
    request: {},
    services: [],
    url: function () {
        var getString = 'startDate=' + this.request.startDate + '&endDate=' + this.request.endDate;
        getString = getString + '&services=' + this.getServices();
        if(this.stateModel.get('reschedule_flag')) {
            getString = getString + '&rescheduledBookingId=' + this.stateModel.get('reschedule_flag');
        }
        return this.baseUrl + '/availability/summary?' + getString;
    },

    getServices: function () {
        return this.stateModel.getServiceIdsForAPI(this.packagesCollection);
    },
    resetDates:function() {
        // start from yesterday since 'today' could be different between timezones
        this.startDate = moment().add(-1, 'day').format('YYYY-MM-DD');
        this.endDate = moment().endOf('month').format('YYYY-MM-DD');

        /**
         * if halfway through the month then prefetch next month
         */
        if (moment().format('DD') > 14) {
            this.endDate = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD');
        }

        this.request.startDate = this.startDate;
        this.request.endDate = this.endDate;
    },
    initialize: function (models, options) {
        BaseServiceCollection.prototype.initialize(models, options);
        _.bindAll(this, 'url','getServices');

      this.resetDates();
    },
    parse: function (response) {
        var that = this;
        return _.map(response.days, function (value, key) {
            return {
                id: key,
                date: key,
                availability: value.availability,
                services: that.services,
                serviceIdString: that.getServices()
            };
        });
    },
    /**
     * set start/end dates for a request
     *
     * @param month YYYY-MM-DD
     */
    setMonth:function(month) {
        var now = moment();
        var monthMoment = moment(month, 'YYYY-MM-DD');
        var startMoment = moment(this.startDate);
        var endMoment = moment(this.endDate);
        var shouldFetch = true;

        //limit to 7 months of data
        var startToEndRange = Math.abs(endMoment.diff(startMoment, 'months'));
        var startToArgumentRange = Math.abs(monthMoment.diff(startMoment, 'months'));

        if(startToEndRange > 12 || startToArgumentRange > 12) {
            return false;
        }

        if(!this.monthLoaded(monthMoment.get('month'))) {
            if(monthMoment.get('month') === now.get('month') && monthMoment.get('year') === now.get('year')) {
                // start looking from yesterday since "today" could mean something different in the local timezone
                this.request.startDate = now.add(-1, 'day').format('YYYY-MM-DD');
            } else {
                this.request.startDate = monthMoment.startOf('month').format('YYYY-MM-DD');
            }
            this.request.endDate = monthMoment.endOf('month').format('YYYY-MM-DD');
        } else {
            this.request.startDate = monthMoment.startOf('month').format('YYYY-MM-DD');
            this.request.endDate = monthMoment.endOf('month').format('YYYY-MM-DD');
            shouldFetch = false;
        }

        this.endDate = this.request.endDate;
        return shouldFetch;
    },
    /**
     * as users navigate through calendar future dates need to be fetched.
     *
     * @param month
     * @param callback
     */
    getNextFutureMonth: function (month, callback) {
        if (this.setMonth(month)) {
            this.fetch({remove: false}).done(callback);
        }
    },
    /**
     * month should be zero-based.
     *
     * @param month
     * @returns {*}
     */
    monthLoaded: function (month) {
        var monthLoaded = this.find(function (item) {
            //momentjs months are zero indexed
            return moment(item.get('date'), 'YYYY-MM-DD').get('month') === month;
        });

        return monthLoaded;
    },
    resetDays: function () {
        this.reset();
        this.resetDates();
    }
});

