/**
 * Change password modal
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    base64 = require('./../helpers/base64'),
    config = require('./../models/configModel');

Backbone.$ = $;
window.jQuery = $;

Handlebars.registerPartial('partialFooter', Handlebars.compile(require('./../../templates/partialFooter.hbs').default));

require('jquery-validation');

module.exports = Backbone.View.extend({
    el: '#changePwModal',
    events: {
        'click #savePassBtn':   'savePass',
        'click #closeX':        'closeModal',
        'click #cancelModal':   'closeModal'
    },
    initialize: function () {
       this.initValidator();
    },

    render: function(){
      this.$el.modal('toggle');
    },

    savePass: function () {
        var that = this;

        var old_password = $('input[name=pw_old]').val(),
            password = $('input[name=pw_new]').val(),
            passconf = $('input[name=pw_new_confirm]').val();

        if (!$('#passwordForm').valid()) {
            return;
        }

        $('#savePassBtn')
            .addClass('disabled btn-default')
            .removeClass('btn-success')
            .html('Save password <i class="fa fa-circle-o-notch fa-spin"></i>');

        analytics.trackGAEvent(false, 'Non-booking behaviour', 'User changed password');

        var email = this.model.get('clean_email'),
            customerData = {
                old_password: old_password,
                password: password,
                passconf: passconf,
                first_name: this.model.get('firstName'),
                last_name: this.model.get('lastName'),
                customer_kid: this.model.get('id'),
                email: this.model.get('email'),
                phone: this.model.get('phone'),
                street: this.model.get('street'),
                suburb: this.model.get('suburb'),
                city: this.model.get('city'),
                postcode: this.model.get('postalCode'),
                confMethod: this.model.get('confMethod'),
                gender: this.model.get('gender')
            };

        config.fetchFromDom();

        $.ajax({
            type: 'PUT',
            url: config.getAPIUrl() + "customer/" + this.model.get('business_id') + "/" + this.model.get('id'),
            data: customerData,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.btoa(this.model.get('clean_email') + ":" + this.model.get('api_token'))
            },
            success: function (data) {
                that.model.setLoginCookies(email, data.api_token, data.customer);

                that.closeModal();
            },
            error: function (xhr) {
                if(xhr.responseJSON.error === "The email address or password you entered is incorrect."){
                    var errors = {pw_old: "Incorrect current password"};
                    $('#passwordForm').validate().showErrors(errors);
                    $('#savePassBtn')
                        .removeClass('disabled btn-default')
                        .addClass('btn-success')
                        .html('Save password');
                }
            }
        });

    },
    initValidator: function () {
        $.validator.addMethod( 'passwordMatch', function(value, element) {
            // The two password inputs
            var password = $("#pw_new").val();
            var confirmPassword = $("#pw_new_confirm").val();

            // Check for equality with the password inputs
            if (password !== confirmPassword ) {
                return false;
            } else {
                return true;
            }
        }, "Your new passwords don't match");

        $('#passwordForm').validate({
            onfocusout: false,
            onkeyup: false,
            onclick: false,
            rules: {
                pw_old: {
                    required: true
                },
                pw_new: {
                    required: true,
                    minlength: 8
                 //   equalTo: "#pw_new_confirm"
                },
                pw_new_confirm: {
                    required: true,
                 //   equalTo: "#pw_new"
                    passwordMatch: true
                }
            },
            messages: {
                pw_old:{
                    'required': "Please enter your current password."
                },
                pw_new:{
                    'required': "Please enter your new password.",
                    'minlength': "Your new password must be at least 8 characters long."
                  //  'equalTo': 'New passwords do not match.'
                },
                pw_new_confirm:{
                    'required': "Please re-type your new password.",
                    'minlength': "Your new password must be at least 8 characters long.",
                    //'equalTo': 'New passwords do not match.',
                    passwordMatch: "Your new passwords don't match."
                }
            },
            errorLabelContainer: "#passChangeValidationAnchor",
            wrapper: 'li',
            errorPlacement: function (error, element) {
                $('#passChangeValidationAnchor').append(error);
            }
        });
    },
    closeModal: function(){
        $('#passwordForm').validate().resetForm();
        this.$el.modal('toggle');
        this.undelegateEvents();

    }


});
