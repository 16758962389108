"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var BaseView = require('./baseView');
var template = Handlebars.compile(require('../../templates/pageConfirmPause.hbs').default);

module.exports = BaseView.extend({

    pageTitle: 'Awaiting confirmation',
    template: template,
    options: null,

    initialize: function (options) {
        this.options = options;
    },

    getViewData: function () {
        return {
            activityName: this.options.activityName,
            confirmUrl: this.options.confirmUrl,
        };
    },

    render: function () {
        this.setPageTitle(this.pageTitle);
        this.$el.html(this.template(this.getViewData()));
        BaseView.prototype.render(this);
    }
});