"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    PhotoSwipe = require('photoswipe'),
    PhotoSwipeUI_Default  = require('./../../node_modules/photoswipe/dist/photoswipe-ui-default.js'),
    moment = require('moment');

var photoSwipeFunctions = (function () {
    var gallery;
    var initialMetaViewportContent;
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1 || $(figureEl).find('a.stillLoading').length > 0) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        if(e.preventDefault) {
            e.preventDefault();
        } else {
            e.returnValue = false;
        }

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem || $(clickedListItem).find('a.stillLoading').length > 0) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            options,
            items;

        if (pswpElement === undefined) {
            //exit here to avoid falling over later
            return;
        }

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            },

            history: false, //set to true to update URL with each photo... may need this but probably not right now.
            isClickableElement: function(el) {
                return el.tagName === 'A' || el.tagName === 'BUTTON';
            },
            fullscreenEl: false,
            arrowEl: true,
            counterEl: false,
            closeOnScroll: false,
            pinchToClose: false,
            errorMsg: "<div class=\"pswp__error-msg\">This image could not be loaded. <br>The image may have been removed by another user. <br><br>Please refresh the page and try again.</div>"
        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid === index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        if(typeof gallery !== 'undefined' && typeof gallery.close !== 'undefined') {
            gallery.close();
        }
        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);

        updateButtonsForCurrentItem($(items[index].el));

        gallery.init();

        gallery.listen('afterChange', function() {
            updateButtonsForCurrentItem($(this.currItem.el));
        });
    };

    var initPhotoSwipeFromDOM  = function($galleryEle) {
        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)

        // loop through all gallery elements and bind events
        var i = 0;
        _.each($galleryEle, function(ele) {
            $(ele).attr('data-pswp-uid', i++);
            $(ele).on('click', onThumbnailsClick);
        });
    };

    var updateButtonsForCurrentItem = function($item) {
        var $deleteButton = $('.pswp__button--delete'),
            itemDataId = $item.find('a').data('id'),
            canDelete = ($item.find('a').data('uploaded_by') === 'client');


        //catch when an attachment doesn't have critical data and do not show buttons.
        if (itemDataId === "" || itemDataId === undefined || !parseInt(itemDataId)) {
            $deleteButton.hide();
        }
        else {
            if(!canDelete) {
                $deleteButton.addClass('btn-disabled btn-default').removeClass('btn-danger');
            } else {
                $deleteButton.removeClass('btn-disabled btn-default').addClass('btn-danger');
            }
            setButtonDataAttributes($deleteButton, $item, ['bid','id','filename','uploaded_by']);
        }
    };

    var setButtonDataAttributes = function($button, $item, fields) {
        $.each(fields, function(index, value) {
            var fieldName = value;
            $button.data(fieldName, $item.find('a').data(fieldName));
        });
    };

    var getCurrentInstance = function() {
        return gallery;
    };

    return {
        initPhotoSwipeFromDOM: initPhotoSwipeFromDOM,
        getCurrentInstance: getCurrentInstance,
        updatePhotoSwipeButtons: updateButtonsForCurrentItem
    };
}());

module.exports = {
    initPhotoSwipeFromDOM: photoSwipeFunctions.initPhotoSwipeFromDOM,
    getCurrentInstance: photoSwipeFunctions.getCurrentInstance,
    updatePhotoSwipeButtons: photoSwipeFunctions.updatePhotoSwipeButtons
};