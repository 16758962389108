"use strict";
var $ = require('jquery');

module.exports = {
    setAllSpacesToSingleSpaceAndTrim : function (value) {
        if (null === value || undefined === value) {
            value = '';
        }
        return $.trim(value.toLowerCase().replace(/\s\s+/g, ' '));
    }
};