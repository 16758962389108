/**
 * Holds details about a service.
 *
 * @module
 */
"use strict";
var Backbone = require('backbone');
Backbone.$ = require('jquery');

var PACKAGE = 'package';
var SERVICE = 'service';

var Service = Backbone.Model.extend({
    /**
     * categoryId: "100000000001108457"
     * duration: 60
     * followOnId: null
     * gapTime: 0
     * id: "100000000001108460"
     * name: "Break 60 mins"
     * popular: trueprice: "$0.00"
     * visible: true
     */
    defaults: {
        type: SERVICE
    },

    isAPackage: function () {
        return this.get('type') === Service.PACKAGE;
    }
});

Service.PACKAGE  = PACKAGE;
Service.SERVICE  = SERVICE;

module.exports = Service;