/**
 * Link the users state to URL
 *
 * @module
 */

"use strict";
/* global FB */
var $ = require('jquery'),
    _ = require('underscore'),
    Qs = require('qs'),
    config = require('./configModel'),
    Service = require('./serviceModel'),
    Backbone = require('backbone'),
    BusinessModel = require('./businessModel');

Backbone.$ = $;
require('jquery.cookie');

/**
 * holds service information in url
 *
 * @type {*}
 */
var StateService = Backbone.Model.extend({
    defaults: {
        id: null,
        type: null,
        staffId: BusinessModel.NO_STAFF_SELECTED
    },

    isAPackage: function () {
        return this.get('type') === Service.PACKAGE;
    }
});

var StateServiceCollection = Backbone.Collection.extend({
    model: StateService
});

module.exports = Backbone.Model.extend({
    defaults: {
        'services': {},//StateServiceCollection
        'packageDetails': {},//StateServiceCollection
        'time': null,
        'reschedule_flag': undefined,
        'unavailable': [],
        'facebookActive': false,
        'booking_photos': [],
        'paypal_button_exists': false
    },

    // init of the model
    initialize: function (model) {
        var that = this;

        var initFacebook = function(){

            FB.getLoginStatus(function (response) { //this should be instant due to the status:true in FB.init()
                //listen to the change on this field to know when the facebook SDK has loaded
                that.set('facebookActive', true);
                //add dom element to help with selenium testing
                $('body').append('<div id="facebook-sdk-loaded">');
            });
        };

        //set up the call back that get triggered when the facebook js api gets loaded
        //because we don't know if facebook is going to be loaded before or after the bundle we check
        //to see if out flag has been set to say that facebook has done its thing
        if(window.ObFbInit){
            initFacebook();
        } else {
            //we wrap up the inital async because its got code we want to run
            var wrapped = window.fbAsyncInit;
            window.fbAsyncInit = function() { 
                wrapped.apply(this, arguments); 
                initFacebook();
            };
        }


        this.attributes.services = new StateServiceCollection();
        this.attributes.packageDetails = new StateServiceCollection();
    },

    parseQueryString: function (queryString) {
        //console.log("parse query string ", queryString);
        if (queryString == 'undefined' || !queryString) { //jshint ignore:line
            return false;
        } else {
            var finalObject = Qs.parse(queryString);

            return finalObject;
        }
    },

    fetchFromURLHash: function (hash) {
        if (typeof hash === 'undefined') {
            hash = window.location.hash;
        }

        //if the hash includes backbone url fragment then remove it
        if (hash.indexOf('?') !== -1) {
            hash = hash.substr(hash.indexOf('?') + 1);
            return this.parseQueryString(hash);
        } else {
            return {};
        }
    },

    resetData: function() {
        this.get('services').reset();
        this.get('packageDetails').reset();
        this.unset('time');
        this.unset('reschedule_flag');
        this.unset('unavailable');
        this.set('booking_photos',[]);
    },

    /**
     * get the current state from the URL and set it on this model
     *
     * @param hash
     * @returns {exports}
     */
    fetch: function (hash) {
        //console.log('before:', this.attributes);
        var urlData = this.fetchFromURLHash(hash),
            processedData = {};

        this.resetData();

        _.each(urlData.services, function (value, key) {
            this.get('services').add({id: value[0], type: value[1], staffId: value[2]},{merge: true});
        }, this);

        _.each(urlData.packageDetails, function (value, key) {
            this.get('packageDetails').add({id: value[0], type: value[1], staffId: value[2]},{merge: true});
        }, this);

        this.set('time', urlData.time);

        if(urlData.reschedule_flag !== undefined) {
            this.set('reschedule_flag', urlData.reschedule_flag);
        }

        if(urlData.unavailable !== undefined) {
            this.set('unavailable', urlData.unavailable);
        }

        if (urlData.booking_photos !== undefined) {
            this.set('booking_photos', urlData.booking_photos);
        }

        this.attributes = _.extend({}, this.defaults, this.attributes);
        //console.log('after:', this.attributes);
        return this;
    },

    /**
     * given a url hash, append state and return string
     *
     * @param {string} hash
     * @returns {string}
     */
    buildStateHashUrl: function (hash) {
        var urlString,
            returnString = "",
            stringMe = {},
            packageDetails,
            services,
            time,
            reschedule_flag,
            unavailable,
            booking_photos;

        services = _.map(this.get('services').models, function (item) {
            return [item.get('id'), item.get('type'), item.get('staffId')];
        });
        if (services) {
            stringMe.services = services;
        }
        packageDetails = _.map(this.get('packageDetails').models, function (item) {
            return [item.get('id'), item.get('type'), item.get('staffId')];
        });
        if (packageDetails) {
            stringMe.packageDetails = packageDetails;
        }

        time = this.get('time');
        if (time) {
            stringMe.time = time;
        }

        reschedule_flag = this.get('reschedule_flag');
        if(reschedule_flag) {
            stringMe.reschedule_flag = reschedule_flag;
        }

        unavailable = _.map(this.get('unavailable'), function(service) {
            return service.name;
        });
        if(unavailable) {
            stringMe.unavailable = unavailable;
        }

        booking_photos = this.get('booking_photos');
        if (booking_photos) {
            stringMe.booking_photos = booking_photos;
        }

        urlString = Qs.stringify(stringMe);

        //if the hash includes get vars then remove them
        if (hash.indexOf('?') !== -1) {
            hash = hash.substr(0, hash.indexOf('?'));
        }

        if (urlString) {
            returnString = hash + "?" + urlString;
        } else {
            returnString = hash;
        }

        return returnString;
    },

    updateURLHash: function () {
        var hash = window.location.hash;

        hash = this.buildStateHashUrl(hash);

        //TODO, nuke this rubbish code.
        if(hash === window.location.hash) {
            Backbone.history.loadUrl(hash); //Force it to refresh...
        } else {
            window.location.hash = hash;
        }

        return hash;
    },

    getURLHash: function(baseUrl, services, packages) {
        return this.buildStateHashUrl(baseUrl, services, packages);
    },

    /**
     * "add" or "remove" a service or package
     *
     * @param {'add'|'remove'} method
     * @param {string |Service| {id: string, type: string, staffId: string}} targetService - A partial service object, or id string
     * @param {'service'|'package'} [serviceType] - service type string if an id string is used for targetService
     * @param {boolean} [_noUpdateURL=false] - don't update the url hash
     */
    changeServices: function (method, targetService, serviceType, _noUpdateURL) {
        if (typeof targetService !== 'object') {
            targetService = {
                id: targetService.toString(),
                type: serviceType,
                staffId: undefined
            };
        }

        if (method === 'remove' && this.get('services').get(targetService.id)) {
            this.get('services').remove(targetService.id);

            var packageCount = this.get('services').filter(function (i) {
                return i.get('type') === 'package';
            }).length;

            if (this.get('services').length === 0 || packageCount === 0) {
                this.get('packageDetails').reset();
                this.unset('time');
            }

        } else if (method === 'add') {
            this.get('services').add(targetService);
        }

        this.flipAnyoneAndNoStaff();

        if (!_noUpdateURL) {
            this.updateURLHash();
        }
    },

    flipAnyoneAndNoStaff: function() {
        var that = this;
        //If we are default to anyone and single staff person - change staff on services:
        if(this.get('businessModel').get('default_to_anyone') === "1"
            && this.get('businessModel').get('one_staff_only') === "1") {
            var serviceCount = _.reduce(that.get('services').models, function (memo, item) {
                return memo + item.get('serviceCount');
            }, 0);
            var staffId = BusinessModel.ANY_STAFF_PERSON;

            this.get('services').forEach(function(service) {
                if(service.get("staffId") === BusinessModel.ANY_STAFF_PERSON
                    || service.get("staffId") === BusinessModel.NO_STAFF_SELECTED) {
                    service.set('staffId', staffId);
                }
            });
        }
    },

    /**
     * change staff memeber for a service/packageItem
     *
     *  if _updateURL is undefined in arguments, then updateURL. Only
     *  functions that do not want to update the url will pass in an
     *  argument for _noUpdateURL which can be any Boolean value.
     *
     * @param serviceId
     * @param staffId
     * @param serviceType
     * @param {boolean} _noUpdateURL (optional) default: true
     */
    changeStaff: function (serviceId, staffId, serviceType, _noUpdateURL) {
        if (serviceType === 'packageItem') {
            var packageItem = this.get('packageDetails').get(serviceId);
            if (!packageItem) {
                this.get('packageDetails').add([
                    {
                        id: serviceId,
                        type: serviceType,
                        staffId: staffId
                    }
                ]);
            } else {
                packageItem.set('staffId', staffId);
            }
        } else {
            this.get('services').get(serviceId).set('staffId', staffId);
        }

        if (!_noUpdateURL) {
            this.updateURLHash();
        }
    },

    changeTime: function (time) {
        this.set('time', time);
        //dont update the url till they click continue
    },

    wtfServiceIds: function(allServicesCollection) {
        var servicesIds = [];

        _.map(this.get('services').models, function (base_item) {
            var allServices = allServicesCollection;
            if (base_item.get('type') === 'package') {
                return allServices.get(base_item.get('id'))
                    .get('services')
                    .map(function (item) {
                        servicesIds.push({
                            service_id: item.itemId,
                            packageItemId: item.id,
                            packageId: base_item.get('id')
                        });
                    });
            } else {
                servicesIds.push({
                    service_id: base_item.get('id'),
                    packageItemId: false,
                    packageId: false
                });
            }
        }, this);

        return servicesIds;
    },

    /**
     * get list of service ids (include service ids from package ids), used to get capability
     */
    getServiceIds: function (allServicesCollection) {
        var serviceIds;

        serviceIds = _.map(this.get('services').models, function (item) {
            var allServices = allServicesCollection;
            if (item.get('type') === 'package') {
                return allServices.get(item.get('id'))
                    .get('services')
                    .map(function (item) {
                        return item.itemId;
                    });
            } else {
                return item.get('id');
            }
        }, this);
        //packages are nested arrays, so flatten them
        return _.flatten(serviceIds);
    },

    getFirstServiceStaffKid: function () {
        var firstService = this.get('services').first();
        return firstService.get('staffId');
    },

    getFirstServiceStaffName: function (staffCollection) {
        return this._getFirstServiceSelectedStaff(staffCollection).get('name');
    },

    _getFirstServiceSelectedStaff: function (staffCollection) {
        var selectedStaffKid = this.getFirstServiceStaffKid();
        return staffCollection.findByKid(selectedStaffKid)
    },


    reorderServices: function (newOrder) {
        var newOrderedPackageDetails = [];
        console.log("newOrder",newOrder);
        var newOrderedModels = _.map(newOrder, function (arr) {
            if(arr[1] === 'package') {
                newOrderedPackageDetails.push.apply(newOrderedPackageDetails,arr[2]);
                return {id: arr[0], type: arr[1], staffId: 0};
            } else {
                return {id: arr[0], type: arr[1], staffId: arr[2]};
            }
        });

        newOrderedPackageDetails = _.map(newOrderedPackageDetails, function(arr){
            return {id: arr[0], type: arr[1], staffId: arr[2]};
        });

        console.log('newOrderedModels', newOrderedModels);
        console.log('newOrderedPackageDetails', newOrderedPackageDetails);
        this.get('services').reset(_.filter(newOrderedModels));
        this.get('packageDetails').reset(newOrderedPackageDetails);
        this.updateURLHash();
    },

    /**
     * used for templates
     *
     * @param {StateServiceCollection} allServices
     * @param {StaffCollection} staffCollection
     * @returns {*}
     */
    getServiceDetails: function (allServices, staffCollection) {
        var serviceDetails;

        serviceDetails = _.map(this.get('services').models, function (item) {
            var id = item.get('id');
            var staffMember = staffCollection.get(item.get('staffId').toString());
            var staffName = "Please select",
                staffId = "-1";
            if (staffMember) {
                staffId = item.get('staffId').toString();
                staffName = staffMember.get('formattedName');
                if (!staffName) {
                    staffName = staffMember.get('firstName') + " " + staffMember.get('lastName');
                }
            }

            var service = allServices.get(id);
            var returnData = {};
            if (service) {
                returnData = {
                    serviceId: id,
                    serviceName: service.get('name'),
                    serviceType: service.get('type'),
                    staffName: staffName,
                    staffId: staffId
                };
            }

            if (item.get('type') === 'package') {
                var packageDetails = allServices.get(id),
                    templateData = [];

                _.each(packageDetails.get('products'), function (item) {
                    /**
                     * {
                        id: "100000000001207947"
                        itemId: "100000000001207946"
                        name: "aaaaaaaaaa"
                        position: "1"
                        price: "99.00"
                        priceFormatted: "$99"
                     */
                    item.type = 'product';
                    templateData.push(item);

                });
                _.each(packageDetails.get('services'), function (serviceInPackage) {
                    serviceInPackage.type = 'service';

                    //ensure the staff id is grabbed of the state model services as the default,
                    //this is important for pre-selected staff people (defaulting to anyone as per settings,
                    // or re-booking a past package appointment).
                    serviceInPackage.staffId = item.get('staffId');

                    var packageMeta = this.get('packageDetails').get(serviceInPackage.itemId);
                    if (packageMeta) {
                        serviceInPackage.staffId = packageMeta.get('staffId');
                    }


                    var staffMember = staffCollection.get(serviceInPackage.staffId.toString());
                    serviceInPackage.staffName = staffMember.get('formattedName');

                    if (!serviceInPackage.staffName) {
                        serviceInPackage.staffName = staffMember.get('firstName') + ' ' + staffMember.get('lastName');
                    }


                    templateData.push(serviceInPackage);
                }, this);
                returnData.packageDetails = _.sortBy(templateData, 'position');
            }
            return returnData;
        }, this);

        var filteredServiceDetails = _.filter(serviceDetails, function(service) {
            return !$.isEmptyObject(service);
        });

        return filteredServiceDetails;
    },

    /**
     * api requires service/package ids in a specific format
     *
     * list of
     * service
     * (service_id, staffId|0, 0)
     * package
     * (itemId, staffId|0,serviceId)
     *
     * @param {packageCollection} packagesCollection
     * @returns {String}
     */
    getServiceIdsForAPI: function (packagesCollection) {
        var response = '';

        this.get('services').each(function (item, key) {
            var staffId = 0;
            var packageCollection = packagesCollection;

            if (item.get('staffId')) {
                staffId = item.get('staffId');
            }

            if (key > 0) {
                response += ',';
            }
            if (item.get('type') === 'service') {
                response += '(' + item.get('id') + ',' + staffId + ',0)';
            } else if (item.get('type') === 'package') {
                var packageServices = packageCollection.get(item.get('id')).get('services');

                _.each(packageServices, function (packageService, key) {
                    if (key > 0) {
                        response += ',';
                    }

                    var packageMeta = this.get('packageDetails').get(packageService.itemId);
                    var staffId = 0;
                    if (packageMeta) {
                        staffId = packageMeta.get('staffId');
                    }

                    response += '(' + packageService.itemId + ',' + staffId + ',' + packageService.id + ')';

                }, this);

            } else {
                console.log('unsupported type: ' + item.get('type'));
            }
        }, this);
        return response;
    },

    setRequestedBookingSlot:function(cookieData) {
        $.cookie('kitomba.onlinebookings2.requestedBookingSlot', JSON.stringify(cookieData));
    },

    getRequestedBookingSlot:function() {
        var cookieData = $.cookie('kitomba.onlinebookings2.requestedBookingSlot');
        if(cookieData) {
            return JSON.parse(cookieData);
        } else {
            return false;
        }
    },

    storeRequestedServices:function() {
        $.cookie('kitomba.onlinebookings2.requestedBooking', JSON.stringify(this.get('services').models));
    },

    loadRequestedServices: function() {
        var cookieData = $.cookie('kitomba.onlinebookings2.requestedBooking');
        if(cookieData) {
            this.set('services', new StateServiceCollection(JSON.parse(cookieData)));
        }
    },

    isPaymentRefused: function() {
        return !!this.paymentRefused;
    },

    setPaymentRefused: function(value) {
        this.paymentRefused = value;
    },

    removeRequestedBookingSlot:function() {
        $.removeCookie('kitomba.onlinebookings2.requestedBookingSlot');
    },

    /**
     *
     * @param attachment_id
     */
    addBookingPhotoIdToUrl: function(attachment_id) {
        var booking_photos = this.get('booking_photos');
        if (booking_photos === undefined) {
            booking_photos = [];
        }
        booking_photos.push(attachment_id);
        this.set('booking_photos', booking_photos);
        $('body').trigger('route:replace_only', 'confirm');
    },

    /**
     *
     * @param attachment_id
     */
    removeBookingPhotoIdFromUrl: function(attachment_id) {
        var booking_photos = _.filter(this.get('booking_photos'), function(id) {
            if (parseInt(id) !== parseInt(attachment_id)) {
                return id;
            }
        });
        this.set('booking_photos', booking_photos);
        $('body').trigger('route:replace_only', 'confirm');
    },

    /**
     *
     */
    clearData: function() {
        this.resetData();
        this.removeRequestedBookingSlot();
        //$('body').trigger('route:navigate_reload_from_state');
    },

    /**
     *
     * @param pageRedirect
     * @param businessAddress
     * @param {Service[]} availableServices
     * @param unavailableServices
     * @param reschedule_flag
     * @returns {string}
     */
    prepareMyBookingsRedirect: function(pageRedirect, businessAddress, availableServices, unavailableServices, reschedule_flag) {
        var that = this, hash, url;

        config.fetchFromDom();

        this.get('services').reset();
        this.get('packageDetails').reset();

        availableServices.forEach(function(service){

            /** @type Service service **/
            that.changeServices("add", service.toJSON(), undefined, true);

            if (service.isAPackage()) {

                service.get('services').forEach(function (packageItem) {
                    that.changeStaff(packageItem.itemId, packageItem.staffId, 'packageItem', true);
                });

            }

        });

        if(reschedule_flag !== undefined) {
            this.set('reschedule_flag', reschedule_flag);
        }

        if (unavailableServices !== undefined) {
            this.set('unavailable', unavailableServices);
        }

        hash = this.getURLHash(pageRedirect);
        url = config.getBaseUrl() + businessAddress + hash;
        return url;
    },

    /**
     * check all services have a deliberate staff choice against them.
     * @returns {boolean}
     */
    hasServicesWithNoStaff: function() {
        return Boolean(this.get('services').findWhere({staffId:'-1', type:'service'}));
    },

    getUrlParameter: function (paramName) {
        var searchString = window.location.hash;
        if (searchString.split("?").length !== 2) {
            return null;
        }
        var params = searchString.split("?")[1].split("&");

        for (var i = 0; i < params.length; i++) {
            var value = params[i].split("=");
            if (value[0] == paramName) {
                return value[1];
            }
        }
        return null;
    },

    buildErrorHashHashUrl: function(parameters) {
        var hash = "error?" + Qs.stringify(parameters);
        window.location.hash = hash;
        return hash;
    }
});




