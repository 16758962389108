"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    UrlHelper = require('./../helpers/urlHelpers'),
    loading = require('./../helpers/loadingScreen'),
    analytics = require('./../helpers/analytics'),
    VoucherSale = require('./voucherSale'),
    Backbone = require('backbone');
var config = require('./../../js/models/configModel');

Backbone.$ = $;

module.exports = function(businessModel){
    return {
        driverName: "Windcave",

        getPaymentOptionIcons: function() {
            return '/bookings/img/windcave-pay-options.png';
        },

        setupVoucherButton: function (voucherPageView, clickHandler, button){
            //no op
        },

        setupDepositButton: function (res, confirmPageView, clickHandler, button){
            this.appointmentDeposit = res;
        },

        /**
         *
         * @param bookingModel
         */
        payDeposit: function (bookingModel) {
            var deposit = this.appointmentDeposit;
            if (bookingModel.hasNotes()) {
                loading.until(bookingModel.saveNotesByAppointmentHash(deposit.apptHash)).done(function () {
                    window.location.replace(deposit.parameters.url);
                });
            } else {
                window.location.replace(deposit.parameters.url);
            }
        },

        /**
         *
         * @param voucherSaleModel
         */
        payVoucher: function(voucherSaleModel) {
            var redirectURL = voucherSaleModel.postParameters['redirectURL'];
            if (redirectURL === null) {
                $("body").trigger("route:navigate_reload_with_error", {
                    title: 'Payment error',
                    message: 'false',
                    subTitle: 'Voucher payment could not be taken at this time',
                    targetPage: ''
                });
            } else {
                window.location.replace(redirectURL);
            }
        },

        createVoucherSale: function(purchaseHash){
            return new VoucherSale({
                purchaseHash: purchaseHash,
                queryData : {result: UrlHelper.getSearchParameter('result')}
            });
        },


        finalise: function (url, method, customer_kid, appt_hash) {

            return $.ajax({
                url: url + "?" + $.param({
                    result: UrlHelper.getSearchParameter("result"),
                    userid: UrlHelper.getSearchParameter("userid")
                }),
                method: method,
                dataType: 'json',
                data: {
                    driver: this.driverName,
                    customer_kid: customer_kid,
                    appt_hash: appt_hash
                }
            });
        }
    }
};
