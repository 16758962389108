"use strict";

var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');
var $ = require("jquery");
var Backbone = require("backbone");
var _ = require('underscore');

var errorModalTemplate = Handlebars.compile(require('../../templates/bookingErrorModal.hbs').default);

Backbone.$ = $;

module.exports = Backbone.View.extend({
    el: '#ModalRoot',

    initialize: function (options) {
        this.title = options.title == 'Unknown Error' ? false : options.title;
        this.message = options.message == 'false' ? false : options.message;
        this.subMessage = options.subMessage == 'false' ? '' : options.subMessage;
        this.errorContactBlurb = options.errorContactBlurb;
        this.phone = false;
        this.email = false;
        if (options.contact === true) {
            this.contact = window.businessErrorContactPhone ? true :
                (window.businessErrorContactEmail ? true : false);
            this.phone = window.businessErrorContactPhone ? window.businessErrorContactPhone : false;
            this.email = window.businessErrorContactEmail ? window.businessErrorContactEmail : false;
        }

        this.subTitle = typeof options.subTitle !== "undefined"
            ? options.subTitle : "";

        _.bindAll(this, 'loadTemplate');
        this.render();

        if (typeof options.closeCallback === "function") {
            this.getThisModal().on("hidden.bs.modal", options.closeCallback);
        }
    },

    getTitleElement: function() {
        return this.$el.find("div.modal-header.modal-header-warning > h4 > b");
    },

    loadTemplate: function() {
        this.$el.html(errorModalTemplate(this));
    },

    isRootVisible: function() {
        return this.$el.length > 0;
    },

    getMessageBoxElement: function() {
        return this.$el.find(".modal-body .errorMsg");
    },

    getContactMessageElement: function() {
        return this.$el.find(".modal-body .errorContactBlurb");
    },

    getThisModal: function() {
        return this.$el.find("#ErrorModal");
    },

    showModal: function() {
        this.getThisModal().modal('show');
    },

    render: function () {
        if (this.isRootVisible()) {
            this.loadTemplate();
            this.showModal();
        } else {
            alert(this.message);
        }
    }
});
