/**
 * Collection of all services and packages
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    BaseServiceCollection = require('./baseServiceCollection'),
    config = require('./../../js/models/configModel');

Backbone.$ = $;

/**
 *
 * @type {*}
 */
module.exports = BaseServiceCollection.extend({
    groupedServices: {},
    /**
     * take two models, and return -1 if the first model should come before the second, 0 if they are of the same rank and 1 if the first model should come after.
     * @param firstItem
     * @param secondItem
     * @returns {number}
     */
    comparator: function(firstItem, secondItem) {
        var returnVar = 0;

        if (firstItem.get('category') === 'Packages' && secondItem.get('category') !== 'Packages') {
            returnVar = -1;
        } else if (firstItem.get('category') !== 'Packages' && secondItem.get('category') === 'Packages') {
            returnVar = 1;
        } else {
            returnVar = firstItem.get('category').toLowerCase().localeCompare(secondItem.get('category').toLowerCase());
            if(returnVar === 0) {
                returnVar = firstItem.get('name').toLowerCase().localeCompare(secondItem.get('name').toLowerCase());
            }
        }

        return returnVar;
    },
    initialize: function(models, options) {
        BaseServiceCollection.prototype.initialize(models, options);
    },
    setState: function() {
        //reset
        this.each(function(value){
            value.set('selected', false);
        });
        this.stateModel.get('services').each(function(service) {
            var service_id = service.get('id');
            var _service = this.get(service_id);
            if (_service) {
                _service.set('selected', true);
            }
        },this);
    },
    process: function() {
        var that = this;

        //caching, assumed to not change after page loads
        if (this.length > 0) {
            //must happen regaurdless of caching
            console.log("debug: process skipped, using cache");
            this.setState();
            return;
        }

        this.servicesCollection.each(function(serviceModel, key) {
            var category = _.find(that.categoryModel.attributes, {id: serviceModel.attributes.categoryId});
            if (typeof category !== 'undefined') {
                if (typeof category !== 'undefined' && typeof category.top_level_id !== 'undefined' && category.top_level_id) {
                    category = _.find(that.categoryModel.attributes, {id: category.top_level_id});
                }
                if (typeof category.name !== 'undefined' ) {
                    serviceModel.set('category', category.name);
                } else {
                    serviceModel.set('category', "unknown");
                }
            } else {
                serviceModel.set('category', "Other");
            }
            that.add(serviceModel);
        });

        this.packagesCollection.each(function(packageModel, key) {
            packageModel.set('category', 'Packages');
            that.add(packageModel);
        });


        this.setState();


        /**
         * place in an object with categories as keys
         */
        this.groupedServices = this.groupBy('category');
    }

});
