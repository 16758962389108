/**
 * Holds details about a user.
 *
 * @module
 */
"use strict";
/* global FB */
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    urlHelpers = require('./../helpers/urlHelpers'),
    base64 = require('./../helpers/base64'),
    config = require('./configModel'),
    MergeView = require('./../views/mergeView.js'),
    AttachmentCollection = require('./../collections/attachmentCollection');

var isWebview = require('is-ua-webview');
require('jquery.cookie');
require('bootstrap'); // for unit testing
require('bootstrap-growl');

module.exports = Backbone.Model.extend({
    urlRoot: config.getAPIUrl() + "customer/" + config.getBusinessToken(),
    incorrectCodeCount: 0,
    newBookingMade:false,
    servicesUnavailable: false,
    zeroDurationBooking: false,
    facebookToken: null,
    facebookState: null,

    defaults: {
        logged_in: false,
        futureAppointments: false,
        pastAppointments: false,
        //isFacebookLogin: false,
        enableChangePassword: false,
        signUpNoBooking: false,
        attachments_photo: false,
        showLoyaltyBlurb: true
    },

    save: function (attr, options) {
        if (this.isNew()) {
            this.options.url = config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/register";
        }
        Backbone.Model.prototype.save.call(this, attr, options);

    },

    isLoggedIn: function () {
        return this.get('logged_in') === true;
    },

    fetchFutureAppointments: function (amount, btoken, bustCache) {
        var that = this,
            deferred = $.Deferred(),
            promise = deferred.promise();

        if(!this.get('futureAppointments') || bustCache){
            var url = config.getAPIUrl() + "booking/" + btoken + "/customer/" + this.id + '/future/' + amount;
            $.get(url, {}, 'json').done(function(data){
                that.set('futureAppointments', data);
                deferred.resolve(data);
            }).fail(function(xhr, textStatus, errorThrown) {
                if(xhr.status == 401){
                    //we are accessing an dsiabled branch
                    xhr.errorHandled = true;
                    that.set('futureAppointments', []);
                    deferred.resolve([]);
                }
            });
        } else {
            deferred.resolve(this.get('futureAppointments'));
        }

        return promise;

    },
    fetchPastAppointments: function (amount, btoken) {
        var that = this,
            deferred = $.Deferred(),
            promise = deferred.promise();

        if(!this.get('pastAppointments')){
            var url = config.getAPIUrl() + "booking/" + btoken + "/customer/" + this.id + '/past/' + amount;
            $.get(url, {}, 'json').done(function(data){
                that.set('pastAppointments', data);
                deferred.resolve(data);
            }).fail(function(xhr, textStatus, errorThrown) {
                if(xhr.status == 401){
                    //we are accessing an dsiabled branch
                    xhr.errorHandled = true;
                    that.set('pastAppointments', []);
                    deferred.resolve([]);
                }
            });
        } else {
            deferred.resolve(this.get('pastAppointments'));
        }

        return promise;
    },
    getPreviousBookedBranchDetails: function () {
        var chain_customer_id = this.get('chain_customer_id'),
            business_id = this.get('business_id');
        return $.get(config.getAPIUrl() + "customer/" + business_id + "/locations_previously_visited/" + chain_customer_id);


    },
    register: function (formData, businessModel, fbRegister) {
        var that = this;
        fbRegister = (typeof fbRegister === "boolean") ? fbRegister : false;

        var origFacebookEmail = this.get('email'); //the email is replaced on the user model once the data is fetched
        var smsEnabled = (businessModel.get('sms_enabled_flag') === '1');
        var registerURL = config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/register";
        var loginType = this.facebookToken === null ? 'cookie' : 'session';

        var registerPost = function(accessToken) {
            if(typeof accessToken != 'undefined') {
                formData += "&accessToken=" + accessToken;
                formData += "&loginType=" + loginType;
            }

            $.post(registerURL, formData)
                .done(function (data) {

                    that.successRegister(data);
                    $.cookie('userEmail', data.email);
                    $.cookie('userPhone', data.phone);

                    if(fbRegister && data.email === origFacebookEmail) {
                        //if sms is not enabled and we are registering via facebook we can auto confirm them
                        //but only if the submitted email matches the facebook email (this is also checked server side)

                        $.ajax({
                            url: config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/facebook_confirm",
                            data: {
                                accessToken: accessToken,
                                loginType: loginType
                            },
                            type: 'PUT',
                            success: function(response){
                                var hashPath = window.location.hash,
                                    query_index = hashPath.indexOf('?');
                                if (query_index !== -1) {
                                    hashPath = '#confirm' + hashPath.substr(query_index);
                                } else {
                                    hashPath = '#landing';
                                }

                                that.confirmResponse.call(that, response, data.email, null, hashPath, "email");
                            },
                            fail: function(response){
                                console.log(JSON.stringify(response));
                            }
                        });

                    } else {
                        //sorry for adding to the mess
                        $.cookie('kitomba.onlinebookings2.enableChangePassword', 'true');

                        if(smsEnabled) {
                            //business has sms enable, always take them to the confirm mobile
                            $('body').trigger('route:navigate', 'confirmMobile/');
                        } else {
                            //else we just send them to the email confirm page
                            $('body').trigger('route:navigate', 'emailFallback/noResend');
                        }
                    }

                })
                .fail(function (xhr, textStatus, errorThrown) {
                    xhr.errorHandled = true;
                    $('#formSignup input').removeAttr('disabled');
                    $('#btnContinue').removeClass('btn-default disabled').addClass('btn-success').find('.fa-circle-o-notch').removeClass().addClass('fa fa-chevron-circle-right');
                    if(xhr.responseJSON.error === "Email taken"){
                        $('#emailTakenModal').modal('show');
                    }
                });
        };


        if(fbRegister && loginType === 'cookie') {
            FB.getLoginStatus(function (response) { //this should be instant due to the status:true in FB.init()
                //listen to the change on this field to know when the facebook SDK has loaded
                that.set('facebookActive', true);
                if (response.status === 'connected') {
                    registerPost(response.authResponse.accessToken);
                } else {
                    $('#fbErrorCode').text('ERR_REGISTER_NO_LINK');
                    $('#fbError').modal('show');
                    analytics.trackGAEvent(false,'Error','Facebook error');
                }
            });
        } else {
            registerPost(that.facebookToken);
        }

    },
    /**
     * user has registered but not confirmed.
     * @param data
     */
    successRegister: function (data) {
        this.set(data);
        analytics.trackGAEvent(false, 'Account', 'Sign up form submitted');
        this.incorrectCodeCount = 0;
    },
    confirmMergeUser: function (code, email, choice, stage) {
        var that = this,
            data = {},
            success = function (response) {
                if (response.error === "Email/Confirmation code does not match any existing user") {
                    that.incorrectCodeCount++;
                } else {
                    that.setLoginCookies(email, response.api_token,response.customer);
                    window.location.hash = stage;
                    if(stage.indexOf('redirect=myBookings') !== -1){
                        $('body').trigger('route:navigate_replace', 'myProfile/bookings');
                    }else{
                        var query_index = stage.indexOf('?');
                        stage = 'services/selected'+stage.substr(query_index);
                        $('body').trigger('route:navigate_replace', stage);
                    }
                }
            };

        data.code = code;
        data.email = email;
        data.mergeChoice = choice;

        $.ajax({
            url: config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/merge",
            type: 'PUT',
            data: data,
            success: success
        });
    },

    confirmResponse: function(response, email, code, stage, type){
        var that = this;
        var notify;

        if (typeof response.merge_candidates !== 'undefined') {
            var mergeView = new MergeView({
                email: email,
                code: code,
                userModel: that,
                stage: stage,
                mergeCandidates: response.merge_candidates
            });

            analytics.trackPageView(window.location.pathname + "/isThisYou", "Is this you?");
            mergeView.render();
        } else {
            var redirectToHomepage = false;
            if (response.error === "Email/Confirmation code does not match any existing user") {
                that.incorrectCodeCount++;
            } else {
                if(type === 'sms') {
                    analytics.trackGAEvent(false, 'Account', 'Verified by mobile (correct PIN entered)');
                }
                if(response.error === "User already confirmed") {
                    notify = $.notify('The user has already been confirmed', { allow_dismiss: false });

                    // considering the services, staff, time is more likely not available
                    // do a redirect to homepage
                    redirectToHomepage = true;
                }


                analytics.trackGAEvent(false, 'Account', 'New customer registered');

                that.setLoginCookies(email, response.api_token, response.customer);


                $.removeCookie('userPhone');
                $.removeCookie('userEmail');
                that.set('signUpNoBooking',false);

                if(that.servicesUnavailable){
                    window.location.hash = 'services';
                    $('body').trigger('route:navigate_reload');
                }
                else if(redirectToHomepage){
                    $('body').trigger('route:navigate_replace', '');
                }
                else if (stage === '') {
                    $('body').trigger('route:navigate_replace', stage);
                }else if(stage.indexOf('redirect=myBookings') !== -1){
                    $('body').trigger('route:navigate_replace', 'myProfile/bookings');

                }else if(stage.indexOf('redirect=service') !== -1){
                    var query_index = stage.indexOf('?');
                    stage = 'services/selected'+stage.substr(query_index);
                    $('body').trigger('route:navigate_replace', stage);
                }else if(stage.indexOf('confirm?services') !== -1){
                    $('body').trigger('route:navigate_replace', stage);
                }else {
                    window.location.hash = stage;
                    $('body').trigger('route:navigate_reload');
                }
            }
        }
    },

    confirmUser: function (code, email, stage, goToTimeSelect, type) {
        var that = this, notify;

        if (typeof stage === 'undefined' || !stage) {
            stage = '';
        } else {
            var redirect = (stage.indexOf('redirect=') !== -1);
            var query_index = stage.indexOf('services');
            if (query_index === -1 && !redirect) {
                stage = '';
            }
            query_index = stage.indexOf('?');
            if(query_index !== -1) {
                stage = stage.substr(query_index);
            }
        }

        if (stage !== '') {
            if (goToTimeSelect) {
                stage = '#time' + stage;
            } else {
                stage = '#confirm' + stage;
            }
        }

        var data = {};
        data.code = code;
        data.email = email;
        var success = function (response) {

        };

        return $.ajax({
            url: config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/confirm",
            type: 'PUT',
            data: data,
            success: function(response){
                that.confirmResponse.call(that, response, email, code, stage, type);
            }
        });
    },

    confirmPendingTxt: function (code) {
        var that = this;
        var data = {};
        data.code = code;
        data.email = this.get('email');
        data.pending_type = 'txt';
        data.pending_value = this.get('pending_phone');
        var success = function (result) {
            that.set(result.customer);
            analytics.trackGAEvent(false, 'Non-booking behaviour', 'User verified personal information by SMS');
        };

        var error = function (result) {
            if (result.responseJSON && result.responseJSON.error && result.responseJSON.error.indexOf("does not match") > 0) {
                that.incorrectCodeCount++;
            }

        };

        return $.ajax({
            url: config.getAPIUrl() + "customer/" + this.get('business_id') + "/confirm_pending",
            type: 'PUT',
            data: data,
            success: success,
            error: error
        });
    },
    confirmPendingEmail: function (code, pending_email, old_email, stage) {
        var that = this;
        var data = {};
        data.code = code;
        data.email = old_email;
        data.pending_type = 'email';
        data.pending_value = pending_email;
        data.stage = stage;
        var success = function (result) {
            analytics.trackGAEvent(false, 'Non-booking behaviour', 'User verified personal information by email');
            analytics.trackGAEvent(false, 'Account', 'Verified by email');
            that.setLoginCookies(pending_email, result.api_token,result.customer);

            if (stage != null) {
                $('body').trigger('route:navigate_replace', stage);
            } else {
                $('body').trigger('route:navigate_replace', '');
            }
            $.notify({
                // options
                icon: 'fa fa-check',
                message: 'Thanks, we\'ve verified your email.'
            }, {
                // settings
                type: 'success',
                z_index: 2000,
                placement: {
                    from: "top",
                    align: "center"
                },
                animate: {
                    enter: 'animated fadeInDown',
                    exit: 'animated fadeOutUp'
                },
                delay: 500,
                timer: 3000,
                offset: {
                    y: 5
                }
            });
        };


        $.ajax({
            url: config.getAPIUrl() + "customer/" + this.get('business_id') + "/confirm_pending",
            type: 'PUT',
            data: data,
            success: success
        });
    },

    sendPending: function (type) {
        if (this.get('logged_in') !== true) {
            $('body').trigger('route:navigate_replace', this.resetInfo.stage);
        } else {
            var postData = {
                email: this.get('clean_email'),
                type: type
            };

            if (window.location.hash !== "") {
                postData.stage = window.location.hash;
            }
            config.fetchFromDom();
            $.post(config.getAPIUrl() + "customer/" + this.get('business_id') + "/send_pending", postData)
                .done(function (data) {
                    console.log('success');
                })
                .fail(function (xhr, textStatus, errorThrown) {

                });

        }
        return false;
    },

    bookingHasDeposit: function (booking){
        function has(val){
            return typeof val !== 'undefined' && val !== null;
        }

        return has(booking.paymentAmount) && has(booking.paymentStatus);
    },

    resendConfirmationTxt: function (phone) {
        var that = this;
        this.set('type', 'txt');
        this.set('phone', phone);
        //because 'this' has sub objects we need to stringify it because backbone.toJson does not
        //do a deep copy which causes issues
        var putData = JSON.parse(JSON.stringify(this.toJSON()));
       return $.post(config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/resend", putData).done(function (data) {
            that.set('phone', data.phone); //returns phone in international format
        });
    },
    resendConfirmationEmail: function () {
        var that = this;
        var hash_url = window.location.hash,
            query_index = hash_url.indexOf('?'),
            query_string = '';
        if (query_index !== -1) {
            query_string = hash_url.substr(query_index);
        }
           //Login wall should redirect through to confirm with stateModel
        if(query_string !== ""){
            if(that.get('signUpNoBooking')){
                this.set('stage', encodeURIComponent(decodeURI(query_string) + '&redirect=service'));
            }else{
                this.set('stage', encodeURIComponent('#time' + decodeURI(query_string)));
            }
        }else{
            query_string = '?redirect=myBookings';
            this.set('stage', encodeURIComponent(decodeURI(query_string)));
        }
        this.set('type', 'email');
        //because 'this' has sub objects we need to stringify it because backbone.toJson does not
        //do a deep copy which causes issues
        var putData = JSON.parse(JSON.stringify(this.toJSON()));
        $.post(config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/resend", putData).done(function (data) {
            that.successEmailConf(data);
        });

    },
    /**
     * sends an email to customer
     *
     * @param result
     */
    successEmailConf: function (result) {
        if (result !== "") {
            if (result.error !== undefined) {
                $('#emailTakenModal').modal('show');
            }
            else if (result.customer.email != null) {
                this.set('email', result.customer.email);
                $.cookie('userEmail', result.customer.email);
            }
        }
    },
    login: function (email, password, callback) {
        var that = this,
            postData = {
                'email': email,
                'password': password
            };

        if (typeof callback !== 'function') {
            //default callback
            callback = function (data) {
                that.setLoginCookies(email, data.api_token, data.customer);
                if (data.customer.termsAcceptRequired) {
                    var hashPath = urlHelpers.getHashPath();
                    if (urlHelpers.onHomePage()) {
                        hashPath = '#landing';
                    }

                    window.location.hash = '#acceptTerms/' + encodeURIComponent(hashPath);
                    $('body').trigger('route:navigate_reload_from_state');
                }
            };
        }

        return $.post(config.getAPIUrl() + "auth/" + config.getBusinessToken() + "/login", postData)
            .done(callback)
            .fail(function (xhr, textStatus, errorThrown) {
                that.set('logged_in', false);
            });
    },

    fblogin: function(){
        var accessToken = false,
            that = this;

        var promise1 = $.Deferred(),
            promise2 = $.Deferred();

        FB.getLoginStatus(function(response) {
            if (response.status === 'connected') {
                accessToken =  response.authResponse.accessToken;
                promise1.resolve(accessToken);
            } else {
                promise1.reject();
                promise2.reject();
                $("body").trigger("route:navigate_reload_with_error", {
                    title: 'Facebook error',
                    message: 'false',
                    subTitle: 'Could not complete facebook authentication at this time',
                    targetPage: ''
                });
            }
        });

        $.when(promise1).done(
            function(accessToken) {
                that.fbLoginRequest(accessToken).done(function(data) {
                    promise2.resolve(data);
                }).fail(function(e) {
                    promise2.reject(e);
                });
            }
        );

        return promise2;
    },

    fbLoginRequest: function(accessToken) {
        var siteRoot = $('body').data('base-url');

        return $.get(
            siteRoot + 'fblogin.php',
            {
                id: config.getBusinessToken(),
                access_token: accessToken,
                login_type: 'cookie'
            },
            'json'
        );
    },

    fbLoginWithoutCookie: function(pathVariables, loginType) {
        var siteRoot = $('body').data('base-url');

        return $.get(
            siteRoot + 'fblogin.php?'
            +pathVariables
            +'&id='+config.getBusinessToken()
                +'&login_type='+loginType
        );
    },

    shouldUseFacebookRedirect: function() {
        return isWebview(window.navigator.userAgent);
    },

    redirectToFacebook: function() {
        window.location = window.facebookRedirectURL;
    },

    /**
     * Do this so that we can mock out FB library functions in unit tests
     */
    getFacebookLibrary: function() {
        return FB;
    },

    getLoginCookies: function () {
        var email = $.cookie('kitomba.onlinebookings2.email'),
            api_token = $.cookie('kitomba.onlinebookings2.api_token');
        return {email: email, api_token: api_token};
    },
    /**
     *
     * @param email
     * @param api_token
     * @param data items to set on the user model
     */
    setLoginCookies: function (email, api_token, data) {
        if(data) {
            this.set(data);
        }
        this.set('logged_in', true);
        this.set('clean_email', email);
        this.set('api_token', api_token);
        this.set('futureAppointments', false);
        this.set('pastAppointments', false);
        this.set('bustCache', true);
        this.set('showLoyaltyBlurb', true);


        if(this.get('expiring_loyalty') && typeof this.get('expiring_loyalty') == 'object' && this.get('expiring_loyalty').total_expiring > 0) {
            this.get('expiring_loyalty').first_value = this.get('expiring_loyalty').breakdown[0].expiring_loyalty_formatted;
            this.get('expiring_loyalty').first_date = this.get('expiring_loyalty').breakdown[0].expiry_date_formatted;
        }

        $.cookie('kitomba.onlinebookings2.email', email);
        $.cookie('kitomba.onlinebookings2.api_token', api_token);
        $.ajaxSetup({
            headers: {
                'Authorization': 'Basic ' + base64.btoa(email + ":" + api_token)
            }
        });
    },
    removeLoginCookies: function () {
        this.clear();
        this.facebookToken = null;
        this.facebookState = null;
        $.removeCookie('kitomba.onlinebookings2.email');
        $.removeCookie('kitomba.onlinebookings2.api_token');
        if (typeof $.ajaxSettings.headers !== 'undefined') {
            delete $.ajaxSettings.headers.Authorization;
        }
        this.set('bustCache', true);
    },
    fetchPhotos: function() {
        var that = this, attCol = new AttachmentCollection(this.get('business_id'), this.get('id'), 'client'),
            attColPromise = attCol.fetch().done(function() {
                that.set('attachments_photo', attCol);
        });
        return attColPromise;
    },
    //TODO: convert this in to a promise
    fetchFromFacebook: function (callback) {
        var that = this;
        if (this.shouldUseFacebookRedirect()) {
            window.location = window.facebookRedirectURL;
        } else {
            FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    FB.api('/me', {fields: ['first_name', 'last_name', 'name', 'email']}, function (response) {
                        that.set({
                            first_name: response.first_name,
                            last_name: response.last_name,
                            email: response.email
                        });

                        callback(null);
                    });
                } else {
                    $('#fbErrorCode').text('ERR_FETCH_NO_LINK');
                    $('#fbError').modal('show');
                    analytics.trackGAEvent(false, 'Error', 'Facebook error');
                }
            });
        }
    },

    hasAnyBooking: function() {
        return this.get('futureAppointments').length > 0 || this.get('pastAppointments').length > 0;
    },

    /**
     *
     * @param booking_photos array Ids of photos you would like to get
     */
    getSubsetOfAttachmentModels: function(booking_photos) {
        var models = [],
            userPhotos = [];

        //god knows why these need to be strings!!!
        booking_photos = booking_photos.map(String);

        if (booking_photos.length > 0) {

            if (this.get('attachments_photo')) {
                userPhotos = this.get('attachments_photo').models;
            }

            if (userPhotos.length > 0) {
                models = _.filter(userPhotos, function(photo) {
                    return ($.inArray(photo.id.toString(), booking_photos) > -1);
                });
            }
        }
        return models;
    },

    getFullName: function(){
        if(this.get('logged_in')){
            return (this.get('firstName') + " " + this.get('lastName')).trim();
        }

        return "";
    }
});
