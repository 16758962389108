/**
 * Reset password
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    template = Handlebars.compile(require('./../../templates/resetPassword.hbs').default),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    base64 = require('./../helpers/base64'),
    BaseView = require('./baseView'),
    UserModel = require('./../models/userModel.js'),
    config = require('./../models/configModel');



Backbone.$ = $;
/** @global */
window.jQuery = $;

module.exports = BaseView.extend({
    page: 'reset_password',
    pageTitle: 'Reset password',
    template: template,
    logged_in: false,
    loginPromise: false,
    events: {
        "click #newPwSubmit": 'changePassword',
        'click #cancelSendNewResetPasswordBtn': 'clickCancelNewRestPassword',
        'keyup #failedForgotPassForm input': 'checkForgotEnterKeyAction',
        'click #failedSendPwReminder.btn-success': 'resetPasswordAction'
    },
    initialize: function(options) {
        //call parent constructor
        var that = this;
        this.businessModel = options.businessModel;
        this.resetInfo = options.resetInfo;
        this.userModel = options.userModel;
        BaseView.prototype.initialize(options);
        this.loginPromise = this.userModel.login(this.resetInfo.email, this.resetInfo.code);
        $.when(this.loginPromise).always(function() {
            that.myBookingCheckPromise = true;
            if(that.resetInfo.stage === 'fromHomePage') {
                if(!that.userModel.get('logged_in')) {
                    that.resetInfo.stage = '#landing';
                } else {
                    that.resetInfo.stage = '#services/popular';//IE a default if following call fails.
                    that.myBookingCheckPromise = $.when(that.userModel.fetchFutureAppointments(1,that.userModel.get('business_id')), that.userModel.fetchPastAppointments(1,that.userModel.get('business_id')))
                        .done(function() {
                            if(that.userModel.get('pastAppointments').length > 0 || that.userModel.get('futureAppointments').length > 0) {
                                that.resetInfo.stage = '#myProfile/bookings';
                            }
                        });
                }
            }
        });
    },
    render: function() {
        var that = this;
        $.when(this.loginPromise, this.myBookingCheckPromise).always(function() {
            that.$el.html(that.template({logged_in: that.userModel.get('logged_in'), email: that.resetInfo.email}));
            BaseView.prototype.render(that);
            that.setPageTitle(that.pageTitle);
            that.initValidator();
        });
    },
    clickCancelNewRestPassword: function(e) {
        e.preventDefault();
        $('body').trigger('route:navigate_replace', '');
    },
    changePassword: function(e) {
        e.preventDefault();
        if (!$('#resetPassword').valid()) {
            return;
        }

        if(this.userModel.get('logged_in') !== true) {
            window.location.hash = encodeURI(this.resetInfo.stage);
            $('body').trigger('route:navigate_reload');
        } else {
            var email = this.userModel.get('clean_email'),
                putData = {
                    old_password: this.resetInfo.code,
                    password: $('#newPw').val(),
                    passconf: $('#newPwCnf').val(),
                    first_name: this.userModel.get('firstName'),
                    last_name: this.userModel.get('lastName'),
                    kid: this.userModel.get('id'),
                    email: this.userModel.get('email'),
                    phone: this.userModel.get('phone'),
                    street: this.userModel.get('street'),
                    suburb: this.userModel.get('suburb'),
                    city: this.userModel.get('city'),
                    postcode: this.userModel.get('postalCode'),
                    confMethod: this.userModel.get('confMethod'),
                    gender: this.userModel.get('gender')
                },
                that = this;

            config.fetchFromDom();
            $.ajax({
                type: 'PUT',
                url: config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/" + this.userModel.get('id'),
                data: putData,
                dataType: 'json',
                headers: {
                    'Authorization': 'Basic ' + base64.btoa(this.userModel.get('clean_email') + ":" + this.userModel.get('api_token'))
                },
                success: function(data) {
                    that.userModel.setLoginCookies(email, data.api_token, data.customer);
                    analytics.trackGAEvent(false, 'Account', 'Password reset');
                    window.location.hash = encodeURI(that.resetInfo.stage);
                    $('body').trigger('route:navigate_reload');
                },
                error: function() {
                    that.userModel.removeLoginCookies();
                    window.location.hash = encodeURI(that.resetInfo.stage);
                    $('body').trigger('route:navigate_reload');
                }
            });

        }
        return false;
    },
    initValidator: function () {
        $.validator.addMethod( 'passwordMatch', function(value, element) {
            // The two password inputs
            var password = $("#newPw").val();
            var confirmPassword = $("#newPwCnf").val();

            // Check for equality with the password inputs
            if (password != confirmPassword ) { //jshint ignore:line
                return false;
            } else {
                return true;
            }
        }, "Your new passwords don't match");

        $('#resetPassword').validate({
            onfocusout: false,
            onkeyup: false,
            onclick: false,
            rules: {
                newPw: {
                    required: true,
                    minlength: 8
                },
                newPwCnf: {
                    required: true,
                    passwordMatch: true
                }
            },
            messages: {
                newPw:{
                    'required': "Please enter your new password.",
                    'minlength': "Your new password must be at least 8 characters long."
                    //  'equalTo': 'New passwords do not match.'
                },
                newPwCnf:{
                    'required': "Please re-type your new password.",
                    'minlength': "Your new password must be at least 8 characters long.",
                    //'equalTo': 'New passwords do not match.',
                    passwordMatch: "Your new passwords don't match."
                }
            },
            errorLabelContainer: "#resetPasswordValidationAnchor",
            wrapper: 'li',
            errorPlacement: function (error, element) {
                $('#resetPasswordValidationAnchor').append(error);
            }
        });
    },

    checkForgotEnterKeyAction: function (e) {
        if (e.which === 13 && $('#failedSendPwReminder').hasClass('btn-success')) { // enter key
            this.resetPasswordAction(e);
        }
        return false;
    },

    resetPasswordAction: function (e) {
        e.preventDefault();
        var $reset_password_email = $('#failed_reset_password_email'),
            email = $reset_password_email.val(),
            $forgotPWBtn = $('#failedSendPwReminder');
        $forgotPWBtn.removeClass('btn-success').addClass('btn-default disabled');
        $reset_password_email.blur();
        var postData = {
            'email': email
        };

        if (this.resetInfo.stage === ''
            || this.resetInfo.stage === '#landing'
            || this.resetInfo.stage === '#') {
            postData.stage = 'fromHomePage';//Keyword...
        } else {
            postData.stage = encodeURIComponent(decodeURI(this.resetInfo.stage));
        }

        config.fetchFromDom();
        $.ajax({
            url: config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/reset_password",
            type: "POST",
            data: postData,
            suppressErrors: true
        }).done(function () {
            $('#failedForgotPWFailure').hide();
            $('#failedForgotPassForm').fadeOut(function () {
                $('#failedPwResetSentTo').html(postData.email);
                $('#failedPwEmailSentMsg').show();
            });
        })
            .fail(function () {
                $('#failedForgotPWFailure').show();
                $forgotPWBtn.addClass('btn-success').removeClass('btn-default disabled');
            });
    }
});
