/**
 * Services page.
 *
 * Includes tabs:
 * - popular
 * - recent
 * - all (with search)
 * - selected (only shows when items selected)
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');
var $ = require('jquery'),
    _ = require('underscore'),
    templatePage = Handlebars.compile(require('./../../templates/pageServices.hbs').default),
    templateServiceList = Handlebars.compile(require('./../../templates/tabAllServicesList.hbs').default),
    templateTabAll = Handlebars.compile(require('./../../templates/tabServicesAll.hbs').default),
    templateTabPopular = Handlebars.compile(require('./../../templates/tabServicesPopular.hbs').default),
    templateTabPrevious = Handlebars.compile(require('./../../templates/tabServicesPrevious.hbs').default),
    templateTabSelected = Handlebars.compile(require('./../../templates/tabServicesSelected.hbs').default),
    partialFollowOnRow = Handlebars.compile(require('./../../templates/partialFollowOnRow.hbs').default),
    stringHelpers = require('./../helpers/stringHelpers'),
    Backbone = require('backbone'),
    BaseView = require('./baseView'),
    Handlebars = require('handlebars');

Backbone.$ = $;

require('jquery-ui');

module.exports = BaseView.extend({
    page: 'services',
    pageTitle: 'Choose services',
    lastSelectedTab: '',
    servicesPopular: {},
    servicesAll: {},
    servicesSelected: {},
    template: templatePage,
    templateServiceList: templateServiceList,
    events: function (){
        return {

            'change input[type="checkbox"]': 'toggleSelectionChange',
            'keyup #serviceSearchField': _.debounce(this.search, 200) //run max once every 200ms
        };
    },
    initialize: function (options) {
        //call parent constructor
        BaseView.prototype.initialize(options);

        if (options.servicesPopular) {
            this.servicesPopular = options.servicesPopular;
        }
        if (options.servicesAll) {
            this.servicesAll = options.servicesAll;
        }
        if (options.servicesSelected) {
            this.servicesSelected = options.servicesSelected;
        }
        if (options.clientHistoryCollection) {
            this.clientHistoryCollection = options.clientHistoryCollection;
        }
        if (options.userModel) {
            this.userModel = options.userModel;
        }
        _.bindAll(this, 'search');
    },
    mobileTweaks: function (tab) {
        function searchScroll() {
            //  document.body.scrollTop = distance;
            $('html, body').animate({scrollTop: distance}, 300);
        }

        // This is to slide the search box to the top on focus, on smaller devices.
        // This bit here:
        // $(window).width() < 768 && window.innerHeight > window.innerWidth
        //...is to emulate this piece of CSS:
        // @media (max-width: 767px) and (orientation: portrait)
        if (tab === 'all' && $(window).width() < 768 && window.innerHeight > window.innerWidth) {
            var distance = $('#serviceSearchField').offset().top - 10;

            $('.container.withProceeder').css('min-height', $(window).height() + distance);
            $(document).off('focus', '#serviceSearchField', searchScroll);
            $(document).on('focus', '#serviceSearchField', searchScroll);
        }
    },
    loginModalShowEvent: function (event) {
        var button = $(event.relatedTarget),
            modal = $(event.currentTarget),
            from = button.data('from'),
            msgDiv = modal.find('.loginModalMessage');
        msgDiv.data('reason', 'none');
        if (typeof from !== 'undefined') {
            if(from === 'prevBooked') {
                msgDiv.html('<i class="fa fa-lock"></i> You need to be logged in to view your previously booked services.');
            } else if(from === 'myProfile/bookings') {
                msgDiv.html('<i class="fa fa-lock"></i> You need to be logged in to view my bookings.');
            } else if(from === 'myProfile/details') {
                msgDiv.html('<i class="fa fa-lock"></i> You need to be logged in to view my account.');
            } else if(from === 'myProfile/bookings') {
                msgDiv.html('<i class="fa fa-lock"></i> You need to be logged in to view my bookings.');
            }
            msgDiv.data('reason', from);
            msgDiv.show();
        } else {
            msgDiv.hide();
        }
    },
    /**
     * called to narrow all service results by search term.
     * should be debounced.
     *
     * @param e
     */
    search: function (e) {
        var data = this.mergeTemplateData(),
            searchTerm = $(e.currentTarget).val(),
            searchedServices = this.servicesAll.clone(),
            showDescriptions = this.businessModel.get('show_descriptions');

        if (searchTerm) {
            searchedServices.models = searchedServices.filter(function (item) {
                var itemName = stringHelpers.setAllSpacesToSingleSpaceAndTrim(item.get('name'));
                var itemDescription = stringHelpers.setAllSpacesToSingleSpaceAndTrim(item.get('ob_desc'));
                var searchText = stringHelpers.setAllSpacesToSingleSpaceAndTrim(searchTerm);
                return itemName.indexOf(searchText) !== -1
                       || (showDescriptions && itemDescription.indexOf(searchText) !== -1);
            });
            data.servicesAll = searchedServices;

        } else {
            data.servicesAll = this.servicesAll;
        }

        data.servicesAll.groupedServices = data.servicesAll.groupBy('category');
        data.searchTerm = searchTerm;

        this.$el.find('#allServs').html(this.templateServiceList(data));
    },
    toggleSelectionChange: function(e) {
        if(e.currentTarget.id === 'acceptPromos'){
            return;
        }

        var $target = $(e.currentTarget),
            checked = $target.prop('checked'),
            servicesId = $target.data('serviceId'),
            serviceType = $target.data('serviceType');

        this.toggleSelection(
            checked,
            servicesId,
            serviceType,
            $target
        );
    },
    /**
     * when service is selected add/remove it to state
     *
     * @param e
     */
    toggleSelection: function (checked, servicesId, serviceType, $target) {
        var badge = $target.parents('.panel').find('.badge');

        if( checked && this.servicesSelected.getNumberOfSelectedServices() >= 10) {
            //to stop ui getting out of sync
            $target.prop('checked', false);
            alert("Sorry a maximum of 10 services (including services in packages) can be selected at once.");
            return false;
        }

       this.makeServiceSelection(checked, servicesId, serviceType, badge, $target);
    },

    makeServiceSelection: function(checked, servicesId, serviceType, badge, $target) {
        var that = this,
            serviceModel = this.servicesAll.get(servicesId),
            badgeCount = badge.html() || 0;

        var showFollowOnModal = this.checkAndPrepareFollowOnModal(checked, serviceModel);

        if(showFollowOnModal) {
            $('#followOnServiceSelection').on('click', '.list-group-item', function(event) {
                $('#followOnServiceSelection').off('click','.list-group-item');

                $('#followOnServiceSelection').on('hidden.bs.modal', function() {
                    var $followOnServiceTarget = $(event.target),
                        followOnServiceId = $followOnServiceTarget.data('id');

                    $('#previous, #popular, #all')
                        .find('input[data-service-id="' + followOnServiceId + '"]')
                        .prop('checked', true); // Make sure the page is consistent...

                    that.toggleSelection(
                        true,
                        followOnServiceId,
                        'service',
                        $target.closest('.tab-pane').find('input[data-service-id="' + followOnServiceId + '"]')
                    );
                    $('#followOnServiceSelection').off('hidden.bs.modal');
                });
            });
            $('#followOnServiceSelection').modal('show');
            $('#followOnServiceSelection').on('hide.bs.modal', function() {
                that.wrapUpSelection(checked, servicesId, serviceType, badge, badgeCount, $target);
                $('#followOnServiceSelection').off('hide.bs.modal');
                $('#followOnServiceSelection').off('click','.list-group-item');
            });
            $target.closest('label.clickableLabel').addClass('selected');
        } else {
            that.wrapUpSelection(checked, servicesId, serviceType, badge, badgeCount, $target);
        }
    },

    wrapUpSelection: function(checked, servicesId, serviceType, badge, badgeCount, $target) {
        var serviceModel = this.servicesAll.get(servicesId);
        if(serviceModel.get("selected") === checked) {
            return;
        }
        serviceModel.set("selected", checked);

        //re add/remove items to selected view based on selected flag
        this.servicesSelected.process();

        var targetService = {
            id: servicesId,
            type: serviceType,
            staffId: this.businessModel.getDefaultStaffPerson(),
            serviceCount: serviceType === 'package'
                ? this.servicesSelected.packagesCollection.get(servicesId).get("services").length
                : 1
        };

        if (checked) {
            badgeCount++;
            $target.closest('label').addClass('selected');

            /** @see changeServices **/
            this.trigger('service', 'add', targetService);
        } else {
            badgeCount--;
            $target.closest('label').removeClass('selected');

            /** @see changeServices **/
            this.trigger('service', 'remove', targetService);
        }

        if (!badgeCount || badgeCount <= 0) {
            badgeCount = '';
        }
        badge.html(badgeCount);
    },

    checkAndPrepareFollowOnModal: function(checked, serviceModel) {
        var that = this,
            showFollowOnModal = false,
            followOnList = serviceModel.get("followOnList");

        if(checked
            && typeof followOnList !== 'undefined'
            && followOnList.length > 0) {

            $('#followOnServiceSelection .modal-body .list-group').html(''); // clear it!

            $('#rootService').html(serviceModel.get('name'));

            var atLeastOneSelected = false;
            _.each(serviceModel.get("followOnList"), function(followOnId) {
                var followOnServiceModel = that.servicesAll.get(followOnId);
                if(followOnServiceModel.get("selected")) {
                    atLeastOneSelected = true;
                }

                $('#followOnServiceSelection .modal-body .list-group').append(
                    partialFollowOnRow(followOnServiceModel.toJSON())
                );
            });

            if(!atLeastOneSelected) {
                showFollowOnModal = true;
            }
        }

        return showFollowOnModal;
    },
    /**
     * "selected" tab only shows if selected services > 0
     * if items are selected/deselcted in state then tick/untick them
     * without reloading page. show number of selected items in badge
     * and control status of next button.
     *
     */
    renderSelectedStatus:function() {
        var selectedServices = this.servicesAll.filter(function (service) {
                return service.get('selected');
            }),
            count = selectedServices.length;

        this.$el.find('#selectedBadge').html(count);

        _.each($('.row.service label'), function (row) {
            var currentRow = $(row).find('.serviceCheckbox');

            if (this.stateModel.attributes.services.get(currentRow.data('serviceId').toString())) {
                $(row).addClass('selected');
                $(currentRow).addClass('selected');
            } else {
                $(row).removeClass('selected');
                $(currentRow).removeClass('selected');
            }

        }, this);

        //if on the selected tab don't hide the tab from menu
        if (this.lastSelectedTab === 'selected') {
            $('#selectedTab').show();
            if (count >= 1) {
                $('#noneSelectedMsg').slideUp('fast');
            } else {
                $('#noneSelectedMsg').slideDown('fast');
            }
        } else {
            //show/hide proceeding section
            if (count > 0) {
                $('#selectedTab').show();
            } else {
                $('#selectedTab').hide();
            }
        }

        if (count > 0) {
            $('#btnContinue').removeClass('btn-default disabled').addClass('btn-success');
        } else {
            $('#btnContinue').addClass('btn-default disabled').removeClass('btn-success');
        }
    },
    markTabActive:function(tabName) {
        var name = this.$el.find('#' + tabName);
        var nameTab = this.$el.find('#' + tabName + 'Tab');

        if(!name.hasClass('active')) {
            $('.tab-content .active').removeClass('active');
            name.addClass('active');
        }
        if(!nameTab.hasClass('active')) {
            $('#serviceTabs .active').removeClass('active');
            nameTab.addClass('active');
        }
    },

    renderTabSelected: function (data) {
        this.markTabActive('selected');
        if (this.lastSelectedTab !== 'selected') {
            this.$el.find('#selected').html(templateTabSelected(data));
        }
    },
    renderTabPopular:function(data) {
        this.markTabActive('popular');

        if (this.lastSelectedTab !== 'popular') {
            this.$el.find('#popular').html(templateTabPopular(data));
        }
    },
    renderTabAll:function(data) {
        this.markTabActive('all');

        if (this.lastSelectedTab !== 'all') {
            this.$el.find('#all').html(templateTabAll(data));
        }
    },
    renderTabPrevious:function(data) {
        this.markTabActive('previous');

        if (this.lastSelectedTab !== 'previous') {
            this.$el.find('#previous').html(templateTabPrevious(data));
        }
    },

    /**
     *
     * tabs
     *  - previous
     *  - popular
     *  - all
     *  - selected
     *
     * @param tab
     */
    render: function (tab) {
        var forceReload;
        var originalTab = tab;
        var data = this.mergeTemplateData();
        data.show_popular_services = this.servicesPopular.size() > 0;
        data.servicesAll = this.servicesAll;
        data.servicesPopular = this.servicesPopular;
        data.servicesSelected = this.servicesSelected;
        data.client_history = this.clientHistoryCollection;

        //set the default tab
        if(_.isNull(tab)) {
            if(this.lastSelectedTab){
                //so if render is called from in page event it knows current tab
                tab = this.lastSelectedTab;
            } else if(this.userModel.get('logged_in') && this.clientHistoryCollection.length > 0) {
                tab = 'previous';
            }

            if(tab === 'previous' && this.userModel.get('logged_in') && this.clientHistoryCollection.length === 0) {
                tab = null;
            }

            if(_.isNull(tab)) {
                tab = "popular";
            }
        }

        if ((tab === 'previous' || !tab) && !this.userModel.get('logged_in')) {
            tab = 'popular';
        }
        if ((tab === 'selected') && this.servicesSelected.length <= 0) {
            tab = 'popular';
        }
        if ((tab === 'popular' || !tab) && !data.show_popular_services) {
            tab = 'all';
        }

        if(tab !== originalTab) {
            $('body').trigger('route:replace_only', this.stateModel.buildStateHashUrl('#services/'+tab));
        }

        data.tab = tab;
        data.unavailableServices = this._get_unavailable_services_from_state_model();

        forceReload = this.lastUserModelId !== data.userModel.id;

        if($('.main.withProceeder').data('page') !== this.page || forceReload) {

            data.servicesUnavailable = this.userModel.servicesUnavailable;
            if(this.userModel.servicesUnavailable){
                this.userModel.servicesUnavailable = false;
            }
            //to force tabs to render
            this.lastSelectedTab = false;
            //we are redrawing the page after coming from another page, or f5
            this.$el.html(this.template(data));
            BaseView.prototype.render(this);
            this.setPageTitle(this.pageTitle);
            this.lastUserModelId = data.userModel.id;
        }

        switch(tab) {
            case "all":
                this.renderTabAll(data);
                break;
            case "popular":
                this.renderTabPopular(data);
                break;
            case "previous":
                this.renderTabPrevious(data);
                break;
            case "selected":
                this.renderTabSelected(data);
                break;
        }

        if (this.userModel.zeroDurationBooking === true) {
            $("#zeroDurationErrorModal").modal();
            this.userModel.zeroDurationBooking = false;
        }

        this.lastSelectedTab = tab;
        this.renderSelectedStatus();
        this.mobileTweaks(tab);
    },

    /**
     * Hack: gets current unavailableServices
     * from stateModel to display to user on
     * services screen. Resets stateModel value.
     *
     * @returns {*}
     * @private
     */
    _get_unavailable_services_from_state_model: function() {
        var unavailableServices = this.stateModel.get('unavailable');
        this.stateModel.set('unavailable', []);
        return unavailableServices;
    }
});
