/**
 * Branch List
 *
 * @module
 */
"use strict";

var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    templatePage = Handlebars.compile(require('./../../templates/pageBranchList.hbs').default),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    BaseView = require('./baseView'),
    Handlebars = require('handlebars');
    /*moment = require('moment');*/

Backbone.$ = $;

require('jquery-ui');

module.exports = BaseView.extend({
    template: templatePage,
    page: 'branchList',
    pageTitle: 'Where would you like to book?',

    events: function () {
        //merge events from the parent class with events set in this local variable
        var events = {
        };
        return _.extend(BaseView.prototype.events, events);
    },
    initialize: function (options) {
        BaseView.prototype.initialize(options);

    },
    render: function (nextLocation, isSignupPage) {
        var that = this;
        var mergeData = this.mergeTemplateData();
        mergeData.nextLocation = nextLocation || 'services';
        mergeData.showLoginButton = (isSignupPage !== undefined) ? !isSignupPage : true; //don't show the login if its the sign up page
        mergeData.isSignupPage = !!isSignupPage;
        this.setPageTitle(mergeData.business.name);
        this.getBranchListData(mergeData,this.userModel,function(data){
            that.$el.html(that.template(data));
            BaseView.prototype.render(that);
        });


    }
});
