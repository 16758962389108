/**
 * Confirm email view
 *
 * @module
 */"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    template = Handlebars.compile(require('./../../templates/confirmEmail.hbs').default),
    Backbone = require('backbone'),
    BaseView = require('./baseView'),
    analytics = require('./../helpers/analytics'),
    UserModel = require('./../models/userModel');


require('jquery-validation');
Backbone.$ = $;
/** @global */
window.jQuery = $;
module.exports = BaseView.extend({
    page: 'confirm',
    pageTitle: 'Confirm',
    template: template,
    events: {
            "click #showResendForm": 'showResendForm',
            "click #resendEmailButton": 'resendAndUpdateEmail',
            "click #loginInstead": 'loginInstead'
    },
    initialize: function(options) {
        //call parent constructor
        this.businessModel = options.businessModel;
        this.sendEmail = options.sendEmail;
        BaseView.prototype.initialize(options);

    },
    render: function() {
        $.cookie('codeFailed', true);
        var business_name = this.businessModel.get('name');
        this.$el.html(this.template({email: this.model.get('email'), name:business_name}));
        if(this.sendEmail){
            this.sendConfirmationEmail(this.model.get('email'));
        }
        BaseView.prototype.render(this);
        this.setPageTitle(this.pageTitle);
    },

    sendConfirmationEmail: function(){
        var data = {};
        this.model.resendConfirmationEmail(data);
    },
    showResendForm: function(e) {
        if(e!=null){
            e.preventDefault();
        }
        $('#showResendForm').hide();
        $('#emailResend').slideDown('fast');
    },

    resendAndUpdateEmail:function(){
        this.initValidatorEmail();
        if (!$('#formEmail').valid()) {
            return;
        }
        var data = {};
        this.model.set('newEmail',$('#newEmail').val());

        analytics.trackGAEvent(false,'Account','Changed email from \'verify your email\'');
        $('#emailResend button')
            .removeClass('btn-success')
            .addClass('btn-default disabled')
            .prop('disabled', true)
            .html('Resend email <i class="fa fa-circle-o-notch fa-spin"></i>');

        this.model.resendConfirmationEmail();

        //update where wa said we sent it to
        $('#emailSentTo').html($('#newEmail').val());

        //re-enaable the button
        $('#emailResend').fadeOut('fast', function() {
            $('#emailResend button')
            .addClass('btn-success')
            .removeClass('btn-default disabled')
            .prop('disabled', false)
            .html('Resend email');

            $('#showResendForm').show();

        });

    },
    loginInstead: function(e){
        e.preventDefault();
        $('#emailTakenModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $('body').trigger('route:navigate', 'login/');
    },
    initValidatorEmail: function () {

        $.validator.addMethod('valid_email', function (value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        }, "Please enter a valid email address.");
        $('#formEmail').validate({
            //  onfocusout: false,
            //  onkeyup: false,
            //  onclick: false,
            debug:true,
            rules: {
                newEmail: {
                    required: true,
                    email: true,
                    valid_email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                }
            },
            messages: {
                newEmail: {
                    required: "Please enter a valid email address",
                    email: "Please enter a valid email address",
                    valid_email: "Please enter a valid email address"
                }
            },
            errorLabelContainer: "#resendEmailValidationAnchor",
            wrapper: 'li',
            errorPlacement: function (error, element) {
                $('#resendEmailValidationAnchor').append(error);
            }
        });
    }
});
