/*global fbq */
/**
 * Backbone router
 *
 * @module
 */

'use strict';
var $ = require('jquery'),
    _ = require('underscore'),
    loading = require('./helpers/loadingScreen'),
    Backbone = require('backbone');

require('jquery-ui');
require('jquery.cookie');

Backbone.$ = $;
var config = require('./models/configModel'),
    BaseView = require('./views/baseView'),
    LandingPageView = require('./views/landingPageView'),
    ServicePageView = require('./views/servicesPageView'),
    VoucherPageView = require('./views/voucherPageView'),
    VoucherPurchasedPageView = require('./views/voucherPurchasedPageView'),
    ClientPageView = require('./views/clientPageView'),
    BranchListPageView = require('./views/branchListPageView'),
    SignUpPageView = require('./views/signupPage'),
    ConfirmView = require('./views/confirmView'),
    ConfirmEmailView = require('./views/confirmEmailView'),
    TimePageView = require('./views/timePageView'),
    landingPageView,
    analytics = require('./helpers/analytics'),
    base64 = require('./helpers/base64'),
    Branches = require('./collections/branchesCollection'),
    ServicesPopular = require('./collections/popularCollection'),
    ServicesAll = require('./collections/allServicesCollection'),
    AvailabilityCollection = require('./collections/availabilityCollection'),
    ServicesSelected = require('./collections/selectedCollection'),
    ClientHistoryCollection = require('./collections/clientHistoryCollection'),
    Business = require('./models/businessModel'),
    ServicesCollection = require('./collections/servicesCollection'),
    CategoryModel = require('./models/categoryModel'),
    PackagesCollection = require('./collections/packagesCollection'),
    StaffCollection = require('./collections/staffCollection'),
    UserModel = require('./models/userModel.js'),
    ResetPassword = require('./views/resetPassword'),
    ConfirmPageView = require('./views/confirmPageView'),
    ConfirmPaymentView = require('./views/confirmPaymentView'),
    ConfirmPaymentPauseView = require('./views/confirmPaymentPauseView'),
    BookingModel = require('./models/bookingModel'),
    GlobalEventsView = require('./views/globalEventsView'),
    AcceptNewTermsView = require('./views/acceptNewTermsView'),
    moment = require('moment'),
    urlHelpers = require('./helpers/urlHelpers'),
    VoucherSale = require('./models/voucherSale'),
    PaymentFactory = require('./models/paymentFactory'),
    StateModel = require('./models/stateModel'),
    BookingErrorModal = require('./models/BookingErrorModal');

module.exports = Backbone.Router.extend({

    lastPageLoggedIn: false,
    lastPageRoute: false,
    currentPageRoute: false,
    servicePageView: false,
    clientPageView: false,
    servicesCollection: false,
    availabilityCollection: false,
    packagesCollection: false,
    clientHistoryCollection: false,
    serviceAll: false,
    categoryModel: false,
    servicesPromise: false,
    packagesPromise: false,
    categoryPromise: false,
    staffPromise: false,
    staffCapabilityPromise: false,
    businessModel: {},
    branchesModel: {},
    stateModel: {},
    userModel: new UserModel(),
    staffCollection: null,
    resetPasswordView: false,
    timePageView: false,
    noBookingAvailable: false,
    globalEventsView: null,
    timeRedoError: false,
    // Define URL routes
    // Revisit rules if updating route actions
    routes: {
        '': 'defaultRoute',
        'landing': 'defaultRoute',
        'services(/:tab)': 'serviceRoute',
        'timeRedo': 'timeRedo',
        'time': 'timeGoodRoute',
        'login(/)(:action)(/)(:method)': 'login',
        'pending/:code/:new_email/:old_email(/:stage1)(/:stage2)(/:stage3)': 'confirmPending',
        'verify/:id/:email/:stage': 'confirmUserEmailStage',
        'verify/:id/:email': 'confirmUserEmail',
        'confirmMobile/': 'confirmUserMobile',
        'emailFallback/(:noResend)': 'emailFallback',
        'confirm': 'confirmRoute',
        'error': 'errorRoute',
        'confirmPayment/:customer_kid/:appt_hash/:reschedule': 'confirmPayment',
        'confirmPaymentPause/*path': 'confirmPaymentPause',
        'cancelPayment(/:extra)': 'cancelPayment',
        'voucher(/:extra)': 'voucherRoute',
        'voucherPurchased/:purchaseHash': 'voucherPurchasedRoute',
        'voucherPurchasedPause/*path': 'voucherPurchasedPauseRoute',
        'reset_password/:code/:email/:stage': 'reset_password',
        'branchList': 'branchListRoute',
        'branchListSignup': 'branchListSignupRoute',
        'myProfile(/:tab)': 'myProfileRoute',
        'acceptTerms/:prevPage': 'acceptTerms',
        'branchListSignupAnywhere': 'branchListSignupAnywhereRoute',
        'facebookRedirect/:accessToken/:state': 'handleFacebookRedirect',
        'makePayment': 'makePaymentRoute',
        //404 handling
        "*actions": "notFound"
    },
    rules: {
        'chainCanAccess': [
            'defaultRoute',
            'branchListRoute',
            'branchListSignupRoute',
            'branchListSignupAnywhereRoute',
            'myProfileRoute',
            'acceptTerms',
            'voucherRoute',
            'voucherPurchasedRoute',
            'voucherPurchasedPauseRoute',
            'notFound',
            'handleFacebookRedirect',
            'makePaymentRoute'
        ],
        'blockForBranch': [
            'branchListRoute',
            'branchListSignupAnywhereRoute',
            'voucherRoute'
        ],
        'blockForBoutique': [
            'branchListRoute',
            'branchListSignupAnywhereRoute'
        ],
        'defaultRoute': ''
    },

    initialize: function (options) {
        if (typeof options !== 'undefined' && options.stateModel) {
            this.stateModel = options.stateModel;
        }
        this.businessModel = new Business();

        this.getGlobalEventsView();

        var businessType = this.getPreloadAttribute('type');
        if (this.isLandingPage()) {
            analytics.trackFBEvent('PageView', businessType);
        }
    },
    notFound: function (path) {
        if (path.indexOf("access_token=") >= 0 || path.indexOf("cb=") >= 0) {
            //assume that we have ended up here after user has logged in with the facebook
            //FB doesn't seem to respect setting a hash in the redirect uri path param
            this.handleFacebookCallback(path);
        } else {
            $.get(config.getBaseUrl() + '404')
                .always(function (response) {
                    $('body').html(response)
                });
        }
    },
    checkUserCookie: function () {
        var cookieData = this.userModel.getLoginCookies();
        var signUpAnywhere = this.userModel.get('signUpNoBooking');
        var email = cookieData.email,
            apiToken = cookieData.api_token,
            that = this;

        if (email !== undefined && apiToken !== undefined
            && (this.userModel.get('clean_email') !== email
                || this.userModel.get('api_token') !== apiToken
                || _.isEmpty(this.businessModel)
                || this.businessModel.get('id') !== this.userModel.get('business_id'))
        ) {
            return $.ajax({
                type: 'POST',
                url: config.getAPIUrl() + "auth/" + config.getBusinessToken() + "/switch_business",
                dataType: 'json',
                headers: {
                    'Authorization': 'Basic ' + base64.btoa(email + ":" + apiToken)
                },
                success: function (data) {
                    that.userModel.setLoginCookies(email, data.api_token, data.customer);

                },
                error: function () {
                    that.userModel.removeLoginCookies();
                }
            });
        } else if (email === undefined || apiToken === undefined) {
            that.userModel.removeLoginCookies();
            that.userModel.set('signUpNoBooking', signUpAnywhere); //we dont want to clear this variable
            return false;
        }
        //Either same user or no user...
        return true;
    },
    //this function is way to long
    fetchBusinessData: function (callback) {
        //https://quickleft.com/blog/leveraging-deferreds-in-backbonejs/
        var that = this;

        //caching, business data should only need to be called once per page load.
        if (!_.isEmpty(this.businessModel.get('id')) && !_.isEmpty(this.branchesModel)) {
            callback();
            return;
        }

        this.businessModel.set('id', config.getBusinessToken());
        this.stateModel.set('businessModel', this.businessModel);
        this.branchesModel = new Branches({id: config.getBusinessToken()});

        var preload = $('body').data('business-preload');
        this.businessModel.preload(preload);

        var business = this.businessModel.fetch().done(function (data) {
            if (data.type === 'chain') {
                that.branchesModel.fetch().done(function () {
                    callback();
                });
            } else {
                callback();
            }
        });
    },
    //happens before route method is called
    execute: function (callback, args, name) {
        var that = this;

        //close all modals on navigate
        //bit of a hack as the overlay isn't removed when navigating from the time page for some reason, so we force remove it
        //this assumes that all modals can be closed
        $('.modal').modal('hide');
        $('body').removeClass('modal-open');
        $('body').removeClass('withKitombaPay');
        $('.modal-backdrop').remove();

        this.stateModel.fetch();

        $.when(this.checkUserCookie()).always(function () {
            that.fetchBusinessData(function () {
                if (callback) {

                    if (that.businessModel.is_chain()) {
                        if (_.indexOf(that.rules.chainCanAccess, name) === -1) {
                            //chain can't access
                            $('body').trigger('route:navigate_replace', that.rules.defaultRoute);
                            return;
                        }
                    } else if (that.businessModel.is_branch()) {
                        if (_.indexOf(that.rules.blockForBranch, name) > -1) {
                            //branch can't access
                            $('body').trigger('route:navigate_replace', that.rules.defaultRoute);
                            return;
                        }
                    } else if (that.businessModel.is_boutique()) {
                        if (_.indexOf(that.rules.blockForBoutique, name) > -1) {
                            //boutique can't access
                            $('body').trigger('route:navigate_replace', that.rules.defaultRoute);
                            return;
                        }
                    }

                    if (!that.businessModel.get('show_voucher_purchase') && name === 'voucherRoute') {
                        //can't access voucher route if purchasing is not enabled
                        $('body').trigger('route:navigate_replace', that.rules.defaultRoute);
                        return;
                    }

                    //if not logged in > logged in
                    if (!that.lastPageLoggedIn && that.userModel.get('logged_in')) {
                        analytics.login(that.businessModel, that.userModel);
                        that.lastPageLoggedIn = true;
                    } else if (that.lastPageLoggedIn && !that.userModel.get('logged_in')) {
                        analytics.logout();
                        that.lastPageLoggedIn = false;
                    }

                    analytics.trackDimension(1, String(that.businessModel.get('prices_enabled')));
                    if (!that.businessModel.is_chain()) {
                        analytics.trackDimension(2, that.businessModel.get('step_size'));
                        analytics.trackDimension(6, that.businessModel.get('deposit_type'));
                    }

                    //if current page <> new page, or if changing tab on the profile page
                    if (that.currentPageRoute !== name || name === 'myProfileRoute') {
                        //give each tab on my profile its own page name
                        var nextTab = args[0];
                        var pageName = (name === 'myProfileRoute') ? name + '_' + nextTab : name;

                        var trackUrl = that.businessModel.get('online_booking_address') + "/" + window.location.hash.split("?")[0].replace("#", "");

                        pageName = that.getGaPageName(pageName);

                        //don't do unnecessary stuff if you are only changing tab, not full route
                        if (name === 'myProfileRoute' && that.currentPageRoute === 'myProfileRoute') {
                            analytics.trackPageView(trackUrl, pageName);
                        } else {

                            if (name === 'serviceRoute') {
                                trackUrl = that.businessModel.get('online_booking_address') + "/services";
                            } else if (name === 'reset_password') {
                                trackUrl = that.businessModel.get('online_booking_address') + "/reset_password";
                            } else if (name === 'pending') {
                                trackUrl = that.businessModel.get('online_booking_address') + "/confirmPendingEmail";

                            } else if (name === 'login' && typeof args[0] !== 'undefined' && args[0] === 'fbsignup') {
                                pageName = 'Facebook user details';
                                trackUrl = that.businessModel.get('online_booking_address') + "/fbDetails";
                            }


                            //dont track pages that are set to false above
                            if (pageName) {
                                if (trackUrl.indexOf('verify/') !== -1) {
                                    //strip indentifing information.
                                    trackUrl = trackUrl.substr(0, trackUrl.indexOf('verify') + 6);
                                }
                                analytics.trackPageView(trackUrl, pageName);
                            }
                            that.lastPageRoute = that.currentPageRoute;
                            that.currentPageRoute = name;


                            //facebook related stuff
                            var showfbSignup = ($.cookie('kitomba.onlinebookings2.showFbSignup') === 'true');
                            var enableChangePassword = ($.cookie('kitomba.onlinebookings2.enableChangePassword') === 'true');
                            var signUpNoBooking = (that.userModel.get('signUpNoBooking'));
                            that.userModel.set('enableChangePassword', enableChangePassword);

                            //if we are authed with facebook but aren't logged in we need to send them to the facebook sign up page
                            if (showfbSignup && !that.userModel.get('logged_in')) {
                                $.cookie('kitomba.onlinebookings2.showFbSignup', 'false');

                                //if we are a chain then we need to get them to pick a branch before they can sign up with facebook
                                if (that.businessModel.is_chain()) {
                                    $('body').trigger('route:navigate_replace', '#branchListSignup');
                                } else {
                                    $('body').trigger('route:navigate_replace', '#login/fbsignup');
                                }
                                return;
                            }
                        }
                    }
                    callback.apply(that, args);
                }
            });
        });
    },

    getGaPageName: function (pageName) {
        var pageDetails = {
            notFound: "Page not found",
            defaultRoute: "Home",
            serviceRoute: "Services",
            voucherRoute: "Buy a voucher",
            voucherPurchasedRoute: "Voucher purchased",
            timeRoute: "Choose a time",
            locationRoute: "Location",
            login: "Login/signup wall",
            pending: false,
            confirmPending: false,
            reset_password: false,
            confirmUserEmail: false,
            confirmUserEmailStage: false,
            confirmUserMobile: "Confirm mobile",
            emailFallback: "Confirm email",
            confirmRoute: false,
            confirmPayment: false,
            branchListRoute: "Branch list",
            myProfileRoute_bookings: "My profile",
            myProfileRoute_details: "My details",
            myProfileRoute_photos: "My photos"
        };

        return pageDetails[pageName];
    },

    // default route when no route are set (landing page)
    defaultRoute: function () {
        if (this.businessModel.get('type') === 'branch') {
            this.navigate('services', {trigger: true, replace: true});
        } else {
            landingPageView = new LandingPageView({
                'businessModel': this.businessModel,
                'branchesModel': this.branchesModel,
                'userModel': this.userModel,
                'stateModel': this.stateModel
            });
            landingPageView.render();
        }
    },
    /**
     * get data and models for service page, those should cache data if people click back and forwards between tabs
     * without refreshing the page.
     */
    fetchServiceData: function () {
        if (!this.servicesCollection || !this.packagesCollection || !this.categoryModel || !this.servicePageView
            || !this.clientHistoryCollection) {

            this.servicesCollection = new ServicesCollection();
            this.packagesCollection = new PackagesCollection();
            this.categoryModel = new CategoryModel({id: config.getBusinessToken()});

            //this.staffCollection = this.staffCollection.fetch();
            this.servicesPromise = this.servicesCollection.fetch();
            this.packagesPromise = this.packagesCollection.fetch();
            this.categoryPromise = this.categoryModel.fetch();

            var options = {
                //  staffCollection: this.staffCollection,
                servicesCollection: this.servicesCollection,
                packagesCollection: this.packagesCollection,
                categoryModel: this.categoryModel,
                stateModel: this.stateModel
            };

            this.clientHistoryCollection = new ClientHistoryCollection(false, {userModel: this.userModel});
            this.clientHistoryCollectionPromise = this.clientHistoryCollection.fetch();


            this.servicesAll = new ServicesAll([], options);
            var servicesPopular = new ServicesPopular([], options);
            var servicesSelected = new ServicesSelected([], options);

            this.servicePageView = new ServicePageView({
                'servicesAll': this.servicesAll,
                'servicesPopular': servicesPopular,
                'servicesSelected': servicesSelected,
                'businessModel': this.businessModel,
                'branchesModel': this.branchesModel,
                'stateModel': this.stateModel,
                'userModel': this.userModel,
                'clientHistoryCollection': this.clientHistoryCollection
            });

            loading.until(this.packagesPromise, this.servicesPromise, this.categoryPromise, this.clientHistoryCollectionPromise, this.servicePageView);
        } else if (this.userModel.newBookingMade || this.clientHistoryCollection.checkCacheInvalid()) {
            this.clientHistoryCollectionPromise = this.clientHistoryCollection.fetch();
            loading.until(this.clientHistoryCollectionPromise);
            this.userModel.newBookingMade = false;
        }


    },

    /**
     * the service page, contains 4 tabs
     * - popular (default)
     * - selected (only shows if services selected)
     * - all (all services and packages by category)
     * - previous (if logged in and has booked previous services)
     *
     * @param tab
     */
    serviceRoute: function (tab) {
        this.fetchServiceData();
        var that = this;

        //4th item this.servicePageView is not a deffered object but is passed to access in scope.
        $.when(this.packagesPromise, this.servicesPromise, this.categoryPromise, this.clientHistoryCollectionPromise, this.servicePageView).done(
            function (packagesPromise, servicesPromise, categoryPromise, clientHistoryCollectionPromise, servicePageView) {
                servicePageView.servicesPopular.process();

                //because servicesAll contains all packages and services we are going to use it to update state from URL
                servicePageView.servicesAll.process();

                //this is also called when a service is selected/deselected
                servicePageView.servicesSelected.process();

                if (typeof that.clientHistoryCollection.process_ready === 'function') {
                    that.clientHistoryCollection.process_ready();
                }

                servicePageView.render(tab);
            });

        //third parameter to bind "this" scope
        this.servicePageView.off('service', this.stateModel.changeServices, this.stateModel);
        this.servicePageView.on('service', this.stateModel.changeServices, this.stateModel);
    },

    voucherRoute: function (extra) {
        if (extra === 'gatewayError') {
            analytics.trackGAEvent(true, 'KitombaPay Payment', 'Payment refused by card issuer', 'Voucher sale');
            new BookingErrorModal({
                title: 'Payment refused',
                message: 'Your card issuer has refused the payment. Please try again or enter another payment method.'
            });

            // clean error notice trigger from URL
            this.navigate('voucher', {trigger: false, replace: true});
        }

        var trackUrl = this.businessModel.get('online_booking_address') + "/voucher";
        analytics.trackPageView(trackUrl, 'Buy a voucher');

        new VoucherPageView({
            model: new VoucherSale(),
            businessModel: this.businessModel,
            userModel: this.userModel,
            stateModel: this.stateModel
        }).render();
    },

    //(liam): i just copied above. not sure about naming and wat is 'tab' for??
    voucherPurchasedRoute: function (purchaseHash) {
        var that = this;

        // ask for a voucher sale object factory method
        var paymentModel = PaymentFactory.getPaymentModel(this.businessModel);

        analytics.trackGAEvent(true, "Voucher", "Before payment finalize", "Gateway: " + paymentModel.driverName);

        var sale = paymentModel.createVoucherSale(purchaseHash);

        var finalize = loading.until(sale.finalize());
        //hide url parameters so the user can not come back, as it wont work
        this.navigate('', {trigger: false, replace: true});
        var view = new VoucherPurchasedPageView({
            model: sale,
            businessModel: this.businessModel,
            userModel: this.userModel
        });

        //I give up trying to understand this, leave this it's need for the sign up link
        this.getGlobalEventsView().businessModel = this.businessModel;
        view.render.bind(view);

        finalize
            .done(function (data) {
                if (typeof data.paymentGatewayError !== "undefined") {
                    analytics.trackGAEvent(true, 'Voucher', 'Payment failed', 'Gateway: ' + paymentModel.driverName);
                    handleVoucherError(data);
                    return;
                }
                analytics.trackGAEvent(true, 'Voucher', 'Payment taken', 'Gateway: ' + paymentModel.driverName);
                view.render();
            })
            .fail(function (jqXHR) {
                analytics.trackGAEvent(true, 'Voucher', 'Payment failed', 'Gateway: ' + paymentModel.driverName);
                if (jqXHR.status === 404) {
                    that.notFound();
                }

                if (typeof jqXHR.responseJSON === 'object' && typeof jqXHR.responseJSON.paymentGatewayError === 'string') {
                    jqXHR.errorHandled = true; //stops global error handler from firing, see main.js ctrl + f "jqXHR.errorHandled"

                    handleVoucherError(jqXHR.responseJSON);
                }
            });

        function handleVoucherError(data) {
            var callback = function () {
                $('body').trigger('route:navigate_replace', 'voucher');
            };
            if (data.paymentGatewayError !== 'CANCELLED') {
                new BookingErrorModal({
                    title: "Payment Error",
                    subTitle: "We could not complete the transaction",
                    message: typeof data.showPaymentGatewayError === "undefined" ? "false" : data.paymentGatewayError,
                    closeCallback: callback
                });
            } else {
                callback();
            }
        }
    },

    timeRedo: function () {
        this.timeRedoError = true;
        this.timeRoute();
    },

    timeGoodRoute: function () {
        this.timeRedoError = false;
        this.timeRoute();
    },

    timeRoute: function () {
        var that = this;
        if (!this.timePageView) {
            //reuse the all_services model from service view, this should usually be already loaded.
            this.fetchServiceData();

            if (!this.staffPromise) {
                this.staffCollection = new StaffCollection();
                this.staffPromise = this.staffCollection.fetch();
            }
            this.timePageView = new TimePageView({
                businessModel: this.businessModel,
                branchesModel: this.branchesModel,
                stateModel: this.stateModel,
                servicesAll: this.servicesAll,
                staffCollection: this.staffCollection,
                packagesCollection: this.packagesCollection,
                noBookingAvailable: this.noBookingAvailable,
                timeRedoError: this.timeRedoError
            });

            $.when(this.packagesPromise, this.servicesPromise, this.categoryPromise, this.staffPromise, this.timePageView)
                .done(
                    function (packagesPromise, servicesPromise, categoryPromise, staffPromise, timePageView) {
                        var bookingModel = new BookingModel();

                        if (bookingModel.hasZeroDuration(that.stateModel.get('services'), that.servicesCollection, that.packagesCollection)) {
                            that.userModel.zeroDurationBooking = true;
                            that.timePageView = false; //reset time page since we haven't gone there yet
                            $('body').trigger('route:navigate_replace', 'services');
                            return;
                        }

                        //because servicesAll contains all packages and services we are going to use it to update state from URL
                        timePageView.servicesAll.process();

                        //when switching back and forwards between pages need to clear this
                        timePageView.availabilityCollection.reset();

                        timePageView.staffCollection.add([
                            {
                                firstName: "(anyone)",
                                lastName: "",
                                formattedName: "(anyone)",
                                name: "(anyone)",
                                id: "0"
                            }
                        ]);

                        timePageView.staffCollection.add([
                            {
                                firstName: "Please",
                                lastName: "Select",
                                formattedName: "Please select",
                                name: "Please select",
                                id: "-1"
                            }
                        ]);

                        timePageView.render();
                        that.noBookingAvailable = false;

                    });

        } else {
            that.timePageView.timeRedoError = that.timeRedoError;
            $.when(this.servicesPromise, this.categoryPromise, this.packagesPromise, this.staffPromise, this.timePageView)
                .done(function () {
                    var bookingModel = new BookingModel();

                    if (bookingModel.hasZeroDuration(that.stateModel.get('services'), that.servicesCollection, that.packagesCollection)) {
                        that.userModel.zeroDurationBooking = true;
                        $('body').trigger('route:navigate_replace', 'services');
                        return;
                    }

                    that.timePageView.render();
                });
        }

        this.timePageView.off(null, null, this.stateModel); //should remove all event callbacks that have stateModel context
        this.timePageView.on('time', this.stateModel.changeTime, this.stateModel);
        this.timePageView.on('service:update_staff', this.stateModel.changeStaff, this.stateModel);
        this.timePageView.on('service:update_position', this.stateModel.reorderServices, this.stateModel);
    },

    locationRoute: function () {

    },

    login: function (action, method) {
        var that = this,
            $body = $('body');

        if (this.userModel.get('logged_in')) {
            if (this.stateModel.fetch().get('time')) {
                $body.trigger('route:navigate_replace', 'confirm');
                return;
            }

            $body.trigger('route:navigate_replace', 'landing');
            return;
        }

        function fetchFromFacebook() {
            that.userModel.fetchFromFacebook(function (err) {
                if (err) {
                    throw err;
                } else {

                    analytics.trackGAEvent(false, 'Account', 'Signed up with Facebook');
                    that.getSignupView().renderFacebook();
                }
            });
        }

        if (action === 'fbsignup') {
            //get the data from facebook
            if (that.stateModel.get('facebookActive')) {
                //if its already active then just go ahead
                fetchFromFacebook();
            } else {
                //if its not active we need to wait
                this.stateModel.once('change:facebookActive', function () { //we need to wait for FB javascript to load
                    if (that.stateModel.get('facebookActive')) {
                        //only want to do this when facebookActive has changed to true
                        fetchFromFacebook();
                    }
                });
            }


        } else {
            this.getSignupView().render(null, action, method);
        }
    },

    confirmPending: function (code, newEmail, oldEmail, stage1, stage2, stage3) {
        if (stage2) {
            stage1 = stage1 + "/" + stage2;
            if (stage3) {
                stage1 = stage1 + "/" + stage3;
            }
        }
        this.userModel.confirmPendingEmail(code, newEmail, oldEmail, stage1);
    },

    reset_password: function (code, email, stage) {
        this.resetPasswordView = new ResetPassword({
            businessModel: this.businessModel,
            userModel: this.userModel,
            resetInfo: {
                code: code,
                email: email,
                stage: stage
            }
        });
        this.resetPasswordView.render();
    },

    confirmUserEmail: function (id, email) {
        this.userModel.set({code: id, email: email});
        analytics.trackGAEvent(false, 'Account', 'Verified by email');
        this.userModel.confirmUser(id, email, '', false, 'email');
    },

    confirmUserEmailStage: function (id, email, stage) {
        var that = this;
        this.userModel.set({code: id, email: email});
        analytics.trackGAEvent(false, 'Account', 'Verified by email');
        var requestedBookingIsUndefined = $.cookie('kitomba.onlinebookings2.requestedBookingSlot') === undefined;
        this.stateModel.fetch(stage);
        if (requestedBookingIsUndefined && stage.indexOf('redirect') === -1) {
            var dateTime = that.stateModel.get('time');
            this.availabilityCollection = new AvailabilityCollection(null, {stateModel: this.stateModel});
            this.availabilityCollection.setMonth(moment(dateTime).format('YYYY-MM-DD'));
            this.availabilityCollection.fetch().done(function () {
                var dayPromise;
                var dayModel = that.availabilityCollection.get(moment(dateTime).format('YYYY-MM-DD'));
                if (dayModel.get('slots').length === 0) {
                    dayPromise = dayModel.fetch();
                } else {
                    dayPromise = dayModel;
                }

                $.when(dayPromise).done(function () {
                    var formattedTime = dateTime + ":00";
                    var isBookingAvailable = dayModel.slotExists(formattedTime);
                    if (isBookingAvailable) {
                        var slot = dayModel.getSlotDetails(formattedTime);
                        that.stateModel.setRequestedBookingSlot(slot);
                        that.userModel.confirmUser(id, email, stage, false, 'email');

                    } else {
                        that.noBookingAvailable = true;
                        that.userModel.confirmUser(id, email, stage, true, 'email');

                    }
                });

            }).fail(function () {
                that.userModel.servicesUnavailable = true;
                that.userModel.confirmUser(id, email, false, true, 'email', false);
            });
        } else {
            this.userModel.confirmUser(id, email, stage, false, 'email', false);
        }
    },

    confirmUserMobile: function () {
        var userEmail = $.cookie('userEmail'),
            userPhone = $.cookie('userPhone');

        if (userEmail === undefined || userPhone === undefined) {
            $('body').trigger('route:navigate_replace', 'login/');
            return;
        }
        if (this.confirmView == null) {
            this.confirmView = new ConfirmView({
                model: new UserModel({email: userEmail, phone: userPhone, businessModel: this.businessModel}),
                businessModel: this.businessModel
            });
        } else {
            this.confirmView.model.set('email', userEmail);
            this.confirmView.model.set('phone', userPhone);
            this.confirmView.model.incorrectCodeCount = 0;
        }
        this.confirmView.render();
    },

    emailFallback: function (noResend) {
        var sendEmail = noResend !== 'noResend';
        var userEmail = $.cookie('userEmail'),
            userPhone = $.cookie('userPhone'),
            noBooking = this.userModel.get('signUpNoBooking');

        if (userEmail === undefined || userPhone === undefined) {
            $('body').trigger('route:navigate_replace', 'login/');
            return;
        }
        if (this.confirmEmailView == null) {
            this.confirmEmailView = new ConfirmEmailView(
                {
                    model: new UserModel({
                        email: userEmail,
                        businessModel: this.businessModel,
                        signUpNoBooking: noBooking
                    }),
                    businessModel: this.businessModel, sendEmail: sendEmail
                });
        } else {
            this.confirmEmailView.model.set('email', userEmail);
            this.confirmEmailView.model.set('signUpNoBooking', noBooking);
        }
        this.confirmEmailView.render();
    },

    fetchConfirmData: function (bookingModel) {
        if (!this.servicesCollection) {
            this.servicesCollection = new ServicesCollection();
            this.servicesPromise = this.servicesCollection.fetch();
        }

        if (!this.staffCollection) {
            this.staffCollection = new StaffCollection();
            this.staffPromise = this.staffCollection.fetch();
        }

        if (!this.packagesCollection) {
            this.packagesCollection = new PackagesCollection();
            this.packagesPromise = this.packagesCollection.fetch();
        }

        if (!this.userModel.get('attachments_photo')) {
            //if we are missing attachments photo
            this.attachmentPromise = this.userModel.fetchPhotos();
        }

        if (typeof bookingModel != 'undefined') {
            this.priceCheckPromise = bookingModel.priceCheck();
        } else {
            this.priceCheckPromise = true;
        }

        return $.when(this.servicesPromise, this.staffPromise, this.packagesPromise, this.attachmentPromise, this.priceCheckPromise);
        //return $.when(this.servicesPromise, this.staffPromise, this.packagesPromise, photo_promise);
    },

    cancelPayment: function (extra) {
        this.stateModel.loadRequestedServices();

        if (extra === 'gatewayError') {
            this.stateModel.setPaymentRefused(true);
        }

        $("body").trigger("route:navigate_replace", 'confirm');
    },

    confirmPayment: function (customerKid, apptHash, reschedule) {
        var that = this;

        if (reschedule === undefined) {
            reschedule = false;
        }

        var paymentModel = PaymentFactory.getPaymentModel(this.businessModel);
        analytics.trackGAEvent(true, "Bookings", "Before deposit finalize", "Gateway: " + paymentModel.driverName);

        loading.until(that.fetchConfirmData()).done(function () {
            var options = {
                customer_kid: customerKid,
                appt_hash: apptHash,
                userModel: that.userModel,
                servicesCollection: that.servicesCollection,
                staffCollection: that.staffCollection,
                businessModel: that.businessModel,
                branchesModel: that.branchesModel,
                packagesCollection: that.packagesCollection,
                stateModel: that.stateModel,
                reschedule: reschedule,
            };

            if (that.confirmPaymentView === undefined) {
                that.confirmPaymentView = new ConfirmPaymentView(options);
            } else {
                that.confirmPaymentView.initialize(options);
            }

            that.confirmPaymentView.render();
        });
    },

    confirmPaymentPause: function (confirmPath) {
        var pauseView = new ConfirmPaymentPauseView({
            confirmUrl: "#confirmPayment/" + confirmPath,
            activityName: 'Booking',
        });

        pauseView.render();
    },

    voucherPurchasedPauseRoute: function (confirmPath) {
        var pauseView = new ConfirmPaymentPauseView({
            confirmUrl: "#voucherPurchased/" + confirmPath,
            activityName: 'Purchase',
        });

        pauseView.render();
    },

    userAllowedToBeInOnlineBookings: function () {
        //user can not get here if they are not logged in
        if (!this.userModel.get('logged_in')) {
            $('body').trigger('route:navigate_replace', 'login');
            return false;
        }

        if (this.stateModel.get('paypal_button_exists') == true) {
            window.location.reload(); //Paypal button can only be rendered once.
            return false;
        }

        if (this.userModel.get('termsAcceptRequired')) {
            //Also, if they need to accept terms too...
            var hashUrl = window.location.hash,
                queryIndex = hashUrl.indexOf('?');
            if (queryIndex !== -1) {
                hashUrl = hashUrl.substr(0, queryIndex);
            }
            if (hashUrl.trim() === '') {
                hashUrl = '#landing';
            }
            hashUrl = '#acceptTerms/' + encodeURIComponent(hashUrl);
            $('body').trigger('route:navigate_replace', hashUrl);
            return false;
        }

        return true;
    },

    confirmRoute: function () {
        var that = this;

        if (!this.userAllowedToBeInOnlineBookings()) {
            return;
        }

        if (this.stateModel.isPaymentRefused()) {
            analytics.trackGAEvent(true, 'KitombaPay Payment', 'Payment refused by card issuer', 'Booking deposit');
            new BookingErrorModal({
                title: 'Payment refused',
                message: 'Your card issuer has refused the payment. Please try again or enter another payment method.'
            });

            this.stateModel.setPaymentRefused(false);
        }

        var bookingModel = new BookingModel({
            customerId: this.userModel.id,
            allowDoubleBooking: false,
            suppressEmail: false,
            services: this.stateModel.getRequestedBookingSlot()[0],
            stateModel: that.stateModel
        });

        //crude check to make sure we have some services selected, else go back to the services page
        if (!bookingModel.get('services') || bookingModel.get('services').length === 0) {
            $('body').trigger('route:navigate_replace', 'services');
            return;
        }

        function showView(bookingExists, futureAppts) {
            loading.until(that.fetchConfirmData(bookingModel)).done(function () {

                if (bookingModel.hasZeroDuration(that.stateModel.get('services'), that.servicesCollection, that.packagesCollection)) {
                    that.userModel.zeroDurationBooking = true;
                    $('body').trigger('route:navigate_replace', 'services');
                    return;
                }

                var options = {
                    model: bookingModel,
                    bookingExists: bookingExists,
                    userModel: that.userModel,
                    servicesCollection: that.servicesCollection,
                    staffCollection: that.staffCollection,
                    businessModel: that.businessModel,
                    branchesModel: that.branchesModel,
                    packagesCollection: that.packagesCollection,
                    stateModel: that.stateModel,
                    futureAppts: futureAppts
                };

                if (that.confirmPage === undefined) {
                    that.confirmPage = new ConfirmPageView(options);
                } else {
                    that.confirmPage.initialize(options);
                }

                //backup state, so that we can restore if confirmation fails
                that.stateModel.storeRequestedServices();

                var trackUrl = that.businessModel.get('online_booking_address') + "/confirmBooking";
                analytics.trackPageView(trackUrl, 'Please confirm booking');
                that.confirmPage.checkIfPaymentRequiredThenRender();
            });
        }

        //before loading the data, we need to see if this service for this time has been taken
        //super crude way of checking if the user has already booked this appointment by getting the future appointments
        loading.until(this.userModel.fetchFutureAppointments(10, config.getBusinessToken())).done(function (futureAppts) { //hopefully 10 future appointments is enough
            var timeFormatted = moment(bookingModel.get('services')[0].startDate).format('YYYY-MM-DD HH:mm:ss');
            var bookingExists = _.some(futureAppts, function (appt) {
                return appt.startDate === timeFormatted && appt.bookingId !== that.stateModel.get('reschedule_flag');
            });

            showView(bookingExists, futureAppts);
        });
    },

    errorRoute: function () {
        if (!this.userAllowedToBeInOnlineBookings()) {
            return;
        }

        var errorTitle = this.stateModel.getUrlParameter('title');
        var errorMessage = this.stateModel.getUrlParameter('message');
        var errorSubTitle = this.stateModel.getUrlParameter('subTitle');
        var newPage = this.stateModel.getUrlParameter('targetPage');
        var subMessage = this.stateModel.getUrlParameter('subMessage');

        if (errorTitle !== null && errorMessage !== null && newPage !== null) {
            $('body').trigger('route:navigate_replace', newPage);
            new BookingErrorModal({
                title: decodeURIComponent(errorTitle),
                subTitle: errorSubTitle === null ? undefined : decodeURIComponent(errorSubTitle),
                message: decodeURIComponent(errorMessage),
                subMessage: subMessage === null ? undefined : decodeURIComponent(subMessage),
            });
            return;
        }

        $('body').trigger('route:navigate_replace', '');
    },

    branchListRoute: function () {
        if (this.userModel != null && this.userModel.get('logged_in') !== true) {
            $('body').trigger('route:navigate', '');
            return;
        }

        this.getBranchListView().render('services');
    },

    branchListSignupRoute: function () {
        this.getBranchListView().render('login/signup', true);
    },

    branchListSignupAnywhereRoute: function () {
        this.getBranchListView().render('login/signup', true);
    },

    myProfileRoute: function (tab) {
        if (this.userModel !== null && this.userModel.get('logged_in') !== true) {
            $('body').trigger('route:navigate', '');
            return;
        }
        loading.showLoadingScreen();

        var that = this;
        var doFetch = true;
        var doTabRender = true;

        if (this.lastPageRoute !== false
            && this.lastPageRoute.indexOf("myProfile") === 0
            && this.clientView !== undefined) {

            //switching tabs on myProfile page - don't refresh data/view
            doFetch = false;
        } else {
            this.clientView = new ClientPageView({
                'businessModel': that.businessModel,
                'branchesModel': that.branchesModel,
                'userModel': that.userModel,
                'stateModel': that.stateModel
            });
        }

        this.clientView.render(tab);

        if (tab === 'bookings') {
            if (doFetch
                || (!this.userModel.get('futureAppointments')
                    && !this.userModel.get('pastAppointments'))) {
                //only fetch if first time to this tab

                doTabRender = false;

                $('#bookings .tabContent').hide();
                $('#bookings .tabLoading').show();
                //reset userModel 'cache' so we can get the new appointments
                this.userModel.set('futureAppointments', false);
                this.userModel.set('pastAppointments', false);

                var btoken = this.userModel.get("business_id");

                var futureApptPromise = this.userModel.fetchFutureAppointments(10, btoken),
                    pastApptPromise = this.userModel.fetchPastAppointments(10, btoken);

                loading.until(futureApptPromise, pastApptPromise).done(function (futureAppts, pastAppts) {
                    that.clientView.renderTab(tab);
                    $('#bookings .tabContent').show();
                    $('#bookings .tabLoading').hide();
                    loading.hideLoadingScreen();
                });
            } else {
                this.clientView.renderTab(tab);
            }
        } else if (tab === 'photos') {
            if (doFetch || this.userModel.get('attachments_photo') === undefined
                || this.userModel.get('attachments_photo').length === 0) {
                //only fetch if first time to this tab

                doTabRender = false;

                $('#photos .tabContent').hide();
                $('#photos .tabLoading').show();
                var attachmentsPromise = this.userModel.fetchPhotos();
                loading.until(attachmentsPromise).done(function (attachmentsXHR) {
                    that.clientView.renderTab(tab);
                    $('#photos .tabContent').show();
                    $('#photos .tabLoading').hide();
                    loading.hideLoadingScreen();
                });
            }
        } else {
            //On the off chance this went to a different page
            loading.hideLoadingScreen();
        }

        if (doTabRender) {
            this.clientView.renderTab(tab);
        }
    },


    acceptTerms: function (prevPage) {
        var acceptTC = new AcceptNewTermsView({userModel: this.userModel, prevPage: prevPage});
        acceptTC.render();
    },

    getPreloadAttribute: function (attributeName) {
        var businessData = $('body').data('business-preload');
        if (businessData) {
            return businessData[attributeName];
        }
        return undefined;
    },

    isLandingPage: function () {
        return window.location.hash === "";
    },

    handleFacebookCallback: function (path) {
        var that = this;

        //remove junky ?# from url that was added by facebook
        history.replaceState(null, null, window.location.pathname);


        var retryLogin = function () {
            $.cookie('kitomba.onlinebookings2.fblogin.retry', "true");
            that.getGlobalEventsView().startFacebookLogin();
        };

        // have the sdk query login status.  if we've been redirected back from FB it should pick it up
        // e.g. from cookie or local storage
        // if not, assume it's the manual login implementation triggered by shouldUseFacebookRedirect
        // and try to progress that
        FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                // sdk says we are connected - continue with login flow, i.e. fblogin.php xhr
                $.cookie('kitomba.onlinebookings2.fblogin.retry', "false");
                that.getGlobalEventsView().resumeFacebookLogin();
            } else {
                var isRetry = $.cookie('kitomba.onlinebookings2.fblogin.retry');
                if (isRetry === "true") {
                    $.cookie('kitomba.onlinebookings2.fblogin.retry', "false");
                    console.log('Third party cookies disabled, and no facebook session information in callback');
                    $('#fbErrorCode').text('ERR_REJOIN_NO_PATH');
                    $('#fbError').modal('show');
                    return;
                } else {
                    if (that.stateModel.get('facebookActive')) {
                        //if its already active then just go ahead
                        retryLogin();
                    } else {
                        //if its not active we need to wait
                        that.stateModel.once('change:facebookActive', function () { //we need to wait for FB javascript to load
                            if (that.stateModel.get('facebookActive')) {
                                //only want to do this when facebookActive has changed to true
                                retryLogin();
                            }
                        });
                    }
                }
            }
        });
    },

    handleFacebookRedirect: function (accessToken, state) {
        var that = this;

        //avoid infinite recursion if page gets reloaded later in the flow
        history.replaceState(null, null, window.location.pathname);

        //try to login without facebook cookie
        that.userModel.facebookToken = accessToken;
        that.userModel.facebookState = state;

        var path = 'access_token=' + accessToken + '&state=' + state,
            loginType = 'session';

        $.when(that.userModel.fbLoginWithoutCookie(path, loginType)).done(function (data) {
            $.cookie('kitomba.onlinebookings2.showFbSignup', 'false');
            data = JSON.parse(data);
            that.getGlobalEventsView()._handleLoginResult(data, data.customer.clean_email);
        }).fail(function (response) {
            response.errorHandled = true;

            if (response.status === 401) {
                //facebook login validated but no matching client record
                //try to continue signup without facebook cookie
                //access_token and signed_request are needed later in the signup flow to confirm the client record (see api/customer/facebook_confirm)
                var data = JSON.parse(response.responseText);
                that.userModel.set(data);

                analytics.trackGAEvent(false, 'Account', 'Signed up with Facebook');
                if (that.businessModel.is_chain()) {
                    var nextLocation = 'facebookRedirect/' + that.userModel.facebookToken + "/" + that.userModel.facebookState;
                    that.getBranchListView().render(nextLocation, true);
                } else {
                    that.getSignupView().renderFacebook();
                }

            } else {
                $('#fbErrorCode').text('ERR_FBLOGIN_PHP_FAIL');
                $('#fbError').modal('show');
            }
        });
    },

    getGlobalEventsView: function () {
        if (this.globalEventsView === null) {

            //need to create the global events view only once to enable events that are on every page
            this.globalEventsView = new GlobalEventsView({
                el: 'body',
                stateModel: this.stateModel,
                userModel: this.userModel,
                businessModel: this.businessModel
            });

        }
        return this.globalEventsView;
    },

    getSignupView: function () {
        if (this.signupView == null) {
            this.signupView = new SignUpPageView(
                {
                    'businessModel': this.businessModel,
                    'branchesModel': this.branchesModel,
                    'userModel': this.userModel
                });
        }
        return this.signupView;
    },

    getBranchListView: function () {
        if (this.branchListView == null) {
            this.branchListView = new BranchListPageView({
                'businessModel': this.businessModel,
                'branchesModel': this.branchesModel,
                'userModel': this.userModel
            });
        }
        return this.branchListView;
    },

    getPathVariable: function (paramName, pathString) {
        var urlSearchParams = new URLSearchParams(pathString);

        return urlSearchParams.get(paramName);
    },

    makePaymentRoute: function () {
        $('body').addClass('withKitombaPay');

        if ($('.paymentArea').is(':visible')) {
            //noop to support back button
        } else if ($('.voucherArea').is(':visible')) {
            $('body').trigger('route:replace_only', '#voucher');
        } else if ($('.bookingConfirmationArea').is(':visible')) {
            this.stateModel.fetch(window.location.hash);
            $('body').trigger('route:replace_only', '#confirm');
        } else {
            history.replaceState(null, null, window.location.pathname);
            $('body').trigger('route:navigate_reload');
        }
    }
});
