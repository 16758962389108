/**
 * For chains this should hold a list of branches
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    Branch = require('./../models/branchModel'),
    config = require('./../../js/models/configModel');

Backbone.$ = $;

module.exports = Backbone.Collection.extend({
    model: Branch,
    comparator: "name",

    url: function () {
        return config.getAPIUrl() + "business/" + config.getBusinessToken() + "/branches";
    },
    initialize: function (model) {

    }

});

