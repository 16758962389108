/**
 * Merge Customers view
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    template = Handlebars.compile(require('./../../templates/pageMerging.hbs').default),
    Backbone = require('backbone'),
    BaseView = require('./baseView'),
    analytics = require('./../helpers/analytics'),
    UserModel = require('./../models/userModel.js'),
    config = require('./../models/configModel');



Backbone.$ = $;
window.jQuery = $;
module.exports = BaseView.extend({
    page: 'Merge',
    pageTitle: 'Merge',
    template: template,
    events: function () {
        //merge events from the parent class with events set in this local variable
        var events = {
            "click .userSuggestion.option": 'clickOption',
            "click #mergeSubmit": "clickMergeSubmit",
            "click #mergeSkip": "clickMergeSkip"
        };

        return _.extend(events, BaseView.prototype.events);
    },
    initialize: function (options) {
        //call parent constructor
//        this.business_model = options.business_model;
        this.userModel = options.userModel;
        this.email = options.email;
        this.code = options.code;
        this.mergeCandidates = options.mergeCandidates;
        this.stage = options.stage;
        BaseView.prototype.initialize(options);
    },
    render: function () {
        this.$el.html(this.template({mergeCandidates: this.mergeCandidates, one_result: this.mergeCandidates.length === 1}));
        BaseView.prototype.render(this);
        this.setPageTitle(this.pageTitle);
    },
    clickOption: function(e) {
        e.preventDefault();
        var $btn = $(e.target);
        if(!$btn.hasClass('btn')) {
            $btn = $btn.closest('.btn');
        }
        if ($btn.hasClass('selected')) {
            $btn.removeClass('selected');

        } else {
            $btn.addClass('selected');
            $('.userSuggestion.option').not($btn).removeClass('selected');
        }

        if ($('.userSuggestion.selected').length === 1) {
            $('#mergeSubmit').addClass('btn-success').removeClass('disabled btn-default');
        } else {
            $('#mergeSubmit').removeClass('btn-success').addClass('disabled btn-default');
        }
    },
    clickMergeSubmit: function(e) {
        e.preventDefault();
        var $suggestion = $('.userSuggestion.singleton');
        if($suggestion.length === 0) {
            $suggestion = $('.userSuggestion.option.selected');
        }
        if($('#mergeSubmit').hasClass('disabled')
            || $suggestion.size() !== 1
            || isNaN($suggestion.data('id'))) {
            return;
        }

        analytics.trackGAEvent(false,'Account','Customer duplicate suggestion merged');

        var choice = $suggestion.data('id');
        this.userModel.confirmMergeUser(this.code, this.email, choice, this.stage);
    },
    clickMergeSkip: function(e) {
        e.preventDefault();
        analytics.trackGAEvent(false,'Account','Customer duplicate suggestion skipped');
        this.userModel.confirmMergeUser(this.code, this.email, 'none', this.stage);
    }
});
