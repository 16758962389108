/**
 * Voucher Purchased page.
 *
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    BaseView = require('./baseView'),
    loading = require('./../helpers/loadingScreen');

Backbone.$ = $;

require('jquery-ui');
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

module.exports = BaseView.extend({
    page: 'voucher-purchased',
    pageTitle: 'Voucher purchased',
    lastSelectedTab: '',
    servicesPopular: {},
    servicesAll: {},
    servicesSelected: {},
    template: Handlebars.compile(require('./../../templates/voucherPurchased.hbs').default),

    events: function (){
        return {
            'click .printVoucher': 'showPrintView'
        };
    },

    render: function () {
        var templateData = this.getTemplateVars();
        analytics.trackGAEvent(true, 'Voucher', 'voucher purchase complete', 'Sale Amount', parseInt(templateData.voucherSale.value));
        this.setPageTitle(this.pageTitle);

        this.$el.html(this.template(templateData));
        BaseView.prototype.render(this);
    },

    getTemplateVars: function(){
        return {
            showLoginButton: true,
            voucherSale: this.model.toJSON(),
            fullRecipientName: this.model.getFullRecipientName(),
            business: this.businessModel.toJSON(),
            userModel: this.userModel.toJSON()
        };
    },

    showPrintView: function(e) {
        e.preventDefault(); //stops removal of url hash
        analytics.trackGAEvent(true, 'Voucher', 'voucher printed');
        window.print();
    }

});
