/**
 * Confirm page view
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    loading = require('./../helpers/loadingScreen'),
    BaseView = require('./baseView'),
    BookingModel = require('./../models/bookingModel'),
    UrlHelper = require('./../helpers/urlHelpers'),
    PhotoEventsView = require('./../views/photoEventsView'),
    BookingErrorModal = require('../models/BookingErrorModal');
var PaymentFactory = require('../models/paymentFactory');

var moment = require('moment');

Backbone.$ = $;

var confirmTemplate = Handlebars.compile(require('./../../templates/pageConfirm.hbs').default);
var confirmedTemplate = Handlebars.compile(require('./../../templates/pageConfirmed.hbs').default);

/**
 *
 * @type {*}
 * @property {BookingModel} model
 */
module.exports = BaseView.extend({
    page: 'preconfirm',
    pageTitle: 'Please confirm',
    pageTitleBooked: 'You\'re booked!',
    photoEventsView: null,
    template: confirmTemplate,
    confirmedTemplate: confirmedTemplate,
    paymentDriver: null,

    events: {
        'click #confirm': 'confirmBookingAction',
        'change .photoArea input:file' : 'handlePhotoUpload',
        'dragenter .fileDragZone': 'handleDragEnter',
        'dragover .fileDragZone': 'handleDragOver', //just to prevent default behaviour
        'dragleave .fileDragZone': 'handleDragLeave',
        'drop .fileDragZone': 'handlePhotoDrop',
        'click .pswp__button--delete': 'handlePhotoDelete',
        'focusout #comment' : 'setNotesFromDom',
        'click .payButton': 'handlePayButtonClick'
        //'click #reloadPostPayPal': 'handleReloadPage',
        //'click input[name*="lightbox_container"]': 'handleReloadPage'
    },

    initialize: function(options) {
        BaseView.prototype.initialize(options); //call parent constructor
        _.bindAll(this, 'confirmBookingPromise');
        this.model = options.model; //shouldn't have to do this with backbone, but its not working normally.
        this.businessModel = options.businessModel;
        this.bookingExists = options.bookingExists;
        this.servicesCollection = options.servicesCollection;
        this.staffCollection = options.staffCollection;
        this.packagesCollection = options.packagesCollection;
        this.futureAppts = options.futureAppts;
        this.userModel = options.userModel;
        this.stateModel = options.stateModel;
        this.photoEventsView = new PhotoEventsView({userModel: this.userModel, stateModel: this.stateModel});
    },

    checkIfPaymentRequiredThenRender: function() {
        var that = this;
        if(this.model.get('paymentRequired')) {
            var reschedule = typeof this.stateModel.get('reschedule_flag') !== 'undefined';
            analytics.trackGAEvent(true, "Bookings","confirm booking with deposit");
            loading.until(this.model.setupAppointmentDeposit(reschedule)).done(renderWithButton);
        }
        else {
            this.render();
        }

        function renderWithButton(res){
            if(res.success === true){
                that.paymentDriver = PaymentFactory.getPaymentModel(that.businessModel);
                that.render();
                var button = $("<a class='payButton mBtm btn-block btn btn-success btn-lg mTop'>Pay with Credit Card</a>");
                $("#confirmPageForm").append(button);
                $(".payment_options").attr('src', that.paymentDriver.getPaymentOptionIcons());
                that.paymentDriver.setupDepositButton(res, that, that.handlePayButtonClick.bind(that), button);
            }
            else {
                renderWithoutButton();
            }
        }

        function renderWithoutButton(){
            //set up failed fall back to classic flow
            that.model.set('paymentRequired', false);
            that.render();
        }
    },

    render: function() {
        var templateData = this.getTemplateData();

        templateData.appointmentDisplayDate = moment(this.model.getFirstServiceStartDate()).format('dddd D MMMM [at] h:mma'); //Friday 3 July at 10:30am
        templateData.fullPrice = Number(this.model.get('fullPrice')).toFixed(2);
        templateData.deposit = Number(this.model.get('deposit')).toFixed(2);
        templateData.fullPayment = templateData.fullPrice === templateData.deposit;
        templateData.currencySymbol = this.model.get('currencySymbol');
        templateData.ob_payment_required = this.model.get('paymentRequired');
        this.$el.html(this.template(templateData));
        BaseView.prototype.render(this);

        this.photoEventsView.hideFileInputIfNotSupported(true);

        this.photoEventsView.initPhotoSwipeFromDOM(this.$el.find(".my-gallery").first());
        this.setPageTitle(this.pageTitle);

        if(typeof this.stateModel.get('paymentGatewayError') != 'undefined'){
            new BookingErrorModal({
                title: "Payment Error",
                errorContactBlurb: 'Please contact us directly.',
                message: this.stateModel.get('paymentGatewayError'),
                subMessage: this.stateModel.get('paymentGatewaySubError'),
                code: this.stateModel.get('paymentGatewayErrorCode'),
            });
            this.stateModel.set('paymentGatewayError',undefined);
        }
    },

    handlePayButtonClick: function(){
        analytics.trackGAEvent(true, 'Bookings', 'booking deposit started');
        this.paymentDriver.payDeposit(this.model);
    },

    setNotesFromDom: function() {
        var notes = this.$el.find('#comment').val();
        notes = (_.isString(notes)) ? notes.trim() : "";
        this.model.set('notes', notes);
    },

    renderConfirmed: function(){
        var templateData = this.getTemplateData();

        templateData.appointmentDisplayDate = moment(this.model.getFirstServiceStartDate()).format('h:mma [on] dddd D MMMM'); //4:00pm on Monday 12 April

        this.$el.html(this.confirmedTemplate(templateData));
        BaseView.prototype.render(this);

        this.setPageTitle(this.pageTitleBooked);
    },

    getServiceListText: function(){
        var that = this;
        var servicesText = "";

        var serviceData = this.model.get('services');

        _.each(serviceData, function(service, index){
            var foundService = that.servicesCollection.get(service.serviceId);
            var foundStaff = that.staffCollection.get(service.staffId);

            if(serviceData.length > 1 && index === serviceData.length - 1){ //last element and more than one element
                servicesText += " and ";
            } else if (index !== 0) { //not first element
                servicesText += ", ";
            }
            if(!foundStaff || (foundStaff.get('firstName') === '' && foundStaff.get('lastName') === '') ){
                //invalid staff data so just list the service
                servicesText += foundService.get('name');
            } else {
                servicesText += foundService.get('name') + ' with ' + foundStaff.get('firstName') + ' ' + foundStaff.get('lastName');
            }

        });

        return servicesText;
    },

    getServiceListTextAsArray: function(){
        var that = this;
        var serviceData = this.model.get('services');
        var formattedServices = [];

        _.each(serviceData, function(service, index){
            var packageData;
            var foundService = that.servicesCollection ? that.servicesCollection.get(service.serviceId) : false;
            var foundStaff = that.staffCollection ? that.staffCollection.get(service.staffId) : false;
            var staffName = "", serviceName = "";

            if (!foundStaff || (foundStaff.get('firstName') === '' && foundStaff.get('lastName') === '')) {
                staffName = "";
            }
            else {
                staffName = foundStaff.get('firstName');
            }

            if (!foundService) {
                if (that.packagesCollection) {
                    serviceName = that.packagesCollection.findServiceName(service.serviceId);
                }
            }
            else {
                serviceName = foundService.get('name');
            }

            if (service.packageItemId === undefined) {
                //not part of package
                var data = {
                    'type': "service",
                    'name':serviceName,
                    'staff':staffName
                };
                formattedServices.push(data);
            }
            else {
                //part of a package
                var packageName = that.packagesCollection.findPackageName(service.packageItemId);
                var packageModel = that.packagesCollection.getPackageByName(packageName);
                index = _.findIndex(formattedServices, {'name': packageName});
                if (index === -1) {
                    //didn't find package
                    packageData = {
                        'type': "package",
                        'name': packageName,
                        'productList': _.map(packageModel.attributes.products, function(product, key){
                            return product.name;
                        }),
                        'serviceList': [
                            {
                                'serviceName': serviceName,
                                'packageItemId': service.packageItemId,
                                'staff': staffName
                            }
                        ]
                    };
                    formattedServices.push(packageData);
                }
                else {
                    //found package
                    packageData = formattedServices[index];
                    packageData.serviceList.push({
                        'serviceName':serviceName,
                        'packageItemId':service.packageItemId,
                        'staff':staffName
                    });
                    formattedServices[index] = packageData;
                }
            }
        });

        return formattedServices;
    },

    getTemplateData: function(){
        var data = this.mergeTemplateData();

        data.bookingExists = this.bookingExists;
        data.servicesText = this.getServiceListTextAsArray();

        data.phone = this.businessModel.get('phone');
        data.businessName = this.businessModel.get('name');
        data.cancelPolicy = this.businessModel.get('cancellation_policy') === '' ? false : this.businessModel.get('cancellation_policy');
        data.address = this.businessModel.getDisplayAddress();
        data.shouldDisplayAddress = (this.businessModel.get('address').trim() !== '');

        if(this.stateModel.get('reschedule_flag') !== undefined) {
            var bookingId = this.stateModel.get('reschedule_flag');

            _.each(this.futureAppts, function(appt) {
                if (appt.bookingId.toString() === bookingId.toString()) {
                    data.reschedule_flag = true;
                    data.reschedule_booking = appt;
                    data.reschedule_booking.displayDate = moment(appt.startDate).format('dddd D MMMM [at] h:mma');
                }
            });

        } else {
            data.reschedule_flag = false;
        }

        var booking_photos = this.stateModel.get('booking_photos');
        if (booking_photos !== [] && booking_photos !== undefined) {
            var models = this.userModel.getSubsetOfAttachmentModels(booking_photos);
            data.booking_photos = _.map(models, function(model){
                return model.toJSON();
            });
        }

        return data;
    },

    confirmBookingPromise: function() {
        var withNotes = (this.model.hasNotes()) ? "With comments" : "Without comments";

        //if payment is required the appointment is not booked so we dont trigger this event YET
        if(this.model.get('paymentRequired') !== true){
            analytics.trackGAEvent(true, 'Bookings', 'Appointment booked', withNotes, parseInt(this.model.get('fullPrice')));
        }

        var bookingSavePromise;
        if(this.stateModel.get('reschedule_flag')){
            this.model.set('bookingId', this.stateModel.get('reschedule_flag'));
            bookingSavePromise = this.model.reschedule();
        } else {
            bookingSavePromise = this.model.save();
        }

        return bookingSavePromise;
    },

    confirmBookingAction: function(e){
        var that = this,
            bookingSavePromise = this.confirmBookingPromise();

        e.preventDefault();

        $(e.target).addClass("disabled").prepend('<i class="fa fa-spin fa-refresh"></i>');
        //save booking model
        loading.until(bookingSavePromise).done(function(response){
            if(typeof response.error != 'undefined') {
                $('body').trigger('route:navigate_replace', that.stateModel.buildStateHashUrl('#timeRedo'));
            } else if(!that.model.get('paymentRequired')) {
                //all we want to do is change the template to the finished/confirmed template
                that.stateModel.clearData();
                $('body').trigger('route:replace_only','confirm');
                that.userModel.newBookingMade = true;
                that.renderConfirmed();
                var trackUrl = that.businessModel.get('online_booking_address') + "/complete";
                analytics.trackPageView(trackUrl, 'Booking completed');
                analytics.trackFBEvent('Schedule');
            }
        }).fail(function(){
            that.userModel.servicesUnavailable = true;
            $(e.target).removeClass("disabled").find('i').remove();
        });
    },

    handlePhotoUpload: function(ev, callback) {
        this.photoEventsView.handlePhotoUploadEvent(ev, callback);
    },

    handleDragEnter: function(ev, callback) {
        this.photoEventsView.handleDragEnterEvent(ev, callback);
    },

    handleDragOver: function(ev, callback) {
        this.photoEventsView.handleDragOverEvent(ev, callback);
    },

    handleDragLeave: function(ev, callback) {
        this.photoEventsView.handleDragLeaveEvent(ev, callback);
    },

    handlePhotoDrop: function(ev, callback) {
        this.photoEventsView.handlePhotoDropEvent(ev, callback);
    },

    handlePhotoDelete: function(ev, callback) {
        this.photoEventsView.handlePhotoDeleteEvent(ev, callback);
    },

    handleReloadPage: function(e) {
        e.preventDefault();
        window.location.reload();
        return false;
    },

});
