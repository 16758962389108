"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');
var config = require('./../../js/models/configModel');
var PaypalPayment = require('./paypalPayment');
var WindcavePayment = require('./windcavePayment');
var KitombaPayPayment = require('./kitombaPayPayment');

Backbone.$ = $;

module.exports = {
    WINDCAVE_DRIVER : 'windcave',
    PAYPAL_DRIVER : 'paypal',
    KITOMBAPAY_DRIVER : 'kitombapay',
    getPaymentModel: function (businessModel) {
        var name = businessModel.attributes.payment_gateway;
        if (name === this.WINDCAVE_DRIVER) {
            return new WindcavePayment(businessModel);
        } else if (name === this.PAYPAL_DRIVER) {
            return new PaypalPayment(businessModel);
        } else if (name === this.KITOMBAPAY_DRIVER) {
            return new KitombaPayPayment(businessModel);
        }
    }
};
