/**
 * My details
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    template = Handlebars.compile(require('./../../templates/myDetailsCodeTemplate.hbs').default),
    Backbone = require('backbone'),
    analytics = require('./../helpers/analytics'),
    BaseView = require('./baseView');

var MobileDetect = require('mobile-detect'),
    md = new MobileDetect(window.navigator.userAgent);

require('jquery.cookie');
Backbone.$ = $;
window.jQuery = $;
require('jquery-validation');


var CodeEvents = {
    CODE_CONFIRMED: 'codeConfirmed'
};

module.exports = Backbone.View.extend({
    el: '#inner-modal',
    template: template,
    events: {
        "keyup #pinEntryField": 'pinEntered'
    },
    initialize: function (options) {
        this.options = options;
        this.CodeEvents = CodeEvents;
    },
    render: function () {
        require(
            ['js/helpers/google_phone_number_handler'],
            (phoneNumberHandler) => {
                this.options.phone = this.model.get('pending_phone');
                this.options.country = this.options.businessModel.get('country');
                this.options.phone = phoneNumberHandler.handleMobileParse(this.options.phone, this.options.country, 'db');
                this.$el.html(this.template({'options': this.options}));

                if (!md.mobile()) {
                    $('#pinEntryField').focus();
                }

                this.show();
            });
    },
    show: function () {
        $('#detailsCodeModal').modal('show');
    },
    /**
     * Enter a pin code
     */
    pinEntered: function () {
        var that = this;
        var currentCodeCount = this.model.incorrectCodeCount;
        var pinField = $('#pinEntryField');
        var codeEntered = pinField.val();
        $('.pretendEnterBox').removeClass('currentBox');
        $('#pretendEnterBox' + (codeEntered.length + 1)).addClass('currentBox');
        this.hideIncorrectPinError();
        if (codeEntered.length >= 4) {
            this.undelegateEvents();
            $('#pinError').hide();
            $('#pinLoader').css('display', 'inline-block');
            //we want to do a put to check if the code is correct
            var confirmPromise = this.model.confirmPendingTxt(codeEntered);

            confirmPromise.always(function (data, textStatus, jqXHR) {
                that.clearPinValue();

                if (currentCodeCount < that.model.incorrectCodeCount) {
                    //got the code wrong.
                    pinField.prop('disabled', false);
                    $('#pinEntryForm .pins').finish().effect('shake', {distance: 4}, function () {
                        //'input' trigger is for resetting pretend box focus position.
                        //note mobile browsers probably won't let you do this.
                        //also note settimeout hack to fix ie11 bug
                        setTimeout(function () {
                            if (!md.mobile()) {
                                pinField.focus();
                            }
                            pinField.trigger('input');
                            $('#pretendEnterBox1').addClass('currentBox');
                        }, 200);

                    });
                    that.showIncorrectPinError();
                    $('#pinLoader').fadeOut('fast');
                    pinField.select();
                    that.delegateEvents();
                } else {
                    //correct code
                    $('#detailsCodeModal').modal('hide');

                    that.trigger(that.CodeEvents.CODE_CONFIRMED);

                    $.notify({
                        // options
                        icon: 'fa fa-check',
                        message: 'Thanks, we\'ve verified your mobile number.'
                    }, {
                        // settings
                        type: 'success',
                        z_index: 2000,
                        placement: {
                            from: "top",
                            align: "center"
                        },
                        animate: {
                            enter: 'animated fadeInDown',
                            exit: 'animated fadeOutUp'
                        },
                        delay: 500,
                        timer: 3000,
                        offset: {
                            y: 5
                        }
                    });
                }
            });
        }
    },


    showIncorrectPinError: function () {
        $('<p>That code is not correct. Please double-check it and try again</p>').appendTo('#pinError');
        $('#pinError').show();
    },

    hideIncorrectPinError: function () {
        $('#pinError').hide();
        $('#pinError').html('');
    },
    clearPinValue: function () {
        $('#pinEntryField').val('');
    }
});

module.exports.CodeEvents = CodeEvents;