/**
 * Holds a specific branch
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');
var config = require('./../../js/models/configModel');

Backbone.$ = $;

module.exports = Backbone.Model.extend({
    // init of the model
    initialize: function(model) {

    }
});
