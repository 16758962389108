/**
 * JS Payment form for the dummy 'jaypay' driver
 *
 * @module
 */

"use strict";
/* global require, module, PaymentJs */
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');
var $ = require('jquery'),
    Backbone = require('backbone'),
    BaseView = require('./baseView'),
    config = require('./../../js/models/configModel'),
    template = Handlebars.compile(require('../../templates/kitombaPayForm.hbs').default),
    depositBlurb = Handlebars.compile(require('../../templates/kitombaPayDepositBlurb.hbs').default),
    voucherBlurb = Handlebars.compile(require('../../templates/kitombaPayVoucherBlurb.hbs').default),
    analytics = require('./../helpers/analytics'),
    moment = require('moment');

Backbone.$ = $;

var YEARS_TO_SHOW = 20;

module.exports = BaseView.extend({

    events: {
        'click .js-process-payment:not(.disabled)': 'handlePayClick',
        'click .js-cancel': 'handleCancelClick',
    },

    initialize: function (options) {
        this.options = options;
        BaseView.prototype.initialize(options);
    },

    getDestinationUrl: function () {
        var purchaseHash = this.options.purchaseHash;
        var urlTemplate = this.options.destinationUrlTemplate;
        return urlTemplate.replace(":checkout_hash", purchaseHash);
    },

    getViewData: function () {
        return {
            currencySymbol: this.stateModel.get('businessModel').get('currency_symbol'),
            expiryYears: this.getExpiryYearOptions(),
            purchaseHash: this.options.purchaseHash,
            amount: this.options.amount
        };
    },

    updateHash: function (newHash) {
        this.options.purchaseHash = newHash;
        this.$("input[name=payment_checkout_id]").val(newHash);
    },

    getExpiryYearOptions: function ()
    {
        var today = moment();
        var years = [];

        for (var i = 0; i < YEARS_TO_SHOW; i++) {
            years.push(today.format('YYYY'));
            today.add(1, 'years');
        }

        return years;
    },

    show: function () {
        this.$el.show();
    },

    injectView: function () {
        // show this form and remove the previous confirm page
        this.show();
        analytics.trackGAEvent(true, 'KitombaPay Payment', 'Payment form displayed', this.options.isVoucherSale ? 'Voucher sale' : 'Booking deposit');

        this.$el.siblings('.main').remove();
        window.location.hash = this.stateModel.buildStateHashUrl("makePayment");
    },

    getBlurbPartial: function () {
        if (this.options.isVoucherSale) {
            return voucherBlurb;
        }

        return depositBlurb;
    },

    render: function () {
        // special rendering logic: render without replacing the view in the #content element
        // this is because the JS form contains a couple of asynchronous moving parts and if we do a hidden pre-render
        // we can make sure it's ready for the user as fast as possible
        this.$el.hide();
        this.$el.html(template(this.getViewData(), {
            partials: {
                paymentBlurb: this.getBlurbPartial()
            }
        }));
        this.$el.find('#cvv_tip').tooltip();

        // inject after the current (confirm booking) page
        var $parent = $(this.parent);
        $parent.find('.main').after(this.$el);

    },

    setSubmitButtonEnabled: function (isEnabled) {
        var $button = $('.js-process-payment');

        if (isEnabled === void 0 || isEnabled) {
            $button.removeClass('disabled');
        } else {
            $button.addClass('disabled');
        }
    },

    /**
     * Boot the till payment.js SDK. This requires the till payment.js script to have loaded
     * @see kitombaPayPayment.loadPaymentsSdk()
     * @param publicIntegrationKey Public integration key from the returned driver parameters
     */
    initPaymentJs: function (publicIntegrationKey) {
        var that = this;
        var deferred = $.Deferred();

        this.payment = new PaymentJs();
        this.payment.init(publicIntegrationKey, 'number_div', 'cvv_div', function (payment) {
            // payment.numberOn('input', function (data) {
            //     that.setSubmitButtonEnabled(true); // re-enable the submit button after validation errors
            // });

            payment.numberOn('focus', function () {
                payment.setNumberStyle(that.getFieldFocusStyle());
            });

            payment.numberOn('blur', function () {
                payment.setNumberStyle(that.getFieldNormalStyle());
            });

            payment.setNumberStyle(that.getFieldNormalStyle());

            payment.cvvOn('focus', function () {
                payment.setCvvStyle(that.getFieldFocusStyle());
            });

            payment.cvvOn('blur', function () {
                payment.setCvvStyle(that.getFieldNormalStyle());
            });

            payment.setCvvStyle(that.getFieldNormalStyle());

            var $payButton = $(document).find('.payButton');
            $payButton.removeClass('disabled');
            $payButton.text($payButton.attr('data-button-text'));

            deferred.resolve();
        });

        return deferred.promise();
    },

    /**
     * Get a dictionary describing the style values for a focused form field
     *
     * Since the Till tokenization fields are sandboxes in an iframe, we have to provide the styles directly through the
     * api provided
     *
     */
    getFieldFocusStyle: function () {
        var styles = this.getFieldNormalStyle();

        // focus overrides
        styles['border-color'] = '#66afe9';
        styles['outline'] = 0;
        styles['box-shadow'] = 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)';
        styles['-webkit-box-shadow'] = 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)';

        return styles;
    },

    /**
     * Get a dictionary describing the style values for a normal (unfocused) form field
     *
     * Since the Till tokenization fields are sandboxes in an iframe, we have to provide the styles directly through the
     * api provided
     *
     */
    getFieldNormalStyle: function () {
        return {
            'outline': 0,
            'padding': '6px 8px',
            'display': 'block',
            'width': '100%',
            'height': '34px',
            'font-size': '14px',
            'line-height': '1.42857143',
            'color': '#555555',
            'background-color': '#ffffff',
            'background-image': 'none',
            'border': '1px solid #cccccc',
            'border-radius': '4px',
            '-webkit-box-shadow': 'inset 0 1px 1px rgb(0 0 0 / 8%)',
            'box-shadow': 'inset 0 1px 1px rgb(0 0 0 / 8%)',
            '-webkit-transition': 'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
            '-o-transition': 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
            'transition': 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
        };
    },

    showCardAuthorizeError: function (errors)
    {
        var that = this;

        if (_.isArray(errors)) {
            _.forEach(errors, function (error) {
                that.showErrorMessage(error.errorMessage, 'number');
            });
        }
    },

    showErrorMessage: function (errorMessage, highlightFieldName)
    {
        var $errorList = $('#card-input-errors ul');

        $errorList.append($('<li />').text(errorMessage+"."));
        $(this.getFieldSelector(highlightFieldName)).addClass('invalid');
        $errorList.show();

        this.setSubmitButtonEnabled(true);
    },

    handlePayClick: function (event) {
        var that = this;

        this.resetValidation();

        analytics.trackGAEvent(true, 'KitombaPay Payment', "User clicked 'Pay'", this.options.isVoucherSale ? 'Voucher sale' : 'Booking deposit');

        var baseUrl = config.getAPIUrl() + "paypal/" + config.getBusinessToken() + "/authorize/";
        var tokenizeData = {
            card_holder: this.$("#card_holder").val(),
            month: this.$("#exp_month").val(),
            year: this.$("#exp_year").val()
        };

        event.preventDefault();

        $('.js-process-payment').addClass('disabled');

        this.payment.tokenize(tokenizeData, function (token, cardData) {
            var purchaseHash = that.$("input[name=payment_checkout_id]").val();
            that.$el.find('.paymentProcessing').show();

            var url = baseUrl + purchaseHash;

            // save the token so that it is available in the finalization step
            $.post(url, { cardToken: token }).then(function (response) {

                analytics.trackGAEvent(true, 'KitombaPay Payment', 'Payment authorisation success');

                if (response.redirectUrl) {
                    // backend is requesting a redirect, e.g. additional authorization from gateway
                    analytics.trackGAEvent(true, 'KitombaPay Payment', 'Payment redirect to 3D Secure');

                    window.location = response.redirectUrl;
                    return;
                }

                // go to finalize page
                var destinationUrl = new URL(that.getDestinationUrl());
                $("body").trigger("route:navigate_replace", destinationUrl.hash);
            }).fail(function (jqxhr) {
                // re-enable the pay button to allow trying again
                that.setSubmitButtonEnabled(true);
                that.$el.find('.paymentProcessing').hide();

                if (jqxhr.responseJSON && jqxhr.responseJSON.new_hash) {
                    var newHash = jqxhr.responseJSON.new_hash;
                    that.updateHash(newHash);
                } else {
                    jqxhr.preventConfirmRedirect = true;

                    console.error("Missing checkout hash. Returning to booking confirmation screen");
                    $("body").trigger("route:navigate_replace", "confirm");
                    return;
                }

                if (jqxhr.status === 400 && jqxhr.responseJSON) {
                    that.showCardAuthorizeError(jqxhr.responseJSON.errors);
                    jqxhr.errorHandled = true;

                    analytics.trackGAEvent(true, 'KitombaPay Payment', 'Payment authorisation error', jqxhr.responseJSON.errors);
                    return;
                }

                // otherwise unexpected problem - fall through to generic handler
            });
        }, function(errors) {
            $.each(errors, function(index, error){
                var field = error.attribute,
                    message = error.message;

                analytics.trackGAEvent(true, 'KitombaPay Payment', 'Card validation error', message);
                that.showErrorMessage(message, field);
            });
        });
    },

    resetValidation: function() {
        $('#month_div, #year_div, #card_holder_div, #cvv_div, #number_div').removeClass('invalid');
        $('#card-input-errors ul').empty();
        $('#card-input-errors ul').hide();
    },

    getFieldSelector: function(fieldName) {
        return "#"+fieldName+"_div";
    },

    handleCancelClick: function () {
        analytics.trackGAEvent(true, (this.options.isVoucherSale ? 'Voucher':'Bookings'), (this.options.isVoucherSale ? 'Purchase' : 'Deposit')+' aborted', 'Gateway name: KitombaPay');
        Backbone.history.history.back();
    }
});