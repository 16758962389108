"use strict";
var $ = require('jquery'),
    Backbone = require('backbone'),
    _ = require('underscore'),
    config = require('./configModel');

module.exports = Backbone.Model.extend({
    urlRoot : config.getAPIUrl() + "attachment/" + config.getBusinessToken() + "/",
    defaults: function() {
        return {
            business_id: '',
            owner_id: '',
            owner_type: '',// client, appointment.....,
            type: '',
            file_name: '',
            width: '',
            height: '',
            original_file_name:'',
            url: '',
            thumb_url: '',
            content: '',
            upload_date: '',
            status: 'active',
            format: 'file-o',
            description: ''
        };
    },

    initialize: function(data) {
        if(typeof data.business_id !== 'undefined') {
            this.urlRoot = config.getAPIUrl() + "attachment/" + data.business_id + "/";
        } else {
            this.urlRoot = config.getAPIUrl() + "attachment/" + config.getBusinessToken() + "/";
        }
        if (typeof data.file_name !== "undefined") {
            this.set("format", this.detectFormat(data.file_name));
        }
    },

    parse: function(response) {

        if (typeof response.file_name !== "undefined") {
            response.format = this.detectFormat(response.file_name);
        }
        return response;
    },

    detectFormat: function(file_name) {
        var format = "file-o";
        var ind = file_name.lastIndexOf(".");
        if (ind > 0) {
            var extension = file_name.substr(ind).toLowerCase();
            switch (extension) {
                case ".xls":
                case ".xlsx":
                case ".csv":
                case ".ods":
                    format = "file-excel-o";
                    break;
                case ".tiff":
                case ".tif":
                case ".jpg":
                case ".jpeg":
                case ".bmp":
                case ".gif":
                case ".png":
                    format = "file-image-o";
                    break;
                case ".zip":
                case ".gz":
                case ".tar":
                    format = "file-archive-o";
                    break;
                case ".pdf":
                    format = "file-pdf-o";
                    break;
                case ".doc":
                case ".docx":
                case ".odt":
                case ".txt":
                case ".rtf":
                    format = "file-text-o";
                    break;
                default:
                    format = "file-o";
                    break;
            }
        }
        return format;
    },

    isImage: function() {
        return this.get('type') === 'photo';
    },

    destroy: function() {
        var that = this,
            jsonObj = that.toJSON();
        if(typeof jsonObj.id === "undefined" || !$.isNumeric(jsonObj.id)
            || typeof jsonObj.owner_id === "undefined" || !$.isNumeric(jsonObj.owner_id)) {
            return false;
        }
        return $.ajax({
            url: that.urlRoot + jsonObj.owner_id + "/" + jsonObj.id,
            contentType: 'application/x-www-form-urlencoded',
            dataType: 'json',
            data: {attachment: JSON.stringify(jsonObj)},
            global: false,
            type: 'DELETE'
        });
    },

    save: function() {
        //Unsaved customers get photos that have fake ids, clear out these ids when saving...
        if(this.get('id') && !$.isNumeric(this.get('id'))) {
            this.set('id', undefined);
        }

        var attributes = { global: false };
        Backbone.emulateJSON = true;
        var xhr = Backbone.Model.prototype.save.call(this, attributes);
        Backbone.emulateJSON = false;
        return xhr;
    }
});