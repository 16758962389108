/**
 * Timeslots that are bookable
 *
 * @module
 */

"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    BaseServiceCollection = require('./baseServiceCollection'),
    timeUtilities = require('./../helpers/timeUtilities'),
    config = require('./../../js/models/configModel');

Backbone.$ = $;

/**
 *
 *
 * @type {*}
 */
module.exports = BaseServiceCollection.extend({
    model: Backbone.Model.extend({defaults: {
        time: false,
        enabled: false,
    }}),
    open: false, //openFloor
    close: false, //closeCeiling
    stepSize: 15,
    cols: 4,
    slots: {
        morning: [],
        afternoon: [],
    },
    initialize: function(models, options) {
        BaseServiceCollection.prototype.initialize(models, options);
        _.bindAll(this, 'getTimeSlots');
    },
    fetch: function(dayOfWeek) {
        this.stepSize = parseInt(this.businessModel.get('stepSize'), 10);

        this.reset();
        this.slots = {
            morning: [],
            afternoon: [],
        };

        this.getTimeSlots(dayOfWeek);
        return this;
    },
    getOpenCloseTimes: function(dayOfWeek) {
        var open = false,
            close = false,
            closeMinutes;

        var times = this.businessModel.attributes.hours[dayOfWeek];


        open = timeUtilities.convertToMilitary(times.o);
        close = timeUtilities.convertToMilitary(times.c);


        closeMinutes = close % 100;
        if (this.stepSize <= closeMinutes) {
            close = close - this.stepSize;
        } else {
            close = close - this.stepSize - 40;
        }

        this.open = open;// - (open % 100); KIT-7644 removing the rounding.
        this.close = close;
    },
    /**
     * 13. 5 minutes, 4 per row. Step size is 5.
     14. 10 minutes, 3 per row. Step size is 10.
     15. 15 minutes, 4 per row, starting on the hour.
     16. 20 minutes, 3 per row, starting on the hour. Step size is 20.
     17. 30 minutes, 4 per row, starting on the alternate hour. This is used if their OB step size is 30.
     18. 45 minutes, 3 per row. This is used if their OB step size is 45. Only one person uses this :/
     19. 60 minutes, 3 per row. This is used if their OB step size is 60.
     20. Fallback (i.e. if not set to any of the above) should be 15 (e.g. 25, 35, 55)
     */
    setNumberofColumns:function() {
        switch (parseInt(this.stepSize,10)) {
            case 60:
                this.cols = 3;
                break;
            case 45:
                this.cols = 3;
                break;
            //case 30:
            case 20:
                this.cols = 3;
                break;
            //case 15:
            case 10:
                this.cols = 3;
                break;
            //case 5:
            default :
                this.cols = 4;
        }

    },
    getTimeSlots: function(dayOfWeek) {
        var i;

        this.setNumberofColumns();
        this.getOpenCloseTimes(dayOfWeek);

        i = this.open;

        while( i <= this.close) {
            var formattedTime, formattedTimeEnd;


            formattedTime = timeUtilities.convertToStandardTime(i);
            formattedTimeEnd = formattedTime.slice(-2);
            formattedTime = formattedTime.slice(0,formattedTime.length-2).toString();
            formattedTime = parseInt(formattedTime) + ":" + formattedTimeEnd;

            this.add([
                {id: i, time: formattedTime, time24: false}
            ]);

            if (i < 1200) {
                this.slots.morning.push(this.get(i));
            } else {
                this.slots.afternoon.push(this.get(i));
            }
            i = parseInt(timeUtilities.addMinutes(i,this.stepSize));
        }
    },
    /**
     * remove extranous slots for display to user
     */
    tidy: function() {
        if (!_.filter(this.slots.morning,function(item) {
            return item.get('enabled');
        }).length) {
            this.slots.morning = [];
        }
        if (!_.filter(this.slots.afternoon,function(item) {
            return item.get('enabled');
        }).length) {
            this.slots.afternoon = [];
        }
    }
});
