"use strict";

function getHashPath() {
    var hash_url = window.location.hash,
        query_index = hash_url.indexOf('?');
    if (query_index !== -1) {
        hash_url = hash_url.substr(0, query_index);
    }
    return hash_url.trim();
}

function getStageURI(){

    var hashUrl = window.location.hash;
    var queryIndex = hashUrl.indexOf('?');

    if (queryIndex !== -1) {
        var queryString = hashUrl.substr(queryIndex);
        //is #time the same always?
        return encodeURIComponent('#time' + decodeURI(queryString));
    }

    return null;
}


function onHomePage() {
    var hashPath = getHashPath();
    hashPath = hashPath.replace('#','').replace('landing','');

    if(!hashPath) {
        return true;
    } else {
        return false;
    }
}

function onLogin() {
    var hashPath = getHashPath();
    hashPath = hashPath.replace('#','').replace('login','');

    if(!hashPath) {
        return true;
    } else {
        return false;
    }
}

function getQueryParam(key) {
    return getParameter(key, window.location.hash);
}

function getParameter(key, hash) {
    var value = "Not found",
        tmp = [];
    if (hash.indexOf('?') !== -1 && hash.indexOf('?') < hash.length -1) {
        hash = hash.substr(hash.indexOf('?') + 1);
    }

    hash.split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === key) value = decodeURIComponent(tmp[1]);
        });
    return value;
}

function getSearchParameter(key) {
    return getParameter(key, window.location.search);
}

module.exports.getHashPath = getHashPath;
module.exports.getStageURI = getStageURI;
module.exports.onHomePage = onHomePage;
module.exports.onLogin = onLogin;
module.exports.getQueryParam = getQueryParam;
module.exports.getSearchParameter = getSearchParameter;