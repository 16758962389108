"use strict";
var $ = require('jquery');

//force numeric values only (plus some other keys to navigate etc)
$.fn.forceNumeric = function () {
    return this.each(function () {
        $(this).keydown(function (e) {
            var key = e.which || e.keyCode;

            if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
                    // numbers
                key >= 48 && key <= 57 ||
                    // Numeric keypad
                key >= 96 && key <= 105 ||
                    // comma, period and minus, . on keypad
                    //        key == 190 || key == 188 || key == 109 || key == 110 ||
                    // Backspace and Tab and Enter
                key === 8 || key === 9 || key === 13 ||
                    // Home and End
                key === 35 || key === 36 ||
                    // left and right arrows
                key === 37 || key === 39 ||
                    // Del and Ins
                key === 46 || key === 45) {
                return true;
            }
            return false;
        });
    });
};

$.fn.forceNumericDecimals = function () {
    return this.each(function () {
        $(this).keydown(function (e) {
            var key = e.which || e.keyCode;

            if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
                    // numbers
                key >= 48 && key <= 57 ||
                    // Numeric keypad
                key >= 96 && key <= 105 ||
                    // period . on keypad
                key === 190 || key === 110 ||
                    // Backspace and Tab and Enter
                key === 8 || key === 9 || key === 13 ||
                    // Home and End
                key === 35 || key === 36 ||
                    // left and right arrows
                key === 37 || key === 39 ||
                    // Del and Ins
                key === 46 || key === 45 ||
                    // Subtract
                key === 109
            ) {
                return true;
            }
            return false;
        });
    });
};

$.fn.forcePositiveNumericDecimals = function () {
    return this.each(function () {
        $(this).keydown(function (e) {
            var key = e.which || e.keyCode;

            if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
                    // numbers
                key >= 48 && key <= 57 ||
                    // Numeric keypad
                key >= 96 && key <= 105 ||
                    // period . on keypad
                key === 190 || key === 110 ||
                    // Backspace and Tab and Enter
                key === 8 || key === 9 || key === 13 ||
                    // Home and End
                key === 35 || key === 36 ||
                    // left and right arrows
                key === 37 || key === 39 ||
                    // Del and Ins
                key === 46 || key === 45) {
                return true;
            }
            return false;
        });
    });
};

//force numeric values only but considers 0 & numpad 0 to be invalid (plus some other keys to navigate etc)
$.fn.forceNumericNonZero = function () {
    return this.each(function () {
        $(this).keydown(function (e) {
            var key = e.which || e.keyCode;

            if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
                // numbers
                key >= 49 && key <= 57 ||
                // Numeric keypad
                key >= 97 && key <= 105 ||
                // comma, period and minus, . on keypad
                //        key == 190 || key == 188 || key == 109 || key == 110 ||
                // Backspace and Tab and Enter
                key === 8 || key === 9 || key === 13 ||
                // Home and End
                key === 35 || key === 36 ||
                // left and right arrows
                key === 37 || key === 39 ||
                // Del and Ins
                key === 46 || key === 45) {
                return true;
            }
            return false;
        });
    });
};

$.fn.forcePositiveNumericDecimalsDecimalPlaces = function (numDecimalPlaces) {
    return this.each(function () {
        $(this).on('blur', function(e){

            var toFixedDown = function(value, digits) {
                var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
                    m = value.toString().match(re);
                return m ? parseFloat(m[1]) : value.valueOf();
            };

            //truncate the value if they past in something with more decimal places
            var val = parseFloat(e.currentTarget.value);
            if(!_.isNaN(val)){
                var roundedValue = toFixedDown(val, numDecimalPlaces);
                if(val !== roundedValue){
                    $(e.currentTarget).val(roundedValue);
                }
            }
        });

        $(this).keydown(function (e) {
            var key = e.which || e.keyCode;

            if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
                // numbers
                key >= 48 && key <= 57 ||
                // Numeric keypad
                key >= 96 && key <= 105 ||
                // period . on keypad
                key === 190 || key === 110 ||
                // Backspace and Tab and Enter
                key === 8 || key === 9 || key === 13 ||
                // Home and End
                key === 35 || key === 36 ||
                // left and right arrows
                key === 37 || key === 39 ||
                // Del and Ins
                key === 46 || key === 45) {

                //see if they have already entered a decimal place
                if((key === 190 || key === 110) && e.currentTarget.value.indexOf('.') >= 0){
                    return false;
                }

                //now do a check to see how many decimal places they have entered
                // var value = e.currentTarget.value;
                // if((key >= 48 && key <= 57 || key >= 96 && key <= 105) && value.indexOf('.') >= 0){
                //     var decimalPart = value.substring(value.indexOf('.') + 1);
                //     //
                //     if(decimalPart.length >= numDecimalPlaces){
                //         return false
                //     }
                // }


                return true;
            }
            return false;
        });
    });
};


