/**
 * Holds domain level settings
 *
 * @module
 */
"use strict";

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');

Backbone.$ = $;

var Config = Backbone.Model.extend({
    defaults: {
        apiUrl: "",
        businessToken: "",
        baseUrl: ""
    },
    fetchFromDom: function () {
        if ($('body').data('api')) {
            this.set('apiUrl', $('body').data('api'));
        } else {
            this.set('apiUrl', "");
        }
        if ($('body').data('businessId')) {
            this.set('businessToken', $('body').data('businessId'));
        } else {
            this.set('businessToken', "");
        }
        if ($('body').data('baseUrl')) {
            this.set('baseUrl', $('body').data('baseUrl'));
        } else {
            this.set('baseUrl', "");
        }

    },
    initialize: function (model) {
    },
    fetch: function () {
        //special model
        return this.fetchFromDom();
    },
    getAPIUrl: function () {
        //append slash if not present because browsersync can remove it..
        var url = this.get('apiUrl').replace(/\/?$/, '/');
        return url;
    },
    getBusinessToken: function () {
        return this.get('businessToken');
    },
    /**
     * used for things like switching branches of a chain,
     * needs to be the part of the url before the business token
     * like /bookings2/
     *
     * @returns {*}
     */
    getBaseUrl: function () {
        return this.get('baseUrl');
    }
});


/**
 * singleton
 *
 * @type {exports.extend}
 */
module.exports = new Config();
