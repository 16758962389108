/**
 * Holds a booking
 *
 * @module
 */
"use strict";

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');

var moment = require('moment');

var config = require('./../../js/models/configModel');
var BaseModel = require('./baseModel');

Backbone.$ = $;

module.exports = BaseModel.extend({

    idAttribute: 'bookingId',
    //cancelBookingPrependStr : 'Cancelled by client. Reason:',

    defaults: {
        //businessId: null,
        customerId: null,
        paymentRequired: false,
        allowDoubleBooking: false,
        suppressEmail: false,
        notes: "",
        stateModel: false,
        services: [], //list of booking slots
        price: false
    },

    url: function() {
        return config.getAPIUrl() + 'booking/' + config.getBusinessToken() + '/';
    },

    cancelUrl: function() {
        return config.getAPIUrl() + 'booking/' + this.get('businessId') + '/cancel/';
    },

    rescheduleUrl: function() {
        return config.getAPIUrl() + 'booking/' + config.getBusinessToken() + '/reschedule/' + this.get(this.idAttribute);
    },

    /**
     boolean reschedule flag
     **/
    setupExpressCheckoutUrl: function(reschedule) {
        return config.getAPIUrl() + "paypal/" + config.getBusinessToken() + "/appointment_deposit/" + this.get('customerId') + '/' + reschedule
    },

    updateNotesByAppointmentHashUrl: function(apptHash) {
        return config.getAPIUrl() + 'booking/' + config.getBusinessToken() + '/update_notes_by_appointment_hash/' + apptHash;
    },

    priceUrl: function() {
        return config.getAPIUrl() + 'booking/' + config.getBusinessToken() + '/pricing/';
    },

    priceCheck: function() {
        var that = this, url = this.priceUrl();
        return $.post(url, {model:JSON.stringify({services: that.get('services')})}, function(data){
            that.set('fullPrice', data.price);
            that.set('deposit', data.deposit);
            that.set('currencySymbol', data.currencySymbol);
            that.set('paymentRequired', Number(data.deposit) > 0);
        }, 'json');
    },

    //initialize: function(model) {},

    //save: function(attributes, options){
    //    var data = this.toJSON();
    //    data = this._preSave(data);
    //
    //    return $.post(this.url(), data);
    //},

    cancel: function(options) {
        //data.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');

        options = options || {};

        options.emulateJSON = true;

        options.url = this.cancelUrl() + this.get(this.idAttribute);
        options.attrs = this.pick('cancelReason');

        return this.sync('update', this, options);
    },

    reschedule: function(attributes, options){
        options = options || {};
        options.emulateJSON = true;
        options.url = this.rescheduleUrl();
        return this.save(attributes, options);
    },

    saveNotesByAppointmentHash: function(apptHash){
        return this.postAction(this.updateNotesByAppointmentHashUrl(apptHash));
    },

    hasNotes: function(){
        var notes = this.get('notes');
        notes = (_.isString(notes)) ? notes.trim() : '';
        return notes !== '';
    },

    setupAppointmentDeposit: function(reschedule){
        return this.postAction(this.setupExpressCheckoutUrl(reschedule));
    },

    /**
     *
     * @returns startDate
     */
    getFirstServiceStartDate: function(){
        return this.get('services')[0].startDate;
    },

    prepareCancel: function() {
        if (this.get('status').toLowerCase() === "booked") {
            this.set('status', 'Cancelled');
        } else if(this.get('status').toLowerCase() === "webpending") {
            this.set('status', 'deleted');
        }
    },

    appendCancelNotes: function(notes) {
        var cancelNotes = this.cancelBookingPrependStr + ' ' + notes + '\n';
        this.set('notes', cancelNotes);
    },

    _preSave: function(data) {
        //due to the way the notes work we need to get them from the model.notes and stick them
        //onto the first service slot
        if(data.services.length > 0){
            data.services[0].notes = data.notes;
        }
        delete data.notes;

        data.services = JSON.stringify(data.services);
        return data;
    },

    hasZeroDuration: function(services, servicesCollection, packagesCollection) {
        var duration = 0;

        _.each(services.models, function(service) {
            if (service.get('type') === 'package') {
                var packageModel = packagesCollection.get(service.id);
                if (packageModel) {
                    duration += packageModel.get('duration');
                }
            }
            else if (service.get('type') === 'service') {
                var serviceModel = servicesCollection.get(service.id);
                if (serviceModel) {
                    duration += serviceModel.get('duration');
                }
            }
        });

        return duration === 0;
    }
});

