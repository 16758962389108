/**
 * Landing/homepage
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    config = require('./../models/configModel'),
    template = Handlebars.compile(require('./../../templates/pageLanding.hbs').default),
    Backbone = require('backbone'),
    Handlebars = require('handlebars'),
    analytics = require('./../helpers/analytics'),
    BaseView = require('./baseView');

Backbone.$ = $;

module.exports = BaseView.extend({
    page:'landing',
    pageTitle: 'Home page',
    template: template,
    initialize: function(options) {
        //call parent constructor
        BaseView.prototype.initialize(options);

    },
    render: function() {
        this.stateModel.removeRequestedBookingSlot();
        var that = this;
        var mergeData = this.mergeTemplateData();
        this.setPageTitle(mergeData.business.name);
        this.getBranchListData(mergeData,this.userModel,function(data){
            that.$el.html(that.template(data));
            BaseView.prototype.render(that);
        });

    },

    //currently unused
    //also needs
    //google.maps.event.addDomListener(window, 'load', initialize);    <script>
    drawGoogleMap: function() {
        var myLatlng = new window.google.maps.LatLng(-41.2922338, 174.7724595);
        var mapOptions = {
            zoom: 16,
            center: myLatlng
        };
        var map = new window.google.maps.Map(document.getElementById('map-canvas'), mapOptions);

        var marker = new window.google.maps.Marker({
            position: myLatlng,
            map: map,
            title: 'Hello World!'
        });
    }
});
