/**
 * Availability for a specific day.
 *
 * @module
 */
"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    moment = require('moment'),
    config = require('./configModel'),
    jstz = require('jstz');

Backbone.$ = $;

//holder for services/packages passed from backend
module.exports = Backbone.Model.extend({
    reschedule_flag: undefined,
    defaults: {
        id: "", //same as date
        date: "",
        availability: false, //open, closed, booked
        services: [],
        slots: [],
        serviceIdString: ""
    },
    url: function () {
        var baseUrl = config.getAPIUrl() + 'booking/' + config.getBusinessToken() + '/availability/',
            reschedule_string = '';
        if(this.reschedule_flag) {
            reschedule_string = '&rescheduledBookingId=' + this.reschedule_flag;
        }
        return baseUrl + 'full?startDate=' + this.get('date') + '&endDate=' + this.get('date') + '&services=' + this.getServices() + reschedule_string;
    },
    initialize: function (model, options) {
        if(options && options.collection && options.collection.stateModel && options.collection.stateModel.get) {
            this.reschedule_flag = options.collection.stateModel.get('reschedule_flag');
        }
    },
    checkEnabled: function () {
        return this.get('availability') === 'open';
    },
    parse: function (response) {
        if (_.isArray(response.availability)) {
            response.slots = response.availability;
            response.availability = this.get('availability');
        }
        return response;
    },
    getServices: function () {
        return this.get('serviceIdString');
    },
    getAvailableSlots: function () {
        var slots = _.map(this.get('slots'), function (slots) {
            var slot = _.first(slots),
                data = {};
            data.id = moment(slot.startDate).format('Hmm');//remove preceeding slash
            data.time = moment(slot.startDate).format('h:mm');
            data.time24 = moment(slot.startDate).format('HH:mm');
            data.enabled = true;

            return data;
        });
        return slots;
    },
    getSlotDetails: function (slotDateTime) {
        return this.get('slots').filter(function (item) {
            return item[0].startDate === slotDateTime;
        });
    },
    slotExists: function(slotDateTime){
        return this.get('slots').some(function (item) {
            return item[0].startDate === slotDateTime;
        });
    }
});

