/**
 * My details modal view.
 *
 * @module
 */
"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone'),
    config = require('./../models/configModel'),
    analytics = require('./../helpers/analytics'),
    base64 = require('./../helpers/base64'),
    template = Handlebars.compile(require('./../../templates/myDetailsModal.hbs').default);


Backbone.$ = $;
window.jQuery = $;
require('jquery-validation');

var TriggerEvents = {
    LOAD_CODE_VIEW: 'goToCodeView',
    LOAD_EMAIL_VIEW: 'goToEmailView',
    RELOAD_PROFILE_VIEW: 'reloadProfileView'
};
module.exports = Backbone.View.extend({
    el: '#inner-modal',
    template: template,
    events: {
        'click #saveDeets': 'saveDetailsAction',
        'click #closeX': 'closeModal',
        'click #cancelModal': 'closeModal'
    },
    initialize: function (options) {
        this.model = options.model;
        this.businessModel = options.businessModel;
        this.initValidator();
        this.TriggerEvents = TriggerEvents;
    },

    render: function () {
        this.$el.html(this.template({'model': this.model.attributes}));
        this.initValidator();
    },

    show: function () {
        $('#deetsModal').modal('show');
    },
    save: function (callBack) {
        require(
            ['js/helpers/google_phone_number_handler'],
            (phoneNumberHandler) => {
                var that = this, putData,
                    email = this.model.get('clean_email'),
                    inputFirstName = $('#firstname').val(),
                    inputLastName = $('#lastname').val(),
                    inputEmail = $('#email').val(),
                    inputMobile = $('#mobile').val(),
                    inputAcceptPromos = $('#acceptPromos').is(":checked") ? '1' : '0';
                if (inputMobile) {
                    inputMobile = phoneNumberHandler.handleMobileParse(inputMobile, this.businessModel.get('business_phoneNumberCountryCode'), 'db');
                }

                putData = {
                    first_name: inputFirstName,
                    last_name: inputLastName,
                    customer_kid: this.model.get('id'),
                    email: inputEmail,
                    phone: inputMobile,
                    accept_promos: inputAcceptPromos
                };
                config.fetchFromDom();

                callBack($.ajax({
                    type: 'PUT',
                    url: config.getAPIUrl() + "customer/" + this.model.get('business_id') + "/" + this.model.get('id'),
                    data: putData,
                    dataType: 'json',
                    headers: {
                        'Authorization': 'Basic ' + base64.btoa(this.model.get('clean_email') + ":" + this.model.get('api_token'))
                    },
                    success: function (data) {
                        that.model.set(data.customer);
                        that.model.setLoginCookies(data.customer.email, data.api_token, data);
                        that.setShownName(data.customer.firstName);
                        that.closeModal();
                    },
                    error: function (xhr, status, error) {
                        that.model.set('email', email);
                        if (xhr.responseJSON.error === "The new email is already registered.") {
                            var errors = {email: 'The new email is already registered.'};
                            $('#deetsForm').validate().showErrors(errors);
                        }
                    }
                }));
            });
    },

    setShownName: function(name){
        $('#user_first_name').text(name);
    },

    saveDetailsAction: function () {
        var that = this;
        if (!$('#deetsForm').valid()) {
            return;
        }
        analytics.trackGAEvent(false,'Non-booking behaviour','User changed personal information');
        this.save(savePromise => {
            $.when(savePromise).then(function (data, textStatus, jqXHR) {
                that.triggerAfterSaveEvents(data);
            });
        });

    },
    triggerAfterSaveEvents: function(data) {
        if (data.validationType === 'txt') {
            this.trigger(this.TriggerEvents.LOAD_CODE_VIEW);
        } else if (data.validationType === 'email') {
            this.trigger(this.TriggerEvents.LOAD_EMAIL_VIEW);
        }
        else {
            this.trigger(this.TriggerEvents.RELOAD_PROFILE_VIEW);
        }
    },
    initValidator: function () {
        $.validator.addMethod('valid_email', function (value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        }, "Please enter a valid email address.");
        $.validator.addMethod('valid_phone', function (value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        }, "Please enter a valid phone number");
        $.validator.addMethod('not_empty', function (value, element,bool) {
            return (value.trim() === "") !== bool;
        }, "Cannot be empty");
        $('#deetsForm').validate({
            //  onfocusout: false,
            //  onkeyup: false,
            //  onclick: false,
            rules: {
                firstname: {
                    required: true,
                    not_empty: true
                },
                lastname: {
                    required: true,
                    not_empty: true
                },
                email: {
                    required: true,
                    email: true,
                    valid_email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    not_empty: true
                },
                mobile: {
                    required: true,
                    minlength: 6,
                    valid_phone: /^\+*(?:[(0-9-.,)]\s?){4,20}[0-9]$/,
                    not_empty: true
                }
            },
            messages: {
                firstname: {
                    required: "First name is required",
                    not_empty: "First name is required"
                },
                lastname: {
                    required: "Last name is required",
                    not_empty: "Last name is required"
                },
                email: {
                    required: "Please enter a valid email address",
                    email: "Please enter a valid email address",
                    valid_email: "Please enter a valid email address",
                    not_empty: "Please enter a valid email address"
                },
                mobile: {
                    required: "Please enter a valid mobile number",
                    minlength: "Please enter a valid mobile number",
                    not_empty: "Please enter a valid mobile number"
                }
            },
            errorLabelContainer: "#myDeetsValidationAnchor",
            wrapper: 'li',
            errorPlacement: function (error, element) {
                $('#myDeetsValidationAnchor').append(error);
            }
        });
    },
    closeModal: function () {
        $('#deetsModal').modal('hide');
        $("div.fade.in").remove();
        this.undelegateEvents();
    }


});
module.exports.TriggerEvents = TriggerEvents;
